import {makeStyles} from "@material-ui/core/styles";
import {createStyles} from "@material-ui/core";
import odlSizes from "odl-components/styles/odlSizes.json"
import odlPalette from "odl-components/styles/odlProPalette"
import {customBreakpoints} from "../../app/theme";

export const formGroupStyle = makeStyles((theme) => createStyles({
    container: {
        "& > button": {
            float: "right"
        }
    },
    left: {
        flex: "1 1 70%"
    }
}));

export const formSectionStyle = makeStyles((theme) => createStyles({
    displayName: {
        fontSize: odlSizes.typography.size.xlarge,
        lineHeight: odlSizes.spacing.factor4.s5,
        marginBottom: odlSizes.spacing.factor4.s6
    },
    multiSectionRow: {
        borderColor: odlPalette.light.obj.deco,
        marginBottom: odlSizes.spacing.factor4.s6,
        paddingTop: odlSizes.spacing.factor4.s8,
        paddingBottom: odlSizes.spacing.factor4.s8,
        paddingRight: odlSizes.spacing.factor4.s6,
        paddingLeft: odlSizes.spacing.factor4.s6,
        border: "1px solid",
        borderRadius: odlSizes.spacing.factor4.s1,
        backgroundColor: odlPalette.light.obj.day,
        "& > button": {
            float: "right"
        },
        [theme.breakpoints.up(customBreakpoints.mobile)]: {
            maxWidth: "640px",
        }
    },
    multiSectionRowContent: {
        flex: "1 1 auto",
        "& $field": {
            flexBasis: "100%",
            marginRight: 0
        }
    },
    multiSectionRowError: {
        borderColor: odlPalette.light.obj.red,
        borderStyle: "dashed"
    },
    errorBanner: {
        width: "min(640px, 100%)"
    },
    addRowButton: {
        borderRadius: odlSizes.spacing.factor4.s6,
        marginBottom: odlSizes.spacing.factor4.s6
    },
    sectionContainer: {
        borderRadius: odlSizes.spacing.factor4.s2,
        paddingLeft: odlSizes.spacing.factor4.s8,
        paddingRight: odlSizes.spacing.factor4.s8,
        paddingTop: odlSizes.spacing.factor4.s8,
        marginTop: odlSizes.spacing.factor4.s6,
        marginBottom: odlSizes.spacing.factor4.s6,
    },
    label: {
        maxWidth: "100%",
        "& img": {
            maxWidth: "100%"
        }
    },
    field: {
        marginBottom: odlSizes.spacing.factor4.s8,
        maxWidth: "min(640px, 100%)",
        marginRight: odlSizes.spacing.factor4.s4,
        flex: "1 1 auto",
    },
    tooltip: {
        maxWidth: "300px",
        marginBottom: odlSizes.spacing.factor4.s4,
        "& img": {
            maxWidth: "100%"
        },
    },
    [theme.breakpoints.down("md")]: {
        tooltip: {
            flex: "1 1 auto",
            maxWidth: "unset"
        }
    },
    [theme.breakpoints.down(customBreakpoints.mobile)]: {
        field: {
            minWidth: "unset",
            flexBasis: "100%"
        }
    }
}));

export const errorBanner = makeStyles(() => createStyles({
    message: {
        paddingTop: odlSizes.spacing.factor4.s2,
        paddingBottom: odlSizes.spacing.factor4.s2
    }
}));

export const navButton = makeStyles(() => createStyles({
    navButton: {
        height: odlSizes.spacing.factor4.s14,
        borderRadius: odlSizes.spacing.factor4.s7,
        "& > .odlButton__label": {
            margin: odlSizes.spacing.factor4.s3,
            fontSize: "20px",
            lineHeight: odlSizes.spacing.factor4.s7
        }
    },
    commonMargin: {
        marginRight: odlSizes.spacing.factor4.s4
    },
    unCapitalise: {
        textTransform: "unset"
    },
    otherOptions: {
        fontSize: "20px"
    }
}));