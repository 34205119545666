import React from "react";
import {PropsWithChildren} from "react";
import {Paper} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import odlPalette from "odl-components/styles/odlProPalette";
import odlSizes from "odl-components/styles/odlSizes.json";
import clsx from "clsx";
import {customBreakpoints} from "../app/theme";


export const paperStyle = makeStyles((theme) => createStyles({
    root: {
        "&.MuiPaper-rounded": {
            borderRadius: odlSizes.spacing.factor4.s4
        },
        "&.MuiPaper-root": {
            padding: odlSizes.spacing.factor4.s12,
            backgroundColor: odlPalette.light.obj.day
        },
        [theme.breakpoints.down(customBreakpoints.tablet)]: {
            "&.MuiPaper-root": {
                padding: odlSizes.spacing.factor4.s6
            }
        },
        [theme.breakpoints.down(customBreakpoints.mobile)]: {
            "&.MuiPaper-root": {
                padding: odlSizes.spacing.factor4.s2
            }
        }
    }
}));

export function PagePaper(props: PropsWithChildren<{ className?: string, margins?: boolean }>) {
    const paperClasses = paperStyle(props);
    return <Paper elevation={0} className={clsx(paperClasses.root, props.className)}>
        {props.children}
    </Paper>
}

const containerStyles = makeStyles((theme) => createStyles({
    container: {
        marginTop: odlSizes.spacing.factor4.s12,
        marginBottom: odlSizes.spacing.factor4.s16,
        marginRight: odlSizes.spacing.factor4.s16,
        marginLeft: odlSizes.spacing.factor4.s16
    },
    [theme.breakpoints.up(customBreakpoints.desktop)]: {
        container: {
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "1280px",
            width: "100%"
        }
    },
    [theme.breakpoints.down(customBreakpoints.tablet)]: {
        container: {
            marginLeft: odlSizes.spacing.factor4.s8,
            marginRight: odlSizes.spacing.factor4.s8
        }
    },
    [theme.breakpoints.down(customBreakpoints.mobile)]: {
        container: {
            marginLeft: odlSizes.spacing.factor4.s2,
            marginRight: odlSizes.spacing.factor4.s2,
        }
    }
}));


export function PageContainer(props: PropsWithChildren<{ className?: string }>) {
    const containerClasses = containerStyles();
    return <div className={clsx(containerClasses.container, props.className)}>
        {props.children}
    </div>
}

export const pageBottomButtons = makeStyles((theme) => createStyles({
    container: {
        marginTop: odlSizes.spacing.factor4.s6,
        "& #save-submission, & #back-submission-page, & #next-submission-page, & #draft-submission-container": {
            marginRight: odlSizes.spacing.factor4.s4
        },
        "& #draft-submission-container": {
            flexGrow: 1
        },
        [theme.breakpoints.down("xs")] : {
            "& #draft-submission-container": {
                marginBottom: odlSizes.spacing.factor4.s2,
                flexBasis: "100%"
            },
            "& #back-submission-page": {
                marginRight: "auto"
            }
        }
    }
}));