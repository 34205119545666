import {client} from "./axios-client";

const windowFeatures = "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

let windowReference: Window | null = null;

let reloadConfig: (() => void) | null = null;

export const REFRESH_MSG = "Refresh";

export function login(reloadCallback: () => void): void {
    reloadConfig = reloadCallback;

    if (!windowReference || windowReference.closed) {
        windowReference = window.open(window.origin + "/login", "Login", windowFeatures);
    } else {
        windowReference.focus();
    }
    window.addEventListener("message", receiveLoginToken, false);
}

export function logout() {
    client.get("/logout").then(r => {
        window.location.href = window.location.origin;
    });
}

function receiveLoginToken(event: MessageEvent): void {
    //Login redirect must be same origin
    if (event.origin !== window.location.origin || !reloadConfig || event.data !== REFRESH_MSG) {
        return;
    }
    reloadConfig();
    window.removeEventListener("message", receiveLoginToken);
}