import {SearchResult} from "../../placeholder";
import {ColumnTemplate, formatSearchResults, SearchResultState} from "../Search/Search";

export interface DashboardWidgetState {
    title: string;
    searchId: string;
    page: number;
    pageSize: number;
    results?: null | SearchResultState;
    columnTemplates: ColumnTemplate[];
}

export enum DashboardActionType {
    CLEAR, SET, SET_RESULTS, CLEAR_RESULTS
}

export type DashboardReducerAction =
    { type: DashboardActionType.CLEAR } |
    { type: DashboardActionType.SET, widgets: DashboardWidgetState[] } |
    { type: DashboardActionType.SET_RESULTS, searchId: string, page: number, pageSize: number, results: SearchResult } |
    { type: DashboardActionType.CLEAR_RESULTS, searchId: string };

export function dashboardReducer(current: DashboardWidgetState[], action: DashboardReducerAction): DashboardWidgetState[] {
    switch (action.type) {
        case DashboardActionType.CLEAR:
            return [];
        case DashboardActionType.SET:
            return action.widgets;
        case DashboardActionType.SET_RESULTS: {
            return current.map(w => w.searchId === action.searchId ?
                {
                    ...w,
                    page: action.page,
                    pageSize: action.pageSize,
                    results: formatSearchResults(action.results, w.columnTemplates)
                } : w
            );
        }
        case DashboardActionType.CLEAR_RESULTS: {
            const clone = current.map(w => ({...w}));
            clone.filter(c => c.searchId === action.searchId).forEach(c => c.results = {
                searchResultRows: [],
                count: 0
            });
            return clone;
        }
        default:
            throw new Error();
    }
}

