import React, {useContext} from "react";
import {Box, Grid, Menu, MenuItem, Paper, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
    AspectState,
    AspectTypes,
    FieldState,
    FieldTypes,
    FormState,
    MultiSectionRowState,
    WorkflowActionState
} from "./state";
import {Link, useHistory} from "react-router-dom";
import {ButtonTypes, ODL_ICONS} from "odl-components";
import {loadExistingForm, performWorkflowAction} from "./reducer";
import {ISnackBar, useSnackbar} from "../../components/SnackbarProvider";
import {fieldStyle, rowStyle, summaryContentStyle, summaryStyle} from "./FormSummary.style";
import {ODLButton} from "../../components/ODLWrapper";
import {
    atLeastOneAspectEditable, atLeastOneFieldEditable,
    isEditable,
    isEmpty,
    isVisible,
    joinLinkFieldValues,
    joinRefDataNames,
    selectGroupSize,
    selectGroupStates, workflowActions
} from "./form-utils";
import {notUndefined} from "../../util/notUndefined";
import {createSelector} from "@reduxjs/toolkit";
import {AppConfigContext, useLoadingContext} from "../../api/contexts";
import {BackPageButton, ErrorBanner} from "./FormGroupContainer";
import {navButton} from "./FormGroup.style";
import clsx from "clsx";
import {DomainWorkflowResult, workflowFailureMessage, workflowSuccessMessage} from "../../api/workflow-service";
import {processRedirectActions} from "../../api/redirect-service";
import {DraftButton, routeToSubmissionReceipt, SaveButton} from "./FormSubmission";
import {pageBottomButtons} from "../../components/PageComponents";
import {APP_ROUTES} from "../../api/app-routes";

interface SummaryProps {
    baseUrl: string
}

export function FormSummary(props: SummaryProps) {
    const {baseUrl} = props;
    const styles = summaryStyle();
    const errors = useSelector((state: FormState) => state.customErrors);

    return <Box>
        <ErrorBanner errors={errors} className={styles.errorBanner}/>
        <FormSummaryContent baseUrl={baseUrl}/>
        <SummaryButtonBar baseUrl={baseUrl}/>
    </Box>
}

function SummaryButtonBar(props: SummaryProps) {
    const {baseUrl} = props;
    const buttonClasses = pageBottomButtons();
    const newSubmission = useSelector((formState: FormState) => !formState.domainCode);
    const changesPending = useSelector((formState: FormState) => formState.changesPending);
    const aspects = useSelector((formState: FormState) => formState.aspects);
    const inInitialState = useSelector((formState: FormState) => formState.inInitialStatus);
    const groupSize = useSelector(selectGroupSize);
    const showRhsButtons: boolean = newSubmission || changesPending;

    return (inInitialState || newSubmission) && atLeastOneAspectEditable(aspects) ?
        <Grid container className={buttonClasses.container} direction="row-reverse" wrap="wrap-reverse" alignItems="center">
            {showRhsButtons && <SaveButton/>}
            {showRhsButtons && <BackPageButton groupIndex={groupSize} baseUrl={baseUrl}/>}
            <div id="draft-submission-container">
                <DraftButton/>
            </div>
        </Grid> : null;
}

export function WorkflowActionComponent() {
    const actions = useSelector(workflowActions);
    const domainTypeName = useSelector((state: FormState) => state.typeName);
    const snackBar: ISnackBar = useSnackbar();
    const dispatch = useDispatch();
    const portalConfig = useContext(AppConfigContext);
    const history = useHistory();
    const loadingContext = useLoadingContext();

    const buttonStyle = navButton();
    const summaryStyles = summaryStyle();

    const [anchorEl, setAnchorEl] = React.useState<EventTarget & Element | null>(null);
    const handleClick = (event: React.SyntheticEvent) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const successfulWorkflowAction = (results: DomainWorkflowResult, code: string) => {
        if (!processRedirectActions(results.clientActions, history, snackBar, code)) {
            if (results.notification) {
                routeToSubmissionReceipt(history, domainTypeName, results.notification);
            } else {
                dispatch(loadExistingForm(code, portalConfig));
            }
        }
        snackBar.addMessage(workflowSuccessMessage);
    }
    const failedWorkflowAction = (groupInError?: number, domainCode?: string) => {
        if (groupInError !== undefined && domainCode) {
            history.push(`${APP_ROUTES.submissionsEdit}/${domainCode}/${groupInError}`);
        } else {
            snackBar.addMessage(workflowFailureMessage);
        }
    }
    const postWorkflowAction = () => loadingContext.setLoading(false);
    const performHandler = (action: WorkflowActionState) => {
        loadingContext.setLoading(true);
        dispatch(performWorkflowAction(action, successfulWorkflowAction, failedWorkflowAction, postWorkflowAction));
    }

    if (actions.length === 0) {
        return null;
    }
    const primaryButton = <ODLButton a11yId="workflow-primary-action" text={actions[0].name} type={ButtonTypes.PRIMARY}
                                     useWhiteLabel
                                     className={clsx(buttonStyle.navButton, summaryStyles.primaryAction)}
                                     onClickHandler={() => performHandler(actions[0])}/>;
    if (actions.length === 1) {
        return primaryButton;
    }

    let menu;
    if (anchorEl) {
        menu =
            <Menu id="workflow-options-menu" anchorEl={anchorEl} keepMounted open={true} onClose={handleClose}
                  getContentAnchorEl={null} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
                {actions.slice(1).map(a =>
                    <MenuItem key={a.id} className={summaryStyles.menuItem}
                              onClick={() => performHandler(a)} id={"workflow-other-option-" + a.id}>
                        {a.name}
                    </MenuItem>
                )}
            </Menu>;
    }

    return <Box>
        <ODLButton
            type={ButtonTypes.TEXT}
            a11yId="workflow-other-options"
            icon={<span className="icon icon-dots-horizontal"/>}
            className={clsx(buttonStyle.unCapitalise, buttonStyle.otherOptions)}
            text="Other options"
            onClickHandler={event => handleClick(event)}
        />
        {menu}
        {primaryButton}
    </Box>;
}

interface GroupAspects {
    name: string,
    index: number,
    aspects: AspectState[]
}

function FormSummaryContent(props: SummaryProps) {
    const styles = summaryContentStyle();
    const groupData: GroupAspects[] = useSelector(groupAspect);

    return <div>
        {
            groupData.filter(g => g.aspects.length).map(g => <div key={g.index}>
                <Typography variant="h2" className={styles.aspectName}>{g.name}</Typography>
                {g.aspects.map(a =>
                    <Paper elevation={0} key={a.id + "-aspect-summary"} className={styles.aspectSummary}>
                        <Grid container justify="space-between" wrap="wrap-reverse">
                            <div>
                                <Typography variant="h2" className={styles.aspectName}>{a.displayName}</Typography>
                                {a.type === AspectTypes.SINGLE_SECTION && <FieldSummary fields={a.fields}/>}
                                {a.type === AspectTypes.MULTI_SECTION && <RowSummary rows={a.rows}/>}
                            </div>
                            {isEditable(a) && atLeastOneFieldEditable(a) && <Link className={styles.editLink} to={`${props.baseUrl}/${g.index}`}>
                                <span className={`icon ${ODL_ICONS.PENCIL}`}/>
                                Edit
                            </Link>}
                        </Grid>
                    </Paper>)
                }
            </div>)
        }
    </div>
}

const groupAspect = createSelector(
    selectGroupStates,
    (state: FormState) => state.aspects,
    (groups, aspects) =>
        groups.map((g, i) => ({
            name: g.name,
            index: i,
            aspects: g.aspectIds
                .map(id => aspects.find(a => a.id === id))
                .filter(notUndefined)
                .filter(isVisible)
                .filter(shouldDisplayAspect)
        }))
);

function shouldDisplayAspect(aspect: AspectState): boolean {
    if (!isVisible(aspect)) {
        return false;
    }
    switch (aspect.type) {
        case AspectTypes.SINGLE_SECTION:
            return aspect.fields.some(f => fieldSummaryVisible(f));
        case AspectTypes.MULTI_SECTION:
            return aspect.rows.some(r => !r.removed && r.fields.some(f => fieldSummaryVisible(f)));
        default:
            return false;
    }
}

function FieldSummary(props: { fields: FieldState[] }) {
    const style = fieldStyle();

    return <div>
        {props.fields.filter(fieldSummaryVisible).map(f =>
            <div key={f.template.id + "-field-summary"} className={style.container}>
                <label>{f.template.displayName}</label>
                <DisplayFieldValue {...f} />
            </div>
        )}
    </div>;
}

function RowSummary(props: { rows: MultiSectionRowState[] }) {
    const styles = rowStyle();
    return <div className={styles.rowContainer}>
        {props.rows.filter(r => !r.removed)
            .map(r =>
                <div key={r.key}>
                    <FieldSummary fields={r.fields}/>
                </div>
            )
        }
    </div>;
}

function DisplayFieldValue(field: FieldState) {
    const style = fieldStyle();

    switch (field.type) {
        case FieldTypes.CHECK_BOX:
            return <div className={style.value}>{field.value ? "Yes" : "No"}</div>;
        case FieldTypes.DROPDOWN_MULTI:
        case FieldTypes.CHECKBOX_GROUP:
            return <div className={style.value}>{joinRefDataNames(field.value)}</div>;
        case FieldTypes.DROPDOWN_SINGLE:
        case FieldTypes.RADIO:
            return <div className={style.value}>{field.value?.name}</div>;
        case FieldTypes.FILE_UPLOAD:
            return <div>
                {field.value.map(f => <div key={f.path} className={style.value}>{f.name}</div>)}
            </div>;
        case FieldTypes.LABEL:
            return null;
        case FieldTypes.DATE:
        case FieldTypes.DATE_TIME:
            return <div className={style.value}>{field.value?.dateString}</div>;
        case FieldTypes.LINK:
            return <div className={style.value}>{joinLinkFieldValues(field.value)}</div>;
        default:
            return <div className={style.value}>{field.value}</div>;
    }
}

function fieldSummaryVisible(field: FieldState): boolean {
    return isVisible(field) && !isEmpty(field);
}
