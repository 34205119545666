import {createStyles, makeStyles} from "@material-ui/core/styles";
import odlPalette from "odl-components/styles/odlProPalette"
import odlSizes from "odl-components/styles/odlSizes.json";

export const receiptStyles = makeStyles((theme) => createStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        "& > button": {
            alignSelf: "flex-end"
        }
    },
    content: {
        display: "flex",
        flexDirection: "column",
        padding: odlSizes.spacing.factor4.s8,
        marginBottom: odlSizes.spacing.factor4.s12,
    },
    receiptHeader: {
        marginBottom: odlSizes.spacing.factor4.s6,
        fontSize: odlSizes.typography.size.xlarge,
        fontWeight: 500,
        lineHeight: odlSizes.spacing.factor4.s5
    },
    checkedIcon: {
        fontSize: odlSizes.spacing.factor4.s6,
        color: odlPalette.light.obj.green,
        marginBottom: odlSizes.spacing.factor4.s6,
    }
}))