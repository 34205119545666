import {
    AspectState,
    AspectTypes,
    ErrorType,
    FieldState,
    FieldTypes,
    FileUploadFieldState,
    FileValue,
    FormState,
    LinkFieldValue,
    MultiSectionRowState, WorkflowActionState
} from "./state";
import {ReferenceDataValue} from "../../placeholder";
import {blankIfUndefined, notUndefined} from "../../util/notUndefined";
import {listRefDataByCode, listRefDataByPath} from "../../api/ref-data-service";
import {
    CODE_NAME_DELIMITER,
    INVALID_DATE_MSG,
    ITEM_DELIMITER,
    MISSING_DATA_MSG,
    MISSING_OPTION_MSG,
    NULL_DATA_VALUE,
    SUMMARY_PAGE_MULTI_VALUE_DELIMITER
} from "../../api/constants";
import {createSelector} from "@reduxjs/toolkit";

export const selectGroupStates = createSelector(
    (state: FormState) => state.groups,
    (state: FormState) => state.aspects,
    (groups, aspects) =>
        groups.filter(g => atLeastOneAspectVisible(g.aspectIds, aspects))
);

export const selectGroupSize = createSelector(
    selectGroupStates,
    (groups) => groups.length
);

export const selectGroups = createSelector(
    (state: FormState) => state.groups,
    (state: FormState) => state.aspects,
    (groups, aspects) =>
        groups.map(g => {
            return {
                name: g.name,
                aspects: g.aspectIds.map(id => aspects.find(a => a.id === id))
                    .filter(notUndefined)
            };
        })
);

export const inProgressFileUploadFields = createSelector(
    (state: FormState) => state.aspects,
    (aspects) => {
        let inProgressFields: FileValue[] = [];
        aspects.forEach(aspect => {
            switch (aspect.type) {
                case AspectTypes.SINGLE_SECTION:
                    inProgressFields.push(...getPendingFileUploadFields(aspect.fields));
                    break;
                case AspectTypes.MULTI_SECTION:
                    inProgressFields.push(...getPendingFileUploadFields(aspect.rows.map(r => r.fields).flat()))
                    break;
            }
        });
        return inProgressFields;
    }
);

export const workflowActions = createSelector(
    (state: FormState) => state.actions,
    (actions) => actions.filter(isWorflowActionVisible)
);


export function isVisible(formElement: AspectState | FieldState): boolean {
    return formElement.template.visible && formElement.activeRules.hide.length === 0
        && (formElement.type !== FieldTypes.LINK || !isEmpty(formElement));
}

export function isRowVisible(state: MultiSectionRowState): boolean {
    return !state.removed && state.activeRules.hide.length === 0;
}

export function isWorflowActionVisible(state: WorkflowActionState): boolean {
    return state.activeRules.hide.length === 0;
}

export function isEditable(formElement: AspectState | FieldState): boolean {
    return formElement.template.editable && formElement.activeRules.disable.length === 0;
}

export function isMandatory(formElement: FieldState): boolean {
    return formElement.template.mandatory || formElement.activeRules.require.length > 0;
}

export function isEmpty(fieldState: FieldState): boolean {
    switch (fieldState.type) {
        case FieldTypes.TEXT:
        case FieldTypes.DATE_TIME:
        case FieldTypes.DATE:
        case FieldTypes.DROPDOWN_SINGLE:
        case FieldTypes.RADIO:
            return !fieldState.value;
        case FieldTypes.DROPDOWN_MULTI:
        case FieldTypes.CHECKBOX_GROUP:
        case FieldTypes.FILE_UPLOAD:
        case FieldTypes.LINK:
            return fieldState.value.length === 0;
        case FieldTypes.NUMBER:
            return !fieldState.value && fieldState.value !== 0;
        case FieldTypes.CHECK_BOX:
            return false;
        default:
            return true;
    }
}

const bannerError: string = "MuiAlert-standardError";
const fieldError: string = "Mui-error";

export function findFirstErrorClass(aspects: AspectState[]): string | null {
    for (let aspect of aspects) {
        if (hasCustomError(aspect)) {
            return bannerError;
        }
        if (aspect.type === AspectTypes.SINGLE_SECTION &&
            aspect.fields.find((f) => hasVisibleError(f))) {
            return fieldError;
        }
        if (aspect.type === AspectTypes.MULTI_SECTION) {
            let errorClass;
            for (let row of aspect.rows) {
                errorClass = firstVisibleRowError(row);
                if (errorClass) {
                    return errorClass;
                }
            }
        }
    }
    return null;
}

export function firstVisibleRowError(row: MultiSectionRowState): string | null {
    if (isRowVisible(row)) {
        if (hasCustomError(row)) {
            return bannerError;
        } else if (row.fields.find((f) => hasVisibleError(f))) {
            return fieldError;
        }
    }
    return null;
}

export function atLeastOneFieldVisible(aspect: AspectState): boolean {
    if (AspectTypes.SINGLE_SECTION === aspect.type) {
        return aspect.fields.some(isVisible);
    } else if (AspectTypes.MULTI_SECTION === aspect.type) {
        return aspect.fieldTemplates.some(isVisible);
    }
    return false;
}

export function atLeastOneAspectVisible(aspectIds: string[], aspects: AspectState[]): boolean {
    return aspectIds.some(id => {
        for (let aspect of aspects) {
            if (aspect.id === id) {
                return isVisible(aspect) && atLeastOneFieldVisible(aspect);
            }
        }
        return false;
    });
}

export function atLeastOneFieldEditable(aspect: AspectState): boolean {
    if (AspectTypes.SINGLE_SECTION === aspect.type) {
        return aspect.fields.some(isEditable);
    } else if (AspectTypes.MULTI_SECTION === aspect.type) {
        return aspect.fieldTemplates.some(isEditable);
    }
    return false;
}

export function atLeastOneAspectEditable(aspects: AspectState[]): boolean {
    return aspects.some(aspect => {
        return isEditable(aspect) && atLeastOneFieldEditable(aspect);
    })
}

const errorText: { [key in ErrorType]: string | { (field: FieldState): string } } = {
    [ErrorType.BELOW_MIN]: field => FieldTypes.NUMBER === field.type ? `Please enter a value greater than or equal to ${field.min}` : "",
    [ErrorType.OVER_MAX]: field => {
        switch (field.type) {
            case FieldTypes.NUMBER:
                return `Please enter a value less than or equal to ${field.max}`;
            case FieldTypes.TEXT:
                return `Max number of characters is ${field.maxLength}`;
            default:
                return "";
        }
    },
    [ErrorType.MALFORMED]: field => {
        switch (field.type) {
            case FieldTypes.DATE:
            case FieldTypes.DATE_TIME:
                return INVALID_DATE_MSG;
            case FieldTypes.TEXT:
            case FieldTypes.NUMBER:
                return blankIfUndefined(field.regExValidation?.failedMsg);
            default:
                return "";
        }
    },
    [ErrorType.MISSING]: field => {
        switch (field.type) {
            case FieldTypes.CHECKBOX_GROUP:
            case FieldTypes.DROPDOWN_SINGLE:
            case FieldTypes.RADIO:
                return MISSING_OPTION_MSG;
            default:
                return MISSING_DATA_MSG;
        }
    },
    [ErrorType.CUSTOM_ERRORS]: field => field.customErrors.map(ce => ce.text).join(", ")
}


export function getErrorText(field: FieldState): string | undefined {
    return (Object.keys(field.errors) as ErrorType[])
        .filter(k => field.errors[k])
        .map(e => {
            const error = errorText[e];
            return typeof error === "function" ? error(field) : error;
        })
        .filter(e => e)
        .join(", ");
}

export function aspectsHaveErrors(aspects: AspectState[]): boolean {
    return aspects.some(hasError);
}

export function hasError(state: AspectState | FieldState): boolean {
    switch (state.type) {
        case AspectTypes.SINGLE_SECTION:
            return hasCustomError(state) || state.fields.some(hasError);
        case AspectTypes.MULTI_SECTION:
            return hasCustomError(state) ||
                state.rows.some(r => r.customErrors.length !== 0) ||
                state.rows.flatMap(r => r.fields).some(hasError);
        default:
            return Object.keys(state.errors).some(k => state.errors[k as ErrorType]);
    }
}

export function hasVisibleError(state: AspectState | FieldState): boolean {
    return hasError(state) && isVisible(state);
}

export function hasCustomError(state: AspectState | FieldState | MultiSectionRowState) {
    return state.customErrors.length !== 0;
}

export function clearErrors(state: AspectState | FieldState): void {
    switch (state.type) {
        case AspectTypes.SINGLE_SECTION:
            return state.fields.forEach(clearErrors);
        case AspectTypes.MULTI_SECTION:
            return state.rows.flatMap(r => r.fields).forEach(clearErrors);
        default:
            return Object.keys(state.errors).forEach(k => state.errors[k as ErrorType] = false);
    }
}

export function retrieveRefDataOptions(fieldName: string, sectionName?: string, templateName?: string,
                                       codeTableKey?: string, parentCode?: string): Promise<ReferenceDataValue[]> {
    if (notUndefined(templateName) && notUndefined(sectionName)) {
        return listRefDataByPath(templateName, sectionName, fieldName, parentCode);
    } else if (notUndefined(codeTableKey)) {
        return listRefDataByCode(codeTableKey, parentCode);
    }
    return Promise.resolve([]);
}

export function convertFieldValueToRefDataValue(fieldValue: string): ReferenceDataValue[] {
    let referenceDataValues: ReferenceDataValue[] = [];
    if (!fieldValue || fieldValue === NULL_DATA_VALUE) {
        return referenceDataValues;
    }
    fieldValue = fieldValue.trim();
    let splitRefData: string[] = fieldValue.split(ITEM_DELIMITER);

    for (let refDataString of splitRefData) {
        const items = splitCombinedRefDataString(refDataString);
        if (items) {
            referenceDataValues.push(items);
        }
    }

    return referenceDataValues;
}

export function splitCombinedRefDataString(combinedString: string | undefined): ReferenceDataValue | null {
    if (!combinedString) {
        return null;
    }
    let splitItem: string[] = combinedString.split(CODE_NAME_DELIMITER);
    return {
        code: splitItem[0],
        name: splitItem[1]
    };
}

export function joinRefDataNames(fieldValue: ReferenceDataValue[]): string {
    return fieldValue.map(field => field.name).join(SUMMARY_PAGE_MULTI_VALUE_DELIMITER);
}

export function joinLinkFieldValues(fieldValue: LinkFieldValue[]): string {
    return fieldValue.map(field => `${field.name} (${field.code})`).join(SUMMARY_PAGE_MULTI_VALUE_DELIMITER);
}

export function convertRefDataValuesToFieldValue(refDataList: ReferenceDataValue[]): string {
    return refDataList.map(convertSingleRefDataValueToFieldValue).join(ITEM_DELIMITER);
}

export function convertSingleRefDataValueToFieldValue(refDataItem: ReferenceDataValue): string {
    return refDataItem.code + CODE_NAME_DELIMITER + refDataItem.name;
}

function getPendingFileUploadFields(fields: FieldState[]): FileValue[] {
    return fields.filter(f => f.type === FieldTypes.FILE_UPLOAD)
        .map(f => (f as FileUploadFieldState).value).flat()
        .filter(f => f.tempFileUploadId);
}
