import {createStyles, makeStyles} from "@material-ui/core";
import odlPalette from "odl-components/styles/odlProPalette";
import odlSizes from "odl-components/styles/odlSizes.json";
import {IconButton, odlStyled} from "odl-components";
import {ODLButton} from "../../components/ODLWrapper";

export const dashboardWidgetStyle = makeStyles((theme) => createStyles({
    widgetContainer: {
        marginTop: odlSizes.spacing.factor4.s6,
        marginBottom: odlSizes.spacing.factor4.s6
    },
    row: {
        borderBottomColor: odlPalette.light.obj.day,
        borderBottom: "2px solid",
        backgroundColor: odlPalette.light.obj.white
    },
    clickable: {
        cursor: "pointer"
    },
    table: {
        width: "100%",
        borderCollapse: "collapse",
        whiteSpace: "nowrap"
    },
    cell: {
        paddingTop: odlSizes.spacing.factor4.s4,
        paddingBottom: odlSizes.spacing.factor4.s4,
    },
    emptyCell: {
        border: "unset"
    },
    actionIcon: {
        background: "unset",
        color: odlPalette.light.obj.blue
    }
}));

export const workflowActionStyles = makeStyles(() => createStyles({
    heading: {
        padding: `${odlSizes.spacing.factor4.s2} ${odlSizes.spacing.factor4.s4}`,
        fontWeight: 500,
        borderBottom: `solid 1px ${odlPalette.light.obj.day}`
    },
    menu: {
        "& > .MuiPaper-root": {
            borderRadius: odlSizes.spacing.factor4.s2
        }
    },
    item: {
        color: odlPalette.light.obj.blue,
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: odlSizes.spacing.factor4.s6,
        "& > span": {
            marginRight: "45px"
        }
    },
    actionCell: {
        textAlign: "right",
        "& > button:only-child": {
            marginRight: odlSizes.spacing.factor4.s13
        }
    }
}));

export const PrimaryActions = odlStyled(ODLButton)`
    &.odlButton__root {
        padding: ${odlSizes.spacing.factor4.s2} ${odlSizes.spacing.factor4.s4};
        line-height: 22px;
        border-radius: ${odlSizes.spacing.factor4.s8};
        margin-right: ${odlSizes.spacing.factor4.s5};
    }
`

export const SecondaryActions = odlStyled(IconButton)`
    &.odlIconButton__root {
        background: unset;
        color: ${odlPalette.light.obj.blue}
    }
`