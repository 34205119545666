import React, {useContext} from 'react';
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import {DashboardPage} from "./DashboardPage/DashboardPage";
import {FormSelector} from "./FormSelector/FormSelector";
import {APP_ROUTES} from "../api/app-routes";
import {FormContainer, FormContainerRouteState} from "./form/FormContainer";
import {Receipt, FormReceiptProps} from "./form/FormReceipt";
import {fallbackIfUndefined} from "../util/notUndefined";
import {Location} from 'history'
import {AppLoginContext, UserProfile} from "../api/contexts";

export function SubmissionsPage() {
    const history = useHistory();
    let location: Location = history.location;
    const profile: UserProfile = useContext(AppLoginContext).profile;

    const authenticatedRoutes =
        <Switch>
            <Route path={APP_ROUTES.submissionsNewType} component={FormContainer}/>
            <Route path={APP_ROUTES.submissionsEditDomain} render={({match}) =>
                <FormContainer match={match} state={{...location.state as FormContainerRouteState}}/>}/>
            <Route path={APP_ROUTES.submissionsNew} component={FormSelector}/>
            <Route path={APP_ROUTES.submissionsDashboard} component={DashboardPage}/>
            <Route path={APP_ROUTES.submissionsReceipt} render={() => (
                showReceipt ? <Receipt {...location.state as FormReceiptProps}/> :
                    <Redirect to={APP_ROUTES.submissionsDashboard}/>
            )}/>
        </Switch>

    const unauthenticatedRoutes =
        <Switch>
            <Route path={APP_ROUTES.submissionsDashboard} render={() => <Redirect to={APP_ROUTES.submissionsNew}/>}/>
            <Route path={APP_ROUTES.submissionsNewType} component={FormContainer}/>
            <Route path={APP_ROUTES.submissionsNew} component={FormSelector}/>
            <Route path={APP_ROUTES.submissionsReceipt} render={() => (
                showReceipt ? <Receipt {...location.state as FormReceiptProps}/> :
                    <Redirect to={APP_ROUTES.submissionsNew}/>
            )}/>
        </Switch>

    let showReceipt: boolean = fallbackIfUndefined(!!(location.state as FormReceiptProps)?.notification, false);
    return !profile.anonymousUser ? authenticatedRoutes : unauthenticatedRoutes;
}