import React from "react";
import {ODLButton} from "../../components/ODLWrapper";
import {Paper, Typography} from "@material-ui/core";
import {receiptStyles} from "./FormReceipt.style";
import sanitizeHtml from "sanitize-html";
import {useHistory} from "react-router-dom";
import {APP_ROUTES} from "../../api/app-routes";
import {PageHeader} from "../../components/PageHeader";
import {ButtonTypes, ODL_ICONS} from "odl-components";
import {navButton} from "./FormGroup.style";
import clsx from "clsx";
import {PageContainer, PagePaper} from "../../components/PageComponents";

export interface FormReceiptProps {
    notification: string;
    domainTypeName: string;
}

export function Receipt(props: FormReceiptProps) {
    const styleClasses = receiptStyles();
    const buttonStyle = navButton();
    const history = useHistory();
    const newSubmissionHandler = () => history.push(APP_ROUTES.submissionsNew);

    return <PageContainer>
        <PageHeader header={props.domainTypeName} subHeader={"Submitted"}/>
        <PagePaper className={styleClasses.container}>
            <Paper elevation={0} className={styleClasses.content}>
                <span className={clsx("icon", ODL_ICONS.CHECKBOX_MARKED_CIRCLE_OUTLINE, styleClasses.checkedIcon)}/>
                <Typography variant="h2" className={styleClasses.receiptHeader}>The form has been submitted</Typography>
                {!!props.notification && <div dangerouslySetInnerHTML={{__html: sanitizeHtml(props.notification)}}/>}
            </Paper>
            <ODLButton onClickHandler={() => newSubmissionHandler()} className={clsx(buttonStyle.navButton, buttonStyle.commonMargin)}
                       text="New Submission" useWhiteLabel
                       a11yId="receipt-new-submission"
                       icon={<span className="icon icon-plus" />}
                       type={ButtonTypes.PRIMARY}/>
        </PagePaper>
    </PageContainer>;
}