import {createStyles, emphasize, makeStyles} from "@material-ui/core/styles";
import odlPalette from "odl-components/styles/odlProPalette";
import odlSizes from "odl-components/styles/odlSizes.json";

export const bannerStyles = makeStyles(theme => createStyles({
    header: {
        backgroundColor: theme.palette.globalHeader.main,
        flex: "0 0 180px",
        maxHeight: "180px",
        padding: "48px 64px 40px 64px"
    },
    content: {
        "&.MuiGrid-item": {
            flex: "1 0 auto"
        }
    },
    footer: {
        backgroundColor: theme.palette.globalFooter.main,
        padding: "44px 64px 32px 64px",
        "& #footer-logo": {
            marginRight: odlSizes.spacing.factor4.s3,
            maxHeight: odlSizes.spacing.factor4.s16
        },
        "& #rw-sub-logo": {
            marginLeft: "auto",
            alignSelf: "flex-end",
            marginTop: odlSizes.spacing.factor4.s4,
            "& path": {
                fill: theme.palette.getContrastText(theme.palette.globalFooter.main)
            }
        },
        "& > *:only-child": {
            marginLeft: "auto"
        }
    }
}));

export const logoStyles = makeStyles({
    anchor: {
        display: "contents"
    },
    image: {
        maxHeight: "100%",
        maxWidth: "100%",
        objectFit: "scale-down",
        objectPosition: "left"
    }
});

export const navigationMenuStyles = makeStyles((theme) => createStyles({
    navigationMenuPanel: {
        height: "100%",

        "&$navigationPanelOverride": {
            backgroundColor: theme.palette.globalHeader.main,
            "& a": {
                outline: "none"
            }
        },

        "& button": {
            backgroundColor: theme.palette.globalHeader.main,
            color: theme.palette.getContrastText(theme.palette.globalHeader.main),
            "&:hover, &:active, &:focus": {
                backgroundColor: emphasize(theme.palette.globalHeader.main, theme.palette.action.hoverOpacity),
                color: theme.palette.getContrastText(emphasize(theme.palette.globalHeader.main, theme.palette.action.hoverOpacity)),
            }
        },

        "& ul > li > a": {
            backgroundColor: theme.palette.globalHeader.main,
            border: "unset",
            "& span": {
                color: theme.palette.getContrastText(theme.palette.globalHeader.main)
            },
            "&:hover, &:active, &:focus": {
                backgroundColor: emphasize(theme.palette.globalHeader.main, theme.palette.action.hoverOpacity),
                "& span": {
                    color: theme.palette.getContrastText(emphasize(theme.palette.globalHeader.main, theme.palette.action.hoverOpacity)),
                }
            }
        }
    },
    navigationPanelOverride: {}
}));

export const profileMenuStyles = makeStyles((theme) => createStyles({
    userProfileButton: {
        "&.MuiButton-root": {
            textTransform: "none",
        },
        '&:hover, &:active, &:focus': {
            backgroundColor: emphasize(theme.palette.globalHeader.main, theme.palette.action.hoverOpacity),
            color: theme.palette.getContrastText(emphasize(theme.palette.globalHeader.main, theme.palette.action.hoverOpacity))
        },
        marginTop: odlSizes.spacing.factor4.s2,
        marginBottom: odlSizes.spacing.factor4.s2,
        height: odlSizes.spacing.factor4.s9
    },
    menuItemContent: {
        marginRight: odlSizes.spacing.factor4.s4
    }
}));

export const ODLMenuStyled = makeStyles({
    list: {
        padding: `${odlSizes.spacing.factor4.s2} 0`,
        margin: 0,
        borderRadius: odlSizes.spacing.factor4.s1
    },
    paper: {
        '&.MuiPaper-elevation8': {
            boxShadow: '0px 3px 5px rgba(0,0,0,0.3)'
        }
    }
});

const selected = {
    backgroundColor: odlPalette.light.obj.lightBlue,
    color: odlPalette.light.obj.blue
};

export const ODLMenuItemStyled = makeStyles({
    root: {
        color: odlPalette.light.obj.night,
        fontFamily: odlSizes.typography.baseFamily,
        fontSize: `${odlSizes.typography.size.medium}`,
        lineHeight: odlSizes.spacing.factor4.s6,
        padding: `${odlSizes.spacing.factor4.s2} ${odlSizes.spacing.factor4.s4}`,
        borderLeft: "4px solid transparent",

        '&.MuiListItem-button:hover': selected,
        '&.MuiListItem-button:focus': {
            ...selected,
            borderLeft: "4px solid",
        },
        '&.MuiListItem-button > span.icon': {
            marginRight: odlSizes.spacing.factor4.s4,
            color: odlPalette.light.obj.night
        }
    },
    selected
});

export const backdropStyle = makeStyles((theme) => createStyles({
    backdrop: {
        zIndex: theme.zIndex.modal,
        color: odlPalette.light.obj.white,
    },
}));