/**
 * RegulationWorks REST
 * No descripton provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

'use strict';
import * as models from './models';

export interface RuleValueReference {


    constant?: Array<string>;

    standardFieldName?: string;

    sectionTemplateId?: string;

    fieldTemplateId?: string;

    transitionId?: string;

    refType?: RefTypeEnum;
}

    export enum RefTypeEnum {
        CONSTANT = <any> 'CONSTANT',
        FIELD = <any> 'FIELD',
        SECTION = <any> 'SECTION',
        STANDARD = <any> 'STANDARD',
        WORKFLOW_TRANSITION = <any> 'WORKFLOW_TRANSITION'
    }
