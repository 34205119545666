import {client} from "./axios-client";
import {Validation} from "../placeholder";
import AlertTypes from "odl-components/components/Alert/Alert.types";
import {IMessage} from "../components/SnackbarProvider";
import {CancelTokenSource} from "axios";

export interface DomainWorkflowResult {
    validation: Validation;
    clientActions: string[];
    notification: string;
}

export async function executeWorkflowAction(code: string, actionId: string, waitForIndex: boolean = false): Promise<DomainWorkflowResult> {
    const queryParams = {params: {waitForIndex}};
    const response = await client.post(`/api/v1/domains/${code}/workflows/${actionId}`,null, queryParams);
    return response.data;
}

export interface DomainWorkflowList {
    pageSize: number;
    pageOffset: number;
    count: number;
    results: DomainWorkflowListItem[];
}

export interface DomainWorkflowListItem {
    id: string;
    name: string;
    actionType: string;
    targetStatus?: string;
    confirmText?: string;
}

export async function getWorkflowActions(code: string, cancelToken?: CancelTokenSource): Promise<DomainWorkflowList> {
    const response = await client.get(`/api/v1/domains/${code}/workflows/`, {cancelToken: cancelToken?.token});
    return response.data;
}

export const workflowSuccessMessage: IMessage = {
    text: "Action Complete",
    type: AlertTypes.SUCCESS
};

export const workflowFailureMessage: IMessage = {
    text: "Failed to perform action",
    type: AlertTypes.ERROR
};