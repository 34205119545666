import React, {createContext, PropsWithChildren, useContext, useState} from "react";
import {getFileUrl, uploadFile} from "../api/document-service";
import {useDispatch, useSelector} from "react-redux";
import {FileUploadFieldState, FileValue, FormState} from "../features/form/state";
import * as uuid from "uuid";
import {formSlice, performFieldUpdate} from "../features/form/reducer";
import {FormFieldProps} from "../features/form/FieldComponents";
import {AppConfigContext} from "../api/contexts";
import {useSnackbar} from "./SnackbarProvider";
import {AlertTypes} from "odl-components";

export interface IUploadFilesContext {
    files: {[key: string]: PendingFile};
    addFiles: (files: File[], state: FormFieldProps) => void;
    removeFile: (id: string) => void;
}

export interface PendingFile {
    id: string;
    file: File;
    progress: number; // min 0 max 100
}

export interface FileUrlResponse {
    index: number;
    documentPath: string;
    uploadUrl: string;
    uploadMethod: string;
}

const contextDefault: IUploadFilesContext = {
    files: {},
    addFiles: (files: File[], state: FormFieldProps) => {},
    removeFile: (id: string) => {},
}

const UploadFilesContext = createContext(contextDefault);

export const useUploadingFiles = () => useContext(UploadFilesContext);

function createPendingFiles(fileValues: FileValue[], files: File[]) {
    let pendingFileUpdate: { [key: string]: PendingFile } = {};
    fileValues.map((f, i) => ({id: f.tempFileUploadId as string, file: files[i], progress: 0}))
        .forEach(p => pendingFileUpdate[p.id] = p);
    return pendingFileUpdate;
}

export function UploadFilesProvider(props: PropsWithChildren<any>) {
    const [files, setFiles] = useState<{[key: string]: PendingFile}>({});
    const domainCode = useSelector((state: FormState) => state.domainCode);
    const typeId = useSelector((state: FormState) => state.typeId);
    const dispatch = useDispatch();
    const portalConfig = useContext(AppConfigContext);
    const snackbar = useSnackbar();

    const onUploadProgress = (pendingFile: PendingFile, event: ProgressEvent) => {
        let progress: number = Math.round(event.loaded / event.total * 100);
        let updatedFile: PendingFile = {...pendingFile, progress: progress};
        setFiles((state) =>({...state, [pendingFile.id]: updatedFile}));
    };

    const addFiles = (files: File[], state: FormFieldProps) => {
        Promise.allSettled(files.map(file =>
            {
                if (portalConfig.documentMaxUploadSizeMb * 1024 * 1024 < file.size) {
                    return Promise.reject({
                        type: AlertTypes.ERROR,
                        text: file.name + ": file too large, maximum upload size is " + portalConfig.documentMaxUploadSizeMb + "MB"
                    });
                }
                return getFileUrl(domainCode, file.name, typeId);
            })).then(results => {
                let fileUrlResponses: FileUrlResponse[] = [];
                results.forEach((result, index) => {
                    if (result.status === "fulfilled") {
                        fileUrlResponses.push({
                            index: index,
                            documentPath: result.value.data.documentPath,
                            uploadUrl: result.value.data.uploadUrl,
                            uploadMethod: result.value.data.uploadMethod
                        });
                    } else {
                        const reason = result.reason;
                        if (reason.text && reason.type) {
                            snackbar.addMessage(reason);
                        } else if (reason.response) {
                            snackbar.addMessage({
                                type: AlertTypes.ERROR,
                                text: files[index].name + ": " + reason.response.data
                            });
                        }
                    }
                });

                const fieldState: FileUploadFieldState = state.field as FileUploadFieldState;
                let fileValues: FileValue[] = fileUrlResponses.map(response =>
                    ({
                        name: files[response.index].name,
                        path: response.documentPath,
                        tempFileUploadId: uuid.v4(),
                        isNew: true
                    })
                );

                let updatedFileValues = [...fieldState.value, ...fileValues];
                dispatch(formSlice.actions.updateFieldValue({...state, value: updatedFileValues}));
                let pendingFileUpdate = createPendingFiles(fileValues, files);
                setFiles((state) => ({...state, ...pendingFileUpdate}));

                Promise.allSettled(fileValues.map((f, i) =>
                     uploadFile(domainCode, typeId, files[fileUrlResponses[i].index], fileUrlResponses[i],
                        (event) => onUploadProgress(pendingFileUpdate[f.tempFileUploadId as string], event))
                ))
                    .then(results => {
                        let successfulFileUploads: {[key: string] : boolean} = {};
                        results.forEach(((result, index) => {
                            if (result.status === "fulfilled") {
                                successfulFileUploads[fileValues[index].path] = true;
                            } else {
                                snackbar.addMessage({
                                    type: AlertTypes.ERROR,
                                    text: fileValues[index].name + ": " + result.reason.response.data
                                });
                            }
                            removeFile(fileValues[index].tempFileUploadId as string);
                        }));
                        updatedFileValues = updatedFileValues
                            .map(f => !f.tempFileUploadId || !successfulFileUploads[f.path] ? f :
                                {...f, tempFileUploadId: undefined})
                            .filter(f => !f.tempFileUploadId);
                        dispatch(performFieldUpdate({...state, value: updatedFileValues}));
                    });
            });
    }

    const removeFile = (id: string) => {
        setFiles(state => {
            const {[id]: removePendingFile, ...pendingFiles} = state;
            return pendingFiles;
        });
    };

    return <UploadFilesContext.Provider value={{files, removeFile, addFiles}}>
        {props.children}
    </UploadFilesContext.Provider>
}