export const serverDateTimeFormat = "dd/MM/yyyy HH:mm";
export const serverDateFormat = "dd/MM/yyyy";
export const INVALID_DATE_MSG = "Please enter a valid date";
export const MISSING_DATA_MSG = "This field is required";
export const MISSING_OPTION_MSG = "Please choose an option";
export const acceptTypesFileUploadKey = "FILE_TYPE";
export const ITEM_DELIMITER: string = "::";
export const CODE_NAME_DELIMITER: string = ";";
export const NULL_DATA_VALUE: string = "_NULL_;_NULL_";
export const SUMMARY_PAGE_MULTI_VALUE_DELIMITER = ", ";
