import {Grid} from '@material-ui/core';
import {ButtonTypes, Loader} from 'odl-components';
import React, {useCallback, useEffect, useReducer, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
    DashboardItem,
    getDashboardWidgets,
    listDashboards,
    performSavedSearch
} from '../../api/dashboard-service';
import {DashboardWidgetElement} from './DashboardWidget';

import {DashboardActionType, dashboardReducer} from './DashboardPage.reducer';
import {PageHeader} from '../../components/PageHeader';
import {APP_ROUTES} from '../../api/app-routes';
import {ODLButton} from "../../components/ODLWrapper";
import {navButton} from "../form/FormGroup.style";
import clsx from "clsx";
import {useLoadingContext} from "../../api/contexts";
import {dashboardPageStyle, TabsStyled} from "./DashboardPage.style";
import {PageContainer, PagePaper} from "../../components/PageComponents";

/**
 * A Header, a "new submissions" button, and an array of dashboards
 */
export function DashboardPage() {
    // State
    const [dashboards, setDashboards] = useState<DashboardItem[]>([]);
    const [widgets, dispatchWidgets] = useReducer(dashboardReducer, []);
    const [selectedDashboard, setSelectedDashboard] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const loadingContext = useLoadingContext();
    const DEFAULT_PAGE_SIZE = 10;

    const dashboardId = selectedDashboard < dashboards.length ? dashboards[selectedDashboard].id : null;

    const updateOrClearResults = useCallback((searchId: string, page: number, pageSize: number) => {
        const pageOffset = page * pageSize;
        return performSavedSearch(searchId, pageOffset, pageSize).then(r => {
            if (page && r.data.count && !r.data.searchResultRows?.length) {
                // hit a pageOffset of no result (possibly result becomes less when searching), research the last page
                updateOrClearResults(searchId, Math.ceil(r.data.count / pageSize) - 1, pageSize);
            } else {
                dispatchWidgets({ type: DashboardActionType.SET_RESULTS, searchId, page, pageSize, results: r.data })
            }
        }).catch(r => dispatchWidgets({ type: DashboardActionType.CLEAR_RESULTS, searchId }))
    }, []);

    // Request a list of all dashboards (on load only)
    useEffect(() => {
        listDashboards().then(d => {
            setDashboards(d);
            setIsLoading(false);
        });
    }, []);

    // Request the widgets for the given dashboard (on dashboardId change)
    useEffect(() => {
        if (dashboardId != null) {
            dispatchWidgets({ type: DashboardActionType.CLEAR });
            getDashboardWidgets(dashboardId).then(loadedWidgets => {
                const widgets = loadedWidgets.map(w => ({
                    ...w,
                    page: 0,
                    pageSize: DEFAULT_PAGE_SIZE
                }));
                dispatchWidgets({type: DashboardActionType.SET, widgets});
                widgets.map(w => updateOrClearResults(w.searchId, w.page, w.pageSize));
            });
        }
    }, [dashboardId, updateOrClearResults]);

    const onWidgetRefresh = () => {
        let searchRequest = widgets.map(w => updateOrClearResults(w.searchId, w.page, w.pageSize));
        Promise.allSettled(searchRequest).then(() => loadingContext.setLoading(false));
    };

    // Convert to tab object
    const tabs = dashboards.map((d, i) => { return { label: d.title, value: '' + i, disabled: false } })

    const loader = isLoading ? <Loader a11yId="dashboard-loader" isLoading={true} size={50} /> : null;

    return <PageContainer>
        <PageHeader header="My Submissions"/>
        <DashboardTabs tabs={tabs} onSelectionChange={setSelectedDashboard} selectedIndex={selectedDashboard} />
        <PagePaper>
            {widgets.map((w, i) =>
                <DashboardWidgetElement widget={w}
                                        key={('title-' + w.title + '-' + i).replaceAll(/\s/g, '_')}
                                        triggerRefresh={onWidgetRefresh} triggerUpdate={updateOrClearResults}
                />)}
            {loader}
        </PagePaper>
    </PageContainer>
}

function DashboardTabs(props: { tabs: any[], selectedIndex: number, onSelectionChange: any }) {
    const styleClasses = dashboardPageStyle();
    const buttonStyle = navButton();
    const history = useHistory();
    // Change event for tabs (ignoring focus type events, so you can tab past the component)
    const onTabSelection = (evt: React.ChangeEvent, value: string) => {
        if (evt.type !== "focus") {
            props.onSelectionChange(value);// TODO not sure of value type (string or number)
        }
    };

    const selectedTab: string = '' + props.selectedIndex;
    return <Grid container wrap="wrap-reverse" justify="space-between" alignItems="center">
        <TabsStyled a11yId="dashboard-tabs"
                    className={styleClasses.pageHeaderTabs}
                    items={props.tabs}
                    value={'' + selectedTab}
                    onChangeHandler={onTabSelection}/>
        <ODLButton a11yId="new-submission" useWhiteLabel
                   className={clsx(styleClasses.newSubmission, buttonStyle.navButton)}
                   type={ButtonTypes.PRIMARY}
                   icon={<span className="icon icon-plus" />}
                   onClickHandler={() => history.push(APP_ROUTES.submissionsNew)}
                   text="New Submission"
        />
    </Grid>;
}