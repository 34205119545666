import {AspectState, FormState} from "./state";
import React from "react";
import {Breadcrumbs, createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {APP_ROUTES} from "../../api/app-routes";
import {BreadcrumbLink, BreadcrumbSeparator} from "../../components/BreadcrumbLink";
import {useSelector} from "react-redux";
import {aspectsHaveErrors, atLeastOneFieldVisible, isVisible, selectGroups} from "./form-utils";
import * as H from "history";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        breadcrumbs: {
            display: "flex",
            alignItems: "center",
            height: "100%",
            flexBasis: "auto"
        }
    })
);

export interface Group {
    name: string;
    aspects: AspectState[];
}

export function GroupBreadCrumbs(props: { baseUrl: string }) {
    const classes = useStyles();
    const groups: Group[] = useSelector(selectGroups);
    const newSubmission: boolean = useSelector((formState: FormState) => !formState.domainCode);
    const defaultPath = newSubmission ? "/0" : "/summary";
    const basePath = props.baseUrl.endsWith("/") ? props.baseUrl.substr(0, props.baseUrl.length - 1) : props.baseUrl;

    const isActive: (location: H.Location, href?: string) => boolean = (location, href) => {
        if (!href) {
            return false;
        }
        if (location.pathname === href) {
            return true;
        }
        return href.endsWith(defaultPath) &&
            location.pathname === href.substr(0, href.length - defaultPath.length);
    }

    return <Breadcrumbs aria-label="form-groups" className={classes.breadcrumbs} itemsBeforeCollapse={2}
                        separator={<BreadcrumbSeparator/>}>
        <BreadcrumbLink label="Pick a form" href={APP_ROUTES.submissionsNew}/>
        {groups
            .filter(g => g.aspects.some(a => isVisible(a) && atLeastOneFieldVisible(a)))
            .map((g, index) =>
                <BreadcrumbLink key={g.name + "-group"} label={g.name} href={`${basePath}/${index}`}
                                error={aspectsHaveErrors(g.aspects)}
                                activeFn={isActive}
                />
            )}
        <BreadcrumbLink label="Review & Submit" href={`${basePath}/summary`} activeFn={isActive}/>
    </Breadcrumbs>;
}