import React, {createContext, PropsWithChildren, useCallback, useContext, useState} from "react";
import {Backdrop, CircularProgress} from "@material-ui/core";
import {backdropStyle} from "../app/App.style";

export interface PortalLogo {
    link?: string;
    path?: string;
    text?: string;
}

export interface PortalConfig {
    documentUploadFileTypeWhitelist: string[];
    documentMaxUploadSizeMb: number;
    primaryColour?: string;
    headerBannerColour?: string;
    headerLogo?: PortalLogo;
    footerBannerColour?: string;
    footerLogo?: PortalLogo;
    authenticationEnabled: boolean;
}

export const defaultConfig: PortalConfig = {
    documentUploadFileTypeWhitelist: [] as string[],
    documentMaxUploadSizeMb: 100,
    authenticationEnabled: false as boolean
};

export const AppConfigContext: React.Context<PortalConfig> = React.createContext(defaultConfig);

export interface UserProfile {
    username: string;
    anonymousUser: boolean;
    surname?: string;
    givenNames?: string;
    fullName?: string;
}

export const defaultProfile: UserProfile = {
    username: "",
    anonymousUser: true
};

export const AppLoginContext: React.Context<{ profile: UserProfile, login: () => void }> =
    React.createContext({
        profile: defaultProfile, login: () => {}
    });

export interface Loading {
    isLoading: boolean;
    setLoading: (loading: boolean) => void
}

const defaultLoading: Loading = {
    isLoading: false,
    setLoading: (loading: boolean) => {
    }
};
const LoadingContext = createContext(defaultLoading);
export const useLoadingContext = () => useContext(LoadingContext);

export function LoadingProvider(props: PropsWithChildren<any>) {
    const classes = backdropStyle();
    const [loading, setLoading] = useState<boolean>(false);
    const updateLoadState = useCallback((loading: boolean) => {
        setLoading(loading);
    }, []);
    return <LoadingContext.Provider value={{isLoading: loading, setLoading: updateLoadState}}>
        {props.children}
        <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="primary"/>
        </Backdrop>
    </LoadingContext.Provider>
}