import {DomainFieldMetaParameter, InferenceRuleEffect, ReferenceDataValue, RuleValueReference} from "../../placeholder";
import {IMessage} from "../../components/SnackbarProvider";

export interface FormState {
    loading: boolean;
    typeId: string;
    typeName: string;
    domainCode?: string;
    status?: string
    changesPending: boolean;
    groups: GroupState[];
    aspects: AspectState[];
    rules: RulesState[];
    actions: WorkflowActionState[];
    validatedGroups: number;
    customErrors: IMessage[];
    inInitialStatus: boolean;
    draftEnabled: boolean;
}

export interface RulesState {
    id: string;
    condition: RuleCondition;
    conditionSections: string[];
    conditionFields: string[];
    effects: InferenceRuleEffect[];
}

export enum ConditionType {
    AND,
    OR,
    EQUALS,
    NOT_EQUALS,
    POPULATED,
    EMPTY,
    ALL,
    ANY,
    NONE,
    DATE_BETWEEN,
    DATE_MORE_THAN,
    DATE_WITHIN_LAST,
    DATE_IN_NEXT
}

export interface RuleBooleanCondition {
    type: typeof ConditionType.AND | ConditionType.OR;
    conditions: RuleCondition[];
}

export interface RuleComparisonCondition {
    type: typeof ConditionType.EQUALS | ConditionType.NOT_EQUALS | ConditionType.ALL |
        ConditionType.ANY | ConditionType.NONE | ConditionType.DATE_BETWEEN |
        ConditionType.DATE_MORE_THAN | ConditionType.DATE_WITHIN_LAST | ConditionType.DATE_IN_NEXT;
    lhs: RuleValueReference;
    rhs: RuleValueReference;
}

export interface RulePopulationCondition {
    type: typeof ConditionType.POPULATED | ConditionType.EMPTY;
    lhs: RuleValueReference;
}

export type RuleCondition = RuleBooleanCondition |
    RuleComparisonCondition |
    RulePopulationCondition;

export interface GroupState {
    name: string;
    aspectIds: string[];
}

export interface CommonAspectState {
    id: string;
    name: string;
    displayName: string;
    template: SectionTemplateState;
    activeRules: ActiveRulesState;
    customErrors: IMessage[];
}

export interface ReferenceDataTypeState {
    customReferenceData: boolean;
    options: ReferenceDataValue[];
    templateName?: string;
    sectionName?: string;
    codeTableKey?: string;
    parentCode?: string;
    parentCascadingField?: FieldStateReference;
}

export interface SectionAspectState extends CommonAspectState {
    type: typeof AspectTypes.SINGLE_SECTION;
    fields: FieldState[];
}

export interface MultiSectionAspectState extends CommonAspectState {
    type: typeof AspectTypes.MULTI_SECTION;
    allowAddRow: boolean;
    allowDeleteRow: boolean;
    fieldTemplates: FieldState[];
    rows: MultiSectionRowState[];
}

export interface MultiSectionRowState {
    key: string;
    isNew: boolean;
    removed: boolean;
    fields: FieldState[];
    activeRules: ActiveRulesState;
    customErrors: IMessage[];
    displayOrder: number;
}

export type AspectState = SectionAspectState | MultiSectionAspectState;

export enum AspectTypes {
    SINGLE_SECTION = "SINGLE_SECTION",
    MULTI_SECTION = "MULTI_SECTION"
}

export interface SectionTemplateState {
    id: string;
    name: string;
    editable: boolean;
    visible: boolean;
    triggersRules: boolean;
}

export interface FieldTemplateState {
    id: string;
    name: string;
    displayName: string;
    tooltip?: string;
    editable: boolean;
    visible: boolean;
    mandatory: boolean;
    triggersRules: boolean;
    parameters: DomainFieldMetaParameter[];
}

export interface ActiveRulesState {
    hide: string[],
    disable: string[],
    require: string[]
}

export interface CommonFieldState {
    template: FieldTemplateState;
    activeRules: ActiveRulesState;
    errors: ValidationErrors;
    customErrors: IMessage[];
}

export enum ErrorType {
    BELOW_MIN = "belowMin",
    OVER_MAX = "overMax",
    MALFORMED = "malformed",
    MISSING = "missing",
    CUSTOM_ERRORS = "custom_errors"
}

export type ValidationErrors = {
    [key in ErrorType]?: boolean;
}

export interface RegExValidation {
    regEx: string;
    failedMsg?: string;
}

export interface TextFieldState extends CommonFieldState {
    type: typeof FieldTypes.TEXT;
    value: string;
    maxLength?: number;
    regExValidation?: RegExValidation;
}

export interface NumberFieldState extends CommonFieldState {
    type: typeof FieldTypes.NUMBER;
    value?: number;
    min?: number;
    max?: number;
    regExValidation?: RegExValidation;
}

export interface CheckBoxFieldState extends CommonFieldState {
    type: typeof FieldTypes.CHECK_BOX;
    value: boolean;
}

export interface AddressFieldState extends CommonFieldState {
    type: typeof FieldTypes.ADDRESS;
    value?: string;
}

export interface DateFieldState extends CommonFieldState {
    type: typeof FieldTypes.DATE_TIME | FieldTypes.DATE;
    value?: { dateString: string; invalid: boolean; }
}

export interface FieldStateReference {
    sectionTemplateId: string;
    fieldTemplateId: string;
}

export interface SingleOptionFieldState extends CommonFieldState {
    type: typeof FieldTypes.DROPDOWN_SINGLE | FieldTypes.RADIO;
    refDataType: ReferenceDataTypeState;
    value: ReferenceDataValue | null;
}

export interface MultiOptionsFieldState extends CommonFieldState {
    type: typeof FieldTypes.DROPDOWN_MULTI | FieldTypes.CHECKBOX_GROUP;
    refDataType: ReferenceDataTypeState;
    value: ReferenceDataValue[];
}

export interface FileValue {
    name: string;
    path: string;
    externalUrl?: string;
    tempFileUploadId?: string;
    isNew: boolean;
}

export interface FileUploadFieldState extends CommonFieldState {
    type: typeof FieldTypes.FILE_UPLOAD;
    value: FileValue[];
    multiple: boolean;
    fileTypes: string | undefined;
    defaultFolder?: string;
}

export interface LabelFieldState extends CommonFieldState {
    type: typeof FieldTypes.LABEL;
}

export interface LinkFieldState extends CommonFieldState {
    type: typeof FieldTypes.LINK;
    value: LinkFieldValue[];
    originalValue?: string;
}

export interface LinkFieldValue {
    code: string,
    name: string
}

export type FieldState =
    TextFieldState
    | NumberFieldState
    | CheckBoxFieldState
    | SingleOptionFieldState
    | MultiOptionsFieldState
    | AddressFieldState
    | DateFieldState
    | FileUploadFieldState
    | LabelFieldState
    | LinkFieldState;

export enum FieldTypes {
    TEXT = "TEXT",
    NUMBER = "NUMBER",
    CHECK_BOX = "CHECK_BOX",
    DROPDOWN_SINGLE = "DROPDOWN_SINGLE",
    DROPDOWN_MULTI = "DROPDOWN_MULTI",
    ADDRESS = "ADDRESS",
    DATE_TIME = "DATE_TIME",
    DATE = "DATE",
    FILE_UPLOAD = "FILE_UPLOAD",
    LABEL = "LABEL",
    CHECKBOX_GROUP = "CHECKBOX_GROUP",
    RADIO = "RADIO",
    LINK = "LINK"
}

export interface WorkflowActionState {
    id: string;
    name: string;
    activeRules: ActiveRulesState;
}