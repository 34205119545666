import axios, {CancelTokenSource} from "axios";

export let client = axios.create({
    baseURL: window.location.origin
});

export function refreshClient() {
    client = axios.create({
        baseURL: window.location.origin
    });
}

export function getCancelToken(): CancelTokenSource {
    return axios.CancelToken.source();
}