import React, {useCallback, useContext, useEffect, useState} from "react";
import {PageHeader} from "../../components/PageHeader";
import {useDispatch, useSelector} from 'react-redux'
import {FormState} from "./state";
import {FormGroupContainer} from "./FormGroupContainer";
import {loadExistingForm, loadForm, loadNewFormValues, validateRoute} from "./reducer";
import {GroupBreadCrumbs} from "./GroupBreadCrumbs";
import {Prompt, Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {FormSummary} from "./FormSummary";
import {createStyles, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {UploadFilesProvider} from "../../components/FileUploadContext";
import {AppConfigContext, AppLoginContext, useLoadingContext, UserProfile} from "../../api/contexts";
import odlSizes from "odl-components/styles/odlSizes.json"
import {DomainValidation, UpdateDomain} from "../../placeholder";
import {PageContainer, PagePaper} from "../../components/PageComponents";
import {SubmitHandler, SummaryActions} from "./FormSubmission";
import {APP_ROUTES} from "../../api/app-routes";

export interface FormContainerRouteState {
    errors: DomainValidation
}

interface FormContainerProps {
    match: {
        params: {
            typeId: string;
            domainCode: string;
        }
    },
    state?: FormContainerRouteState
}

export const formContainerStyle = makeStyles((theme) => createStyles({
    breadCrumbContainer: {
        display: "flex",
        alignItems: "baseline",
        flexWrap: "wrap",
        marginTop: "-12px",
        "& > div": {
            marginLeft: "auto",
            "& > button": {
                marginTop: odlSizes.spacing.factor4.s3,
                width: "max-content"
            }
        },
        "& > nav": {
            marginTop: odlSizes.spacing.factor4.s3,
            marginRight: odlSizes.spacing.factor4.s4,
            minHeight: odlSizes.spacing.factor4.s14
        },
        "& > button": {
            marginLeft: "auto"
        }
    }
}));

export function FormContainer(props: FormContainerProps) {
    const style = formContainerStyle();
    const dispatch = useDispatch();
    const location = useLocation();
    const portalConfig = useContext(AppConfigContext);
    const loading = useSelector((state: FormState) => state.loading);
    const typeName = useSelector((state: FormState) => state.typeName);
    const domainCode = useSelector((state: FormState) => state.domainCode);
    const status = useSelector((state: FormState) => state.status);
    const loadingContext = useLoadingContext();
    const subHeading = domainCode ? status : "New Submission";
    const existingSubmission = !!domainCode;
    const {path, url, isExact} = useRouteMatch();
    const summaryPage = location.pathname.endsWith("/summary") || (path === APP_ROUTES.submissionsEditDomain && isExact);

    useEffect(() => {
        if (props.match.params.typeId) {
            dispatch(loadForm(props.match.params.typeId, portalConfig))
        } else if (props.match.params.domainCode) {
            dispatch(loadExistingForm(props.match.params.domainCode, portalConfig, props.state?.errors))
        }
    }, [props.match.params.typeId, props.match.params.domainCode, props.state?.errors, dispatch, portalConfig]);

    return <UploadFilesProvider>
        <PageContainer>
            <PageHeader header={typeName} subHeader={subHeading}/>
            {!loading &&
                <PagePaper>
                    <Grid className={style.breadCrumbContainer}>
                        <GroupBreadCrumbs baseUrl={url}/>
                        {summaryPage && !loadingContext.isLoading && <SummaryActions/>}
                    </Grid>
                    <NavigationPrompt/>
                    <Switch>
                        <Route path={`${path}/summary`}>
                               <RouteSummary url={url}/>
                        </Route>
                        <Route path={`${path}/:groupIndex`}
                               component={(routeProps: any) => {
                                   return <FormStep url={url} summary={false} groupIndex={
                                       routeProps.match.params.groupIndex ? Number(routeProps.match.params.groupIndex) : 0
                                   }/>;
                               }}
                        />
                        <Route path={path}>
                            <FormStep url={url} summary={existingSubmission} groupIndex={existingSubmission ? undefined : 0}/>
                        </Route>
                    </Switch>
                </PagePaper>
            }
        </PageContainer>
    </UploadFilesProvider>;
}

function RouteSummary(props: {url: string}) {
    const {url} = props;
    const dispatch = useDispatch();
    const portalConfig = useContext(AppConfigContext);
    const userProfile: UserProfile = useContext(AppLoginContext).profile;
    const draftEnabled: boolean = useSelector((formState: FormState) => formState.draftEnabled);
    const history = useHistory();
    const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);

    useEffect(() => {
        if (draftEnabled && !userProfile.anonymousUser) {
            setSubmitInProgress(true);
        }
    }, [userProfile.anonymousUser, setSubmitInProgress, draftEnabled]);

    const submitCompleted = () => setSubmitInProgress(false);

    const successCallback = useCallback((updateDomain: UpdateDomain) => {
        loadNewFormValues(dispatch, updateDomain, portalConfig);
        history.push(APP_ROUTES.submissionsEdit + "/" + updateDomain.data?.code);
    }, [dispatch, history, portalConfig]);

    const failedCallback = useCallback(() => {
        return <FormStep url={url} summary={true}/>;
    }, [url]);

    if (draftEnabled && submitInProgress && !userProfile.anonymousUser) {
        return <SubmitHandler submitInProgress={submitInProgress} submitCompletedCallback={submitCompleted}
                              successCallback={successCallback} failedCallback={failedCallback}/>;
    } else {
        return <FormStep url={url} summary={true}/>;
    }
}

function NavigationPrompt() {
    const routerValidationMessage: string = "Changes you made may not be saved. Do you wish to continue?";
    const changesPending = useSelector((formState: FormState) => formState.changesPending);
    return <Prompt
        message={(location, action) => {
            const newLocation: string = location.pathname;
            if (changesPending &&
                (newLocation === APP_ROUTES.submissionsNew ||
                newLocation === APP_ROUTES.submissionsDashboard)) {
                return routerValidationMessage;
            }
            return true;
        }}
    />
}

function FormStep(props: { groupIndex?: number, summary: boolean, url: string }) {

    const {summary, url, groupIndex} = props;
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const redirectToGroup = (groupInError: number) => history.push(url + "/" + groupInError);
        dispatch(validateRoute(redirectToGroup, groupIndex));
    }, [url, groupIndex, dispatch, history]);

    return summary ?
        <FormSummary baseUrl={url}/> :
        <FormGroupContainer groupIndex={groupIndex ?? 0} baseUrl={url}/>;
}