import React, {useContext, useEffect, useState} from 'react';
import {Breadcrumbs, Grid, Typography} from "@material-ui/core";
import {getDomainCategories} from "../../api/ui-services";
import {Link, useHistory} from "react-router-dom";
import {APP_ROUTES} from "../../api/app-routes";
import {IconButton, ODL_ICONS} from "odl-components";
import {categoryStyle, formSelectorStyle, typeStyle} from "./FormSelector.style";
import "./FormSelector.css";
import {BreadcrumbLink, BreadcrumbSeparator} from "../../components/BreadcrumbLink";
import {PageHeader} from "../../components/PageHeader";
import {convertDomainCategory} from "../../util/converters";
import {FormCategory, FormType} from "./model";
import {AppLoginContext, UserProfile} from "../../api/contexts";
import {PageContainer, PagePaper} from "../../components/PageComponents";
import {SearchField} from "../common/SearchField";


export function FormSelector() {
    const profile: UserProfile = useContext(AppLoginContext).profile;
    const classes = formSelectorStyle();
    const [categories, setCategories]: [FormCategory[], React.Dispatch<React.SetStateAction<FormCategory[]>>] = useState<FormCategory[]>([]);
    const [filteredCategories, setFilteredCategories]: [FormCategory[], React.Dispatch<React.SetStateAction<FormCategory[]>>] = useState<FormCategory[]>([]);
    const [filterValue, setFilterValue]: [string, React.Dispatch<React.SetStateAction<string>>] = useState("");

    useEffect(() => {
        getDomainCategories().then(categories => setCategories(categories.map(c => convertDomainCategory(c))));
    }, [profile.username]); //Will trigger a refresh on login

    useEffect(() => {
        setFilteredCategories(filterForms(filterValue, categories));
    }, [filterValue, categories]);

    return <PageContainer>
        <PageHeader header="New Submission"/>
        <PagePaper className={classes.pageBody}>
            <Grid container alignItems="center">
                <Breadcrumbs aria-label="breadcrumb" separator={<BreadcrumbSeparator/>} className={classes.breadcrumbs}>
                    <BreadcrumbLink label="Pick a form" href={APP_ROUTES.submissionsNew}/>
                    <BreadcrumbLink label="Fill the form"/>
                </Breadcrumbs>
                <SearchField filterChange={value => {setFilterValue(value)}} placeholder="Search submission forms" className={classes.search}/>
            </Grid>
            <div id="category-container">
            {filteredCategories.map(c => <Category category={c} key={c.id}/>)}
            </div>
        </PagePaper>
    </PageContainer>
}

function filterForms(filterValue: string, categories: FormCategory[]): FormCategory[] {
    if (filterValue) {
        let matchFn = (value: string): boolean => !!value && value.toLowerCase().includes(filterValue.toLowerCase());
        let filteredValues: FormCategory[] = [];
        for(let category of categories) {
            if (matchFn(category.name)) {
                filteredValues.push(category);
            } else {
                let types = category.types.filter(t => matchFn(t.name) || (t.description && matchFn(t.description)));
                if (types.length > 0) {
                    filteredValues.push({
                        ...category,
                        types: types
                    });
                }
            }
        }
        return filteredValues;
    }
    return categories;
}

function Category(props: {category: FormCategory}) {
    const classes = categoryStyle();
    return <div id={props.category.id + "_category"} className={classes.categoryContainer}>
        <Typography variant="h6" className={classes.category}>{props.category.name}</Typography>
        {props.category.types?.map(t => <Type type={t} key={t.id}/>)}
    </div>;
}

function Type(props: {type: FormType}) {
    const classes = typeStyle();
    const history = useHistory();
    const route = `${APP_ROUTES.submissionsNew}/${props.type.id}`;

    return <Link to={route} className={classes.contentNameLink}>
        <Grid id={props.type.id + "type"} container item className={classes.container}>
            <Grid container item direction="column" className={classes.content}>
                <Grid container item alignItems="center" className={classes.contentNameContainer}>
                    <Typography variant="h6" className={classes.contentName}>{props.type.name}</Typography>
                </Grid>
                <Grid item className={classes.contentDescriptionContainer}>
                    {props.type.description && <Typography className={classes.contentDescription}>{props.type.description}</Typography>}
                </Grid>
            </Grid>
            <Grid className={classes.action}>
                <IconButton a11yId={props.type.id + "_type_button"}
                            icon={<span className={"icon " + ODL_ICONS.ARROW_RIGHT} />}
                            text={"Create " + props.type.name}
                            onClickHandler={() => history.replace(route)}>
                </IconButton>
            </Grid>
        </Grid>
    </Link>
}