import {client} from "./axios-client";
import {AxiosResponse} from "axios";
import {FileUrlResponse} from "../components/FileUploadContext";

export const PATH_SEPARATOR: string = "/";

const PUBLIC_API_FILES = "/api/v1/files";
const GET_UPLOAD_URL_BASE_PATH = "/integration/document/uploadUrl";

export async function uploadFile(domainCode: string | undefined, domainTypeId: string, content: File, fileInfo: FileUrlResponse,
                                 onUploadProgress?: (progressEvent: ProgressEvent) => void): Promise<AxiosResponse | void> {
    if (fileInfo.uploadMethod === "POST") {
        return fileUploadPost(domainTypeId, fileInfo.documentPath, fileInfo.uploadUrl, content, onUploadProgress);
    } else if (fileInfo.uploadMethod === "PUT") {
        return fileUploadPut(content, fileInfo.uploadUrl, onUploadProgress);
    }
}

export function openFile(filePath: string) {
    const params = {filePath};
    const url = client.getUri({
            url: PUBLIC_API_FILES,
            params: params
    });
    window.open(url, "_blank");
}

export async function getFileUrl(domainCode: string | undefined, filePath: string, domainTypeId: string): Promise<AxiosResponse> {
    let config;
    if (domainCode) {
        config = { params: {domainCode, filePath} };
    } else {
        config = { params: {domainTypeId, filePath} };
    }
    return client.get(GET_UPLOAD_URL_BASE_PATH, config);
}

export async function fileUploadPost(domainTypeId: string, filePath: string, url: string, content: File,
                                     onUploadProgress?: (progressEvent: ProgressEvent) => void): Promise<AxiosResponse> {
    let formData = new FormData();
    formData.append("content", content, content.name);
    return client.post(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "domainTypeId": domainTypeId
        },
        onUploadProgress
    });
}

export async function fileUploadPut(content: File, url: string,
                                    onUploadProgress?: (progressEvent: ProgressEvent) => void): Promise<AxiosResponse> {
    return client.put(url, content, {
        headers: {'Content-Type': 'application/octet-stream'},
        onUploadProgress
    });
}