import {PageContainer, PagePaper} from "../../components/PageComponents";
import {PageHeader} from "../../components/PageHeader";
import React, {useEffect, useState} from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import {useParams} from "react-router-dom";
import {exportSavedSearch, getSearchInfo} from "../../api/search-service";
import {performSavedSearch} from "../../api/dashboard-service";
import {ColumnTemplate, formatSearchResults, SavedSearch, SearchResultState} from "./Search";
import {searchBoxStyles, searchTableStyles} from "./SearchPage.styles";
import {OrderEnum, SearchSortInfo} from "../../placeholder";
import {SearchField} from "../common/SearchField";
import ButtonTypes from "odl-components/components/Button/Button.types";
import {ODLButton} from "../../components/ODLWrapper";
import {Grid} from "@material-ui/core";

export function SearchPage() {

    const [search, setSearch] = useState<SavedSearch>({
        id: "",
        name: "",
        description: "",
        columns: []
    });
    const [searchTerms, setSearchTerms] = useState<string>("");
    const {typeId}: { typeId: string } = useParams();

    const classes = searchBoxStyles();

    useEffect(() => {
        getSearchInfo(typeId).then(s => setSearch(s));
    }, [typeId, setSearch]);

    return <PageContainer>
        <PageHeader header={search.name}/>
        <div>{search.description}</div>
        <Grid container wrap="wrap-reverse" justify="space-between" alignItems="center">
            <SearchField filterChange={value => {setSearchTerms(value)}} placeholder="Enter Search Text" className={classes.searchBox}/>
            <ODLButton text="export" type={ButtonTypes.TEXT} onClickHandler={() => {
                exportSavedSearch(search, searchTerms);
            }}/>
        </Grid>
        <PagePaper>
            {search.columns.length > 0 && <SearchTable savedSearch={search} searchTerms={searchTerms}/>}
        </PagePaper>
    </PageContainer>
}

type Order = 'asc' | 'desc';

interface SearchTableProps {
    classes: ReturnType<typeof searchTableStyles>;
    onRequestSort: (event: React.MouseEvent<HTMLElement>, property: string) => void;
    order: Order;
    orderBy: string | undefined;
    rowCount: number;
    columns: ColumnTemplate[];
}

function SearchTableHeader(props: SearchTableProps) {
    const {order, orderBy, onRequestSort, columns} = props;
    const createSortHandler = (property: string) => (event: React.MouseEvent<HTMLElement>) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                {columns.map((column) => (
                    <TableCell
                        key={column.name}
                        align='left'
                        sortDirection={orderBy === column.name ? order : false}>
                        <TableSortLabel
                            active={orderBy === column.name}
                            direction={orderBy === column.name ? order : 'asc'}
                            onClick={createSortHandler(column.name)}>
                            {column.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function SearchTable(props: { savedSearch: SavedSearch, searchTerms: string }) {

    const {savedSearch, searchTerms} = props;
    const classes = searchTableStyles();

    const [order, setOrder] = React.useState<Order>(savedSearch.sortInfo?.order === OrderEnum.ASC ? "asc" : "desc");
    const [orderBy, setOrderBy] = React.useState<string | undefined>();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);

    const [terms, setTerms] = React.useState(searchTerms);

    const [results, setResults] = React.useState<SearchResultState>({count: 0, searchResultRows: []});

    useEffect(() => {
        if (terms !== searchTerms) {
            setTerms(searchTerms);
            setPage(0);
            return;
        }
        const sortInfo = getSortInfo(orderBy, order);
        performSavedSearch(savedSearch.id, page * rowsPerPage, rowsPerPage, searchTerms, sortInfo).then(
            r => setResults(formatSearchResults(r.data, savedSearch.columns))
        );
    }, [savedSearch.id, savedSearch.columns, page, setPage, rowsPerPage, orderBy, order, searchTerms, terms, setTerms]);

    const handleRequestSort = (event: React.MouseEvent<HTMLElement>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table
                        id={"table-" + savedSearch.id}
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size='medium'
                        aria-label="search-table"
                    >
                        <SearchTableHeader
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={results.count}
                            columns={savedSearch.columns}
                        />
                        <TableBody>
                            {results.searchResultRows.map((row) =>
                                <TableRow tabIndex={-1} key={row.id}>
                                    {row.columns.map(c =>
                                        <TableCell key={`cell-${row.id}-${c.key}`} align="left">{c.value}</TableCell>
                                    )}
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
                    component="div"
                    count={results.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

function getSortInfo(orderBy: string | undefined, order: Order): SearchSortInfo | undefined {
    let sortInfo: SearchSortInfo | undefined;
    if (orderBy) {
        sortInfo = {
            order: order === "asc" ? OrderEnum.ASC : OrderEnum.DESC,
            sortField: orderBy
        }
    }
    return sortInfo;
}
