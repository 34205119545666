import {
    AddressFieldState,
    AspectState,
    CheckBoxFieldState,
    DateFieldState,
    FieldState,
    FieldTypes,
    FileUploadFieldState,
    FileValue,
    LabelFieldState,
    LinkFieldState,
    LinkFieldValue,
    MultiOptionsFieldState,
    MultiSectionRowState,
    NumberFieldState,
    ReferenceDataTypeState,
    SingleOptionFieldState,
    TextFieldState
} from "./state";
import {fieldStyle, pillStyle} from "./FieldComponents.style";
import React, {useEffect, useRef, useState} from "react";
import {ODLButton, ODLRadio} from "../../components/ODLWrapper";
import {getErrorText, hasError, isEditable, isMandatory, retrieveRefDataOptions} from "./form-utils";
import {blankIfUndefined, fallbackIfUndefined, undefinedIfNaN} from "../../util/notUndefined";
import {INVALID_DATE_MSG, serverDateFormat, serverDateTimeFormat} from "../../api/constants";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import {KeyboardDatePicker, KeyboardDatePickerProps, KeyboardDateTimePicker} from "@material-ui/pickers";
import {
    Checkbox,
    Chip,
    debounce,
    FormControl,
    FormControlLabel,
    FormControlLabelProps,
    FormGroup,
    FormHelperText,
    Grid,
    InputLabel,
    LinearProgress,
    TextField,
    TextFieldProps
} from "@material-ui/core";
import {PendingFile, useUploadingFiles} from "../../components/FileUploadContext";
import clsx from "clsx";
import {openFile} from "../../api/document-service";
import ClearIcon from "@material-ui/icons/Clear";
import {useDispatch} from "react-redux";
import {formSlice, performFieldUpdate} from "./reducer";
import sanitizeHtml from "sanitize-html";
import {CustomLocationData, ReferenceDataValue} from "../../placeholder";
import {KeyboardDateTimePickerProps} from "@material-ui/pickers/DateTimePicker/DateTimePicker";
import {ButtonTypes, FontIcon, Loader, ODL_ICONS} from "odl-components";
import {Autocomplete, AutocompleteProps} from "@material-ui/lab";
import {getCustomLocation, getPlaceSuggestions} from "../../api/address-service";

export interface FormFieldProps {
    field: FieldState;
    aspect: AspectState;
    row?: MultiSectionRowState;
}

export function FormField(props: FormFieldProps) {
    const {aspect, field, row} = props;
    const dispatch = useDispatch();
    const disabled = !isEditable(aspect) || !isEditable(field) || (!!row && row.activeRules.disable.length > 0);
    const error = !disabled && hasError(field);
    const fieldProps = {
        onChange: (value: any) => dispatch(performFieldUpdate({...props, value})),
        id: field.template.name,
        name: field.template.name,
        label: field.template.displayName,
        disabled,
        required: isMandatory(field),
        ...(error && {error, helperText: getErrorText(field)})
    };
    switch (field.type) {
        case FieldTypes.TEXT:
            return <TextFormField field={field} {...fieldProps} />
        case FieldTypes.NUMBER:
            return <NumberFormField field={field} {...fieldProps} />
        case FieldTypes.CHECK_BOX:
            return <CheckboxFormField field={field} {...fieldProps}/>
        case FieldTypes.DROPDOWN_SINGLE:
            return <DropdownSingleFormField {...props} field={field} {...fieldProps} />
        case FieldTypes.DROPDOWN_MULTI:
            return <DropdownMultiFormField {...props} field={field} {...fieldProps} />
        case FieldTypes.ADDRESS:
            return <AddressFormField field={field} {...fieldProps} aspectId={aspect.id}/>
        case FieldTypes.DATE:
        case FieldTypes.DATE_TIME:
            return <DateTimeFormField field={field} {...fieldProps} />
        case FieldTypes.FILE_UPLOAD:
            return <FileUploadFormField field={field} {...fieldProps} dispatchProps={props}/>
        case FieldTypes.LABEL:
            return <LabelFormField field={field} {...fieldProps} />
        case FieldTypes.CHECKBOX_GROUP:
            return <CheckboxGroupFormField {...props} field={field} {...fieldProps}/>
        case FieldTypes.RADIO:
            return <RadioFormField {...props} field={field} {...fieldProps}/>
        case FieldTypes.LINK:
            return <LinkFormField {...props} field={field} {...fieldProps}/>
    }
}

interface FieldProps<State, ValType> {
    field: State;
    onChange: (value: ValType) => void;
    id: string;
    name: string;
    label: string;
    disabled: boolean;
    required: boolean;
    error?: boolean;
    helperText?: string;
}

export function LinkFormField(props: FieldProps<LinkFieldState, LinkFieldValue>) {
    const {field, helperText, id, label, error, required} = props;
    const classes = fieldStyle();
    return field.value.length > 0 ?
        <FormControl className={classes.common} required={required} error={error}>
            <InputLabel id={id + "-label"}>{label}</InputLabel>
            {field.value.map(v => <LinkPill {...v} key={v.code} className={classes.pill}/>)}
            {helperText && <FormHelperText id={id + "-helper-text"}>{helperText}</FormHelperText>}
        </FormControl> : null;
}

function LinkPill(props: { code: string, name: string, className: string }) {
    const {code, name} = props;
    const classes = pillStyle();
    const rootClass = clsx(classes.root, props.className);
    return <Grid container direction="column" className={rootClass}>
        <Grid container alignItems="center" className={classes.container}>
            <span className={classes.fileName}>{name}</span>
            <span>{code}</span>
        </Grid>
    </Grid>
}

export function TextFormField(props: FieldProps<TextFieldState, string>) {
    const {field, onChange, ...common} = props;

    const [value, setValue] = useState<string>(field.value);
    //Update state when field changed externally
    useEffect(() => {
        setValue(field.value);
    }, [field.value]);

    const controlProps: TextFieldProps = {
        ...common,
        className: fieldStyle().common,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value),
        value: value,
        onBlur: () => onChange(value)
    };
    return <TextField {...controlProps} multiline/>;
}

export function NumberFormField(props: FieldProps<NumberFieldState, number | undefined>) {
    const {field, onChange, ...common} = props;
    const [input, setInput] = useState<string>(blankIfUndefined(field.value?.toString()));
    // keeps the user input while typing (potentially not a valid number)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setInput(event.target.value);
    // input is converted to number here
    const convertInput = () => {
        const n: number | undefined = undefinedIfNaN(input);
        if (field.value !== n) {
            onChange(n);
            setInput(blankIfUndefined(n?.toString()));
        }
    };
    const controlProps: TextFieldProps = {
        ...common,
        className: fieldStyle().common,
        onChange: handleChange,
        onBlur: convertInput,
        type: "number",
        value: input,
        inputProps: {
            min: field.min,
            max: field.max
        }
    };
    return <TextField {...controlProps}/>;
}

export function CheckboxFormField(props: FieldProps<CheckBoxFieldState, boolean>) {
    const {field, onChange, helperText, ...common} = props;
    const label = !common.required ? common.label :
        <span>{common.label} <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">*</span></span>;
    const controlProps: FormControlLabelProps = {
        label,
        onChange: (event: React.ChangeEvent<{}>, checked: boolean) => onChange(checked),
        checked: field.value,
        control: <Checkbox color="primary"/>
    }
    return (
        <FormControl {...common} className={fieldStyle().common}>
            <FormGroup className={common.error ? "Mui-error" : ""}>
                <FormControlLabel {...controlProps}/>
            </FormGroup>
            {helperText && <FormHelperText id={common.id + "-helper-text"}>{helperText}</FormHelperText>}
        </FormControl>
    );
}

export function DropdownSingleFormField(props: FormFieldProps & FieldProps<SingleOptionFieldState, ReferenceDataValue | null>) {
    const {aspect, row, field, onChange, ...common} = props;
    const styles = fieldStyle();
    const renderInput = (params: any) => <TextField {...params} {...common} InputProps={{
        ref: params.InputProps.ref,
        startAdornment: params.InputProps.startAdornment,
        endAdornment: params.InputProps.endAdornment
    }}/>;
    return <>
        {!field.refDataType.customReferenceData && <RefDataUpdate {...{aspect, row, field, ...field.refDataType}}/>}
        <Autocomplete
            className={styles.common}
            disabled={common.disabled}
            disableClearable={common.required}
            handleHomeEndKeys={false}
            freeSolo={false}
            options={field.refDataType.options}
            getOptionSelected={(option, value) => option.name === value.name}
            onChange={((event, value: ReferenceDataValue | null) => onChange(value))}
            getOptionLabel={o => o.name!}
            value={field.value}
            renderInput={renderInput}/>
    </>
}

export function DropdownMultiFormField(props: FormFieldProps & FieldProps<MultiOptionsFieldState, ReferenceDataValue[]>) {
    const {aspect, row, field, onChange, ...common} = props;
    const controlProps: TextFieldProps = {...common};
    const autoCompleteProps: AutocompleteProps<ReferenceDataValue, true, boolean, false> = {
        className: fieldStyle().common,
        disabled: common.disabled,
        multiple: true,
        disableClearable: common.required,
        disableCloseOnSelect: true,
        handleHomeEndKeys: false,
        options: field.refDataType.options,
        getOptionSelected: (option, value) => option.name === value.name,
        value: field.value,
        onChange: (event, value) => onChange(value),
        getOptionLabel: o => o.name!,
        renderInput: params => <TextField {...params} {...controlProps} InputProps={{
            ref: params.InputProps.ref,
            startAdornment: params.InputProps.startAdornment,
            endAdornment: params.InputProps.endAdornment
        }}/>,
        renderOption: (option, {selected}) => <><Checkbox checked={selected} color="primary"/>{option.name}</>,
        renderTags: (options, getTagProps) => options.map((option, index) =>
            <Chip color="secondary" label={option.name}
                  deleteIcon={<FontIcon name={ODL_ICONS.CLOSE}/>} {...getTagProps({index})}/>)
    };
    return <>
        {!field.refDataType.customReferenceData && <RefDataUpdate {...{aspect, row, field, ...field.refDataType}}/>}
        <Autocomplete {...autoCompleteProps}/>
    </>;
}

export function AddressFormField(props: FieldProps<AddressFieldState, CustomLocationData> & { aspectId: string }) {
    const {field, onChange, ...common} = props;
    const [searching, setSearching] = useState(false);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const options = useRef<{ id: string, label: string }[]>([]);
    const input = useRef("");
    const msg = useRef("");
    // keeps the user input while typing
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        const oldValue = input.current;
        input.current = newValue;
        if (oldValue !== newValue) {
            options.current = [];
            setSearching(true);
            msg.current = "";
            if (newValue.length >= 3) {
                getPlaceSuggestions(newValue, props.aspectId)
                    .then(r => options.current = r.map(a => {
                        const parts = a.split(";");
                        return {id: parts[0], label: parts[parts.length === 1 ? 0 : 1]}
                    }), e => msg.current = e.response.data)
                    .finally(() => setSearching(false));
            } else {
                setSearching(false);
            }
        }
    };
    const splitAddress = (event: React.ChangeEvent<{}>, value: { id: string, label: string }) => {
        input.current = value.id;
        setSearching(true);
        getCustomLocation(value.id, props.aspectId)
            .then(r => onChange(r), e => msg.current = e.response.data)
            .finally(() => setSearching(false));
    };
    const error = !!msg.current;
    const controlProps: TextFieldProps = {
        onChange: debounce(handleChange, 500),
        placeholder: "Search for an address here",
        ...(error && {error, helperText: msg.current})
    };
    const autoCompleteProps: AutocompleteProps<{ id: string, label: string }, false, true, false> = {
        className: fieldStyle().common,
        disabled: common.disabled,
        blurOnSelect: true,
        clearOnBlur: false,
        disableClearable: true,
        forcePopupIcon: false,
        handleHomeEndKeys: false,
        includeInputInList: true,
        selectOnFocus: false,
        value: null as unknown as { id: string, label: string },
        loading: searching,
        loadingText: "Searching...",
        options: options.current,
        getOptionLabel: o => o.label,
        noOptionsText: "No suggestions",
        open: canShowPopup && !searching && input.current.length >= 3,
        filterOptions: o => o,
        onChange: splitAddress,
        renderInput: params => <TextField {...params} {...controlProps} InputProps={{
            ref: params.InputProps.ref,
            endAdornment: searching && <Loader a11yId="address-spinner"/>,
            onFocus: () => setCanShowPopup(true),
            onBlur: () => setCanShowPopup(false)
        }}/>
    };
    return <Autocomplete {...autoCompleteProps}/>;
}

export function DateTimeFormField(props: FieldProps<DateFieldState, { dateString: string; invalid: boolean; } | undefined>) {
    const {field, onChange, ...common} = props;
    const classes = fieldStyle();
    const handleChange = (date: MaterialUiPickersDate, value: string | undefined | null) => {
        if (!value) {
            onChange(undefined);
        } else {
            onChange({dateString: value, invalid: "Invalid Date" === date?.toString()});
        }
    };

    const dateComponentProps: KeyboardDateTimePickerProps & KeyboardDatePickerProps = {
        ...common,
        className: clsx(classes.common, classes.dateField),
        onChange: handleChange,
        format: field.type === FieldTypes.DATE_TIME ? serverDateTimeFormat : serverDateFormat,
        invalidDateMessage: INVALID_DATE_MSG,
        value: null,
        inputValue: field.value?.dateString
    }

    return field.type === FieldTypes.DATE_TIME ?
        <KeyboardDateTimePicker {...dateComponentProps}/> :
        <KeyboardDatePicker {...dateComponentProps}/>;
}

interface FileUploadFormFieldProps extends FieldProps<FileUploadFieldState, Partial<FileValue[]>> {
    dispatchProps: FormFieldProps;
}

export function FileUploadFormField(props: FileUploadFormFieldProps) {
    const {field, dispatchProps} = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const classes = fieldStyle();
    const pendingFiles = useUploadingFiles();
    const uploadInProgress: boolean = field.value.some(f => f.tempFileUploadId);
    const disabled: boolean = props.disabled || uploadInProgress;
    const maxFilesReached: boolean = field.value.length === 1 && !field.multiple;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let fileList: FileList | null = event.target.files;
        if (fileList != null && fileList.length > 0) {
            let uploadingFiles = Array.from(fileList);
            uploadingFiles = uploadingFiles.filter(uf =>
                !field.value.find(f => f.name.toLowerCase() === uf.name.toLowerCase())
            );
            pendingFiles.addFiles(uploadingFiles, dispatchProps);
        }
        event.target.value = "";
    };

    const onDelete = (file: FileValue) => {
        props.onChange(field.value.filter(f => f !== file));
    }

    const handleButtonClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    }

    return <FormControl className={classes.common} required={props.required} error={props.error}>
        <InputLabel id={props.id + "-label"}>{props.label}</InputLabel>
        <input id={props.id} type="file" multiple={field.multiple} className={classes.fileUploadInput}
               disabled={disabled || maxFilesReached} onChange={handleChange} ref={inputRef} accept={field.fileTypes}/>
        {!uploadInProgress &&
        <ODLButton onClickHandler={handleButtonClick} text="Upload File" type={ButtonTypes.PRIMARY}
                   disabled={disabled || maxFilesReached} className={classes.button}
                   icon={<span className="icon icon-upload"/>}/>
        }
        {field.value.map(f => <FilePill key={f.path} file={f} className={classes.pill} disabled={disabled}
                                        pendingFile={f.tempFileUploadId ? pendingFiles.files[f.tempFileUploadId] : undefined}
                                        onDelete={() => onDelete(f)}/>)}
        {props.helperText && <FormHelperText id={props.id + "-helper-text"}>{props.helperText}</FormHelperText>}
    </FormControl>
}

function FilePill(props: {
    file: FileValue, className?: string, pendingFile?:
        PendingFile, disabled: boolean, onDelete?: () => void
}) {
    const {file, className, pendingFile, onDelete, disabled} = props;
    const classes = pillStyle();
    const rootClass = clsx(classes.root, className);
    const fileNameClass = clsx({
        [classes.fileName]: true,
        [classes.downloadable]: !pendingFile && !file.isNew
    });
    const iconClasses = clsx("icon icon-file-outline");
    const canDelete = !pendingFile && !disabled;
    const handleOpenFile = () => {
        if (!props.file.isNew) {
            if (props.file.externalUrl) {
                window.open(file.externalUrl, "_blank")
            } else {
                openFile(file.path);
            }
        }
    }
    return <Grid container direction="column" className={rootClass}>
        <Grid container alignItems="center" wrap="nowrap" className={classes.container}>
            <span className={iconClasses}/>
            <span className={fileNameClass} onClick={handleOpenFile}>{file.name}</span>
            {canDelete && <ClearIcon className={classes.clearIcon} height="24px" width="24px" onClick={onDelete}/>}
        </Grid>
        {pendingFile && pendingFile.progress !== 100 && <LinearProgress color="primary" value={pendingFile.progress}/>}
    </Grid>
}

export function RadioFormField(props: FormFieldProps & FieldProps<SingleOptionFieldState, ReferenceDataValue | undefined>) {
    const {aspect, row, field, onChange, helperText, ...common} = props;
    const options: ReferenceDataValue[] = field.refDataType.options;
    const isChecked = (option: ReferenceDataValue): boolean => field.value?.name === option.name;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value) {
            onChange(options.find(option => option.name === event.target.value));
        }
    }

    const radioButtons = options?.map((option, index) =>
        <ODLRadio key={option.name + "_" + index}
                  label={option.name}
                  value={option.name}
                  disabled={common.disabled}
                  require={common.required}
                  checked={field.value ? isChecked(option) : false}
                  onChangeHandler={handleChange}/>);

    return <FormControl {...common} className={fieldStyle().common}>
        {!field.refDataType.customReferenceData && <RefDataUpdate {...{aspect, row, field, ...field.refDataType}}/>}
        <InputLabel id={common.id + "-label"}>{common.label}</InputLabel>
        <FormGroup className={common.error ? "Mui-error" : ""}>{radioButtons}</FormGroup>
        {helperText && <FormHelperText id={common.id + "-helper-text"}>{helperText}</FormHelperText>}
    </FormControl>
}

export function LabelFormField(props: FieldProps<LabelFieldState, string>) {
    const value = fallbackIfUndefined(props.field.template.tooltip, props.label);
    const sanitizeOptions: sanitizeHtml.IOptions = {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"])
    }

    return (
        <InputLabel id={props.id} required={props.required}>
            <span dangerouslySetInnerHTML={{__html: sanitizeHtml(value, sanitizeOptions)}}/>
        </InputLabel>
    );
}

export function CheckboxGroupFormField(props: FormFieldProps & FieldProps<MultiOptionsFieldState, ReferenceDataValue[]>) {
    const {aspect, row, field, onChange, helperText, ...common} = props;
    const options: ReferenceDataValue[] = field.refDataType.options;
    const handleChange = (checked: boolean, option: ReferenceDataValue) => {
        if (checked) {
            onChange([...field.value, option]);
        } else {
            onChange(field.value.filter(value => value.name !== option.name));
        }
    };
    const isChecked = (option: ReferenceDataValue): boolean => field.value.some(value => value.name === option.name);
    const checkboxes = options?.map(option => {
        const controlProps: FormControlLabelProps = {
            id: common.id + "-" + option.name,
            name: common.name + "-" + option.name,
            label: option.name,
            onChange: (event: React.ChangeEvent<{}>, checked: boolean) => handleChange(checked, option),
            checked: isChecked(option),
            control: <Checkbox color="primary"/>
        }
        return <FormControlLabel key={option.name} {...controlProps}/>;
    });
    return (
        <FormControl {...common} className={fieldStyle().common}>
            {!field.refDataType.customReferenceData && <RefDataUpdate {...{aspect, row, field, ...field.refDataType}}/>}
            <InputLabel id={common.id + "-label"}>{common.label}</InputLabel>
            <FormGroup className={common.error ? "Mui-error" : ""}>{checkboxes}</FormGroup>
            {helperText && <FormHelperText id={common.id + "-helper-text"}>{helperText}</FormHelperText>}
        </FormControl>
    );
}

function RefDataUpdate(props: FormFieldProps & ReferenceDataTypeState) {
    const {aspect, row, field, options, sectionName, templateName, codeTableKey, parentCode} = props;
    const aspectName = aspect.name;
    const rowKey = row?.key;
    const fieldName = field.template.name;
    const shouldFetch = !options.length;
    const dispatch = useDispatch();
    useEffect(() => {
        if (shouldFetch) {
            retrieveRefDataOptions(fieldName, sectionName, templateName, codeTableKey, parentCode)
                .then(value => dispatch(formSlice.actions.updateFieldReferenceData({
                    aspectName,
                    rowKey,
                    fieldName,
                    value
                })));
        }
    }, [shouldFetch, parentCode, fieldName, sectionName, templateName, codeTableKey, aspectName, rowKey, dispatch]);
    return null;
}
