import {makeStyles} from "@material-ui/core/styles";
import {createStyles} from "@material-ui/core";
import odlSizes from "odl-components/styles/odlSizes.json"
import odlPalette from "odl-components/styles/odlProPalette"

export const formSelectorStyle = makeStyles((theme) => createStyles({
    pageContainer: {
        height: "100%",
        overflow: "auto",
        flexWrap: "nowrap",
        minWidth: "320px"
    },
    pageBody: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column"
    },
    search: {
        marginLeft: "auto",
        [theme.breakpoints.down(545)]: {
            marginRight: "unset",
            flexBasis: "100%"
        }
    },
    breadcrumbs: {
        "& > ol": {
            marginBottom: odlSizes.spacing.factor4.s4
        }
    }
}));

export const categoryStyle = makeStyles(() => createStyles({
    category: {
        fontSize: odlSizes.typography.size.xlarge,
        lineHeight: odlSizes.spacing.factor4.s5,
        marginTop: odlSizes.spacing.factor4.s12,
        marginBottom: odlSizes.spacing.factor4.s6
    },
    categoryContainer: {
        marginBottom: odlSizes.spacing.factor4.s8,
        "& > div:first-of-type": {
            borderTopLeftRadius: odlSizes.spacing.factor4.s2,
            borderTopRightRadius: odlSizes.spacing.factor4.s2
        },
        "& > div:last-of-type": {
            borderBottomLeftRadius: odlSizes.spacing.factor4.s2,
            borderBottomRightRadius: odlSizes.spacing.factor4.s2
        }
    }
}));

export const typeStyle = makeStyles((theme) => createStyles({
    container: {
        "&div:first-child": {
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px"
        },
        flexWrap: "nowrap",
        backgroundColor: odlPalette.light.obj.white,
        padding: "18px 32px",
        margin: "2px 0",
        flex: "0 1 70%",
        width: "unset",
    },
    content: {
        flex: "1 0 auto",
        width: "calc(100% - 24px)"
    },
    contentNameContainer: {
        maxWidth: "75%",
        flex: "1 1 auto"
    },
    contentNameLink: {
      textDecoration: "none"
    },
    contentName: {
        color: odlPalette.light.obj.blue,
        fontSize: odlSizes.typography.size.xlarge,
        lineHeight: odlSizes.spacing.factor4.s5,
        textDecoration: "none"
    },
    contentDescriptionContainer: {
        maxWidth: "75%"
    },
    contentDescription: {
        fontSize: odlSizes.typography.size.medium,
        color: theme.palette.text.primary,
        lineHeight: odlSizes.spacing.factor4.s5,
        marginTop: odlSizes.spacing.factor4.s2,
        wordBreak: "break-word"
    },
    action: {
        alignSelf: "center",
        flex: "0 0 auto",
        "& > button.odlIconButton__root": {
            background: "unset",
            color: odlPalette.light.obj.blue
        }
    }
}));