import {
    AspectState,
    AspectTypes,
    ConditionType,
    FieldState,
    FieldTypes,
    FormState,
    MultiSectionAspectState,
    MultiSectionRowState,
    RuleBooleanCondition,
    RuleComparisonCondition,
    RuleCondition,
    RulePopulationCondition,
    RulesState,
    SectionAspectState,
    WorkflowActionState
} from "./state";
import {IRTypeEnum, RefTypeEnum, RuleValueReference} from "../../placeholder";
import {blankIfUndefined} from "../../util/notUndefined";
import {convertSingleRefDataValueToFieldValue} from "./form-utils";
import {isAfter, isBefore, isValid, parse} from "date-fns";
import {serverDateFormat, serverDateTimeFormat} from "../../api/constants";

interface IExpressionResult {
    forSingle: boolean;
    forMulti: { [templateId: string]: string[] };
}

type FieldValue = string | string[] | undefined;

export function applyRules(formState: FormState, rules: RulesState[]): Set<AspectState | FieldState> {
    const affectedStates: Set<AspectState | FieldState> = new Set();
    for (let rule of rules) {

        let hasActiveEffects = rule.effects.some(effect =>
            effect.reference?.some(r => r.refType !== RefTypeEnum.WORKFLOW_TRANSITION ||
                formState.actions.some(a => a.id === r.transitionId)
            )
        );
        if (hasActiveEffects) {
            const ruleActive: IExpressionResult = evaluateExpression(formState, rule.condition);

            for (let effect of rule.effects) {
                if (effect.reference) {
                    const type = effect.type;
                    if (type) {
                        const apply = (s: AspectState | FieldState, active: boolean) => {
                            const stateChanged = applyEffectToState(s, type, rule.id, active);
                            if (stateChanged) {
                                affectedStates.add(s);
                            }
                        }
                        const applyRow = (s: MultiSectionRowState, active: boolean) =>
                            applyEffectToState(s, type, rule.id, active);

                        for (let reference of effect.reference) {
                            if (reference.sectionTemplateId) {
                                const sectionState = resolveSectionState(formState, reference.sectionTemplateId);
                                if (sectionState) {
                                    applyAspectEffect(sectionState, reference, apply, applyRow, ruleActive);
                                }
                            } else if (reference.transitionId) {
                                const workflowAction = resolveWorkflowActionState(formState, reference.transitionId);
                                if (workflowAction) {
                                    applyEffectToState(workflowAction, type, rule.id, ruleActive.forSingle);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return affectedStates;
}

function applyEffectsToField(fields: FieldState[],
                             fieldTemplateId: string,
                             apply: (s: FieldState, active: boolean) => void,
                             result: boolean) {
    const fieldState = fields.find(f => f.template.id === fieldTemplateId);
    if (fieldState) {
        apply(fieldState, result);
    }
}

function applyAspectEffect(sectionState: SectionAspectState | MultiSectionAspectState,
                     reference: RuleValueReference,
                     apply: (s: (AspectState | FieldState), active: boolean) => void,
                     applyRow: (s: (MultiSectionRowState), active: boolean) => void,
                     ruleActive: IExpressionResult): void {
    if (sectionState.type === AspectTypes.SINGLE_SECTION) {
        if (reference.refType === RefTypeEnum.SECTION) {
            apply(sectionState, ruleActive.forSingle);
        } else if (reference.refType === RefTypeEnum.FIELD && reference.fieldTemplateId) {
            applyEffectsToField(sectionState.fields, reference.fieldTemplateId, apply, ruleActive.forSingle);
        }
    } else if (sectionState.type === AspectTypes.MULTI_SECTION) {
        const trueRows = ruleActive.forMulti[sectionState.template.id];
        const noSpecificRows = !trueRows || trueRows.length === 0;
        if (reference.refType === RefTypeEnum.SECTION) {
            apply(sectionState, ruleActive.forSingle && noSpecificRows);
            for (let row of sectionState.rows) {
                applyRow(row, ruleActive.forSingle && (noSpecificRows || trueRows.includes(row.key)));
            }
        } else if (reference.refType === RefTypeEnum.FIELD && reference.fieldTemplateId) {
            applyEffectsToField(sectionState.fieldTemplates, reference.fieldTemplateId, apply, ruleActive.forSingle && noSpecificRows);
            for (let row of sectionState.rows) {
                const result = ruleActive.forSingle && (noSpecificRows || trueRows.includes(row.key));
                applyEffectsToField(row.fields, reference.fieldTemplateId, apply, result);
            }
        }
    }
}

function applyEffectToState(state: AspectState | FieldState | MultiSectionRowState | WorkflowActionState,
                            effectType: IRTypeEnum, ruleId: string, ruleActive: boolean): boolean {
    let activesRules: string[];

    switch (effectType) {
        case IRTypeEnum.HIDE:
            activesRules = state.activeRules.hide;
            break;
        case IRTypeEnum.DISABLE:
            activesRules = state.activeRules.disable;
            break;
        case IRTypeEnum.REQUIRE:
            activesRules = state.activeRules.require;
            break;
        default:
            activesRules = [];
    }

    let previouslyActive = activesRules.includes(ruleId);
    if (ruleActive && !previouslyActive) {
        activesRules.push(ruleId);
        return true;
    } else if (!ruleActive && previouslyActive) {
        activesRules.splice(activesRules.indexOf(ruleId), 1);
        return activesRules.length === 0;
    }
    return false;
}

function evaluateExpression(formState: FormState, condition: RuleCondition): IExpressionResult {
    switch (condition.type) {
        case ConditionType.EQUALS:
        case ConditionType.NOT_EQUALS:
        case ConditionType.ALL:
        case ConditionType.ANY:
        case ConditionType.NONE:
        case ConditionType.DATE_BETWEEN:
        case ConditionType.DATE_MORE_THAN:
        case ConditionType.DATE_WITHIN_LAST:
        case ConditionType.DATE_IN_NEXT:
            return evaluateCompareCondition(formState, condition);
        case ConditionType.POPULATED:
        case ConditionType.EMPTY:
            return evaluatePopulatedCondition(formState, condition);
        case ConditionType.AND:
        case ConditionType.OR:
            return evaluateBooleanCondition(formState, condition);

    }
    return {forSingle: false, forMulti: {}};
}

function evaluatePopulatedCondition(formState: FormState, condition: RulePopulationCondition): IExpressionResult {
    const lhs = condition.lhs;
    if (lhs.refType === RefTypeEnum.FIELD &&
        lhs.sectionTemplateId && lhs.fieldTemplateId) {
        const fieldTemplateId = lhs.fieldTemplateId;
        const evaluator = (s: SectionAspectState | MultiSectionRowState) =>
            checkPopulated(resolveFieldValue(s, fieldTemplateId), condition);
        return runEvaluation(formState, lhs.sectionTemplateId, evaluator);
    } else if (lhs.refType === RefTypeEnum.STANDARD && lhs.standardFieldName) {
        return {
            forSingle: checkPopulated(resolveStandardValue(formState, lhs.standardFieldName), condition),
            forMulti: {}
        };
    }
    return {forSingle: false, forMulti: {}};
}

function evaluateCompareCondition(formState: FormState, condition: RuleComparisonCondition): IExpressionResult {
    const {lhs, rhs} = condition;
    if (lhs.sectionTemplateId && lhs.fieldTemplateId && rhs) {
        const fieldTemplateId = lhs.fieldTemplateId;
        const evaluator = (s: SectionAspectState | MultiSectionRowState) =>
            compareSides(resolveFieldValue(s, fieldTemplateId), rhs.constant, condition);
        return runEvaluation(formState, lhs.sectionTemplateId, evaluator);
    } else if (lhs.refType === RefTypeEnum.STANDARD && lhs.standardFieldName) {
        return {
            forSingle: compareSides(resolveStandardValue(formState, lhs.standardFieldName), rhs.constant, condition),
            forMulti: {}
        };
    }
    return {forSingle: false, forMulti: {}};
}

function runEvaluation(formState: FormState, sectionTemplateId: string,
                       evaluator: { (s: SectionAspectState | MultiSectionRowState): boolean }): IExpressionResult {
    const sectionState = resolveSectionState(formState, sectionTemplateId);
    if (sectionState) {
        if (sectionState.type === AspectTypes.SINGLE_SECTION) {
            return {forSingle: evaluator(sectionState), forMulti: {}};
        } else if (sectionState.type === AspectTypes.MULTI_SECTION) {
            const trueRows = sectionState.rows.filter(evaluator);
            return {forSingle: trueRows.length > 0, forMulti: {[sectionState.template.id]: trueRows.map(r => r.key)}};
        }
    }
    return {forSingle: false, forMulti: {}};
}

function checkPopulated(value: FieldValue, condition: RulePopulationCondition): boolean {
    switch (condition.type) {
        case ConditionType.POPULATED:
            return value?.length !== 0;
        case ConditionType.EMPTY:
            return value?.length === 0;
    }
}

function compareSides(lhs: FieldValue, rhs: FieldValue, condition: RuleComparisonCondition): boolean {
    switch (condition.type) {
        case ConditionType.EQUALS:
            return lhs === (Array.isArray(rhs) ? rhs[0] : rhs);
        case ConditionType.NOT_EQUALS:
            return lhs !== (Array.isArray(rhs) ? rhs[0] : rhs);
        case ConditionType.ALL:
            return Array.isArray(lhs) && Array.isArray(rhs) && lhs.length === rhs.length && lhs.every(v => rhs.includes(v));
        case ConditionType.ANY:
            return anyMatch(lhs, rhs);
        case ConditionType.NONE:
            return !anyMatch(lhs, rhs);
        case ConditionType.DATE_BETWEEN:
        case ConditionType.DATE_MORE_THAN:
        case ConditionType.DATE_WITHIN_LAST:
        case ConditionType.DATE_IN_NEXT:
            if (!rhs || !lhs || Array.isArray(lhs) || !isValid(getParsedDate(lhs))) {
                break;
            }
            return compareDates(getParsedDate(lhs), getParsedDate(rhs[0]), getParsedDate(rhs[1]));
    }
    return false;
}

function evaluateBooleanCondition(formState: FormState, condition: RuleBooleanCondition): IExpressionResult {
    const results = condition.conditions.map(c => evaluateExpression(formState, c));

    let result: IExpressionResult = {forSingle: false, forMulti: {}};

    if (condition.type === ConditionType.AND) {
        result.forSingle = true;
        for (let r of results) {
            result.forSingle = result.forSingle && r.forSingle;
            for (let key of Object.keys(r.forMulti)) {
                if (result.forMulti[key]) {
                    result.forMulti[key] = result.forMulti[key].filter(x => r.forMulti[key].indexOf(x) !== -1);
                } else {
                    result.forMulti[key] = r.forMulti[key];
                }
            }
        }
    } else if (condition.type === ConditionType.OR) {
        for (let r of results) {
            result.forSingle = result.forSingle || r.forSingle;
            for (let key of Object.keys(r.forMulti)) {
                const resultMulti: string[] = result.forMulti[key];
                if (resultMulti) {
                    for (let rowId of r.forMulti[key]) {
                        if (resultMulti.indexOf(rowId) === -1) {
                            resultMulti.push(rowId);
                        }
                    }
                } else {
                    result.forMulti[key] = r.forMulti[key];
                }
            }
        }
    }
    return result;
}

function getParsedDate(dateString: string): Date {
    return parse(dateString, dateString?.length === 10 ? serverDateFormat : serverDateTimeFormat, new Date());
}

function compareDates(lhs: Date, dateFrom: Date, dateTo: Date): boolean {
    if (!isValid(dateTo) && isValid(dateFrom)) {
        return isBefore(dateFrom, lhs);
    } else if (!isValid(dateFrom) && isValid(dateTo)) {
        return isAfter(dateTo, lhs);
    } else if (isValid(dateFrom) && isValid(dateTo)) {
        return isBefore(dateFrom, lhs) && isAfter(dateTo, lhs);
    }
    return false;
}

function anyMatch(lhs: FieldValue, rhs: FieldValue): boolean {
    if (lhs && rhs && Array.isArray(rhs) && typeof lhs === "string") {
        return rhs.indexOf(lhs) !== -1;
    } else if (lhs && rhs && Array.isArray(rhs) && Array.isArray(lhs)) {
        for (let rhsValue of rhs) {
            if (lhs.some(lhsValue => lhsValue === rhsValue)) {
                return true;
            }
        }
    }
    return false;
}

function resolveFieldValue(aspect: SectionAspectState | MultiSectionRowState, fieldTemplateId: string): FieldValue {
    let fieldState = aspect.fields.find(f => f.template.id === fieldTemplateId);
    if (fieldState) {
        switch (fieldState.type) {
            case FieldTypes.TEXT:
                return fieldState.value;
            case FieldTypes.NUMBER:
                return blankIfUndefined(fieldState.value?.toString());
            case FieldTypes.CHECK_BOX:
                return fieldState.value.toString();
            case FieldTypes.DROPDOWN_SINGLE:
                return fieldState.value ? convertSingleRefDataValueToFieldValue(fieldState.value) : "";
            case FieldTypes.FILE_UPLOAD:
                return fieldState.value.map(f => f.path);
            case FieldTypes.DROPDOWN_MULTI:
            case FieldTypes.CHECKBOX_GROUP:
                return fieldState.value.map(refData => convertSingleRefDataValueToFieldValue(refData));
            case FieldTypes.RADIO:
                return blankIfUndefined(fieldState.value?.name);
            case FieldTypes.DATE:
            case FieldTypes.DATE_TIME:
                return blankIfUndefined(fieldState.value?.dateString);
            case FieldTypes.LINK:
                return fieldState.value.map(linkValue => linkValue.code);
        }
    }
}

function resolveStandardValue(formState: FormState, standardFieldName: string): FieldValue {
    if (standardFieldName === "status") {
        return formState.status;
    }
}

function resolveSectionState(formState: FormState, sectionTemplateId: string): AspectState | undefined {
    return formState.aspects.find(a => a.template.id === sectionTemplateId);
}

function resolveWorkflowActionState(formState: FormState, transitionId: string): WorkflowActionState | undefined {
    return formState.actions.find(t => t.id === transitionId);
}
