import {createStyles, makeStyles} from "@material-ui/core";
import odlSizes from 'odl-components/styles/odlSizes.json'
import {odlStyled, Tabs} from "odl-components";

export const dashboardPageStyle = makeStyles(() => createStyles({
    pageHeaderTabs: {
        background: "transparent",
        marginTop: odlSizes.spacing.factor4.s2,
        marginBottom: odlSizes.spacing.factor4.s6,
        marginLeft: "-12px"
    },
    newSubmission: {
        marginBottom: odlSizes.spacing.factor4.s6,
        marginLeft: "auto",
        marginRight: odlSizes.spacing.factor4.s4
    }
}));


export const TabsStyled = odlStyled(Tabs)`
    &.odlTab__root {
        height: unset;
    },
    &.odlTab__root > .MuiTabs-scroller > .MuiTabs-flexContainer {
        flex-wrap: wrap;
    },
    & .odlTab__tabsWrapper {
        font-size: 14px;
        line-height: ${odlSizes.spacing.factor4.s5};
    }
`