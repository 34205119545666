import {makeStyles} from "@material-ui/core/styles";
import {createStyles} from "@material-ui/core";
import odlSizes from "odl-components/styles/odlSizes.json"
import odlProPalette from "odl-components/styles/odlProPalette";

export const fieldStyle = makeStyles((theme) => createStyles({
    common: {
        maxWidth: "640px",
        width: "100%"
    },
    button: {
        marginTop: "12px",
        height: "44px",
        borderRadius: "22px",
        width: "144px",
        "&.MuiButton-root": {
            textTransform: "none",
        }
    },
    fileUploadInput: {
        display: "none"
    },
    pill: {
        marginTop: odlSizes.spacing.factor4.s4
    },
    dateField: {
        "& .MuiInputAdornment-root > .MuiIconButton-root": {
            color: theme.palette.primary.main
        }
    }
}));

export const pillStyle = makeStyles((theme) => createStyles({
    root: {
        border: `1px solid ${odlProPalette.light.obj.deco}`,
        borderRadius: odlSizes.spacing.factor4.s1,
    },
    container: {
        backgroundColor: odlProPalette.light.obj.white,
        paddingLeft: odlSizes.spacing.factor4.s4,
        paddingRight: odlSizes.spacing.factor4.s4,
        borderRadius: "inherit"
    },
    clearIcon: {
        fontSize: odlSizes.typography.size.small,
        lineHeight: odlSizes.typography.size.small,
        fontWeight: 400,
        cursor: "pointer"
    },
    fileName: {
        flex: "1 1 auto",
        margin: "12px 10px",
        lineHeight: odlSizes.spacing.factor4.s5,
        fontWeight: 500
    },
    downloadable: {
        color: theme.palette.link.primary,
        cursor: "pointer"
    }
}));
