import {makeStyles} from "@material-ui/core/styles";
import {createStyles} from "@material-ui/core";
import odlPalette from "odl-components/styles/odlProPalette"
import odlSizes from "odl-components/styles/odlSizes.json"
import odlProPalette from "odl-components/styles/odlProPalette";

export const summaryStyle = makeStyles((theme) => createStyles({
    typographyH2: {
        fontSize: odlSizes.typography.size.xlarge,
        fontWeight: 500,
        lineHeight: odlSizes.spacing.factor4.s5,
    },
    header: {
        "& > button": {
            marginLeft: "auto",
            marginBottom: odlSizes.spacing.factor4.s2,
            marginTop: odlSizes.spacing.factor4.s3
        }
    },
    errorBanner: {
        marginTop: odlSizes.spacing.factor4.s6
    },
    navButton: {
        "& > .odlButton__label": {
            margin: "12px 20px",
            fontSize: "20px",
            lineHeight: "28px"
        }
    },
    primaryAction: {
        marginLeft: odlSizes.spacing.factor4.s6
    },
    menuItem: {
        color: odlProPalette.light.obj.blue,
        padding: odlSizes.spacing.xxs
    }
}));

export const summaryContentStyle = makeStyles((theme) => createStyles({
    paper: {
        padding: odlSizes.spacing.factor4.s6,
        backgroundColor: odlPalette.light.obj.day,
        width: "70%",
    },
    aspectSummary: {
        marginBottom: odlSizes.spacing.factor4.s6,
        borderRadius: odlSizes.spacing.factor4.s2,
        paddingLeft: odlSizes.spacing.factor4.s8,
        paddingRight: odlSizes.spacing.factor4.s8,
        "& > div > a:lastChild": {
            marginLeft: "auto"
        },
        "& > div > div": {
            flex: "1 1 auto"
        }
    },
    aspectName: {
        marginTop: odlSizes.spacing.factor4.s8,
        marginBottom: odlSizes.spacing.factor4.s6,
        fontSize: odlSizes.typography.size.xlarge,
        fontWeight: 400,
        lineHeight: odlSizes.spacing.factor4.s5,
    },
    editLink: {
        marginTop: odlSizes.spacing.factor4.s6,
        marginLeft: "auto",
        fontSize: odlSizes.typography.size.medium,
        fontWeight: 500,
        textDecoration: "none",
        "& span": {
            marginRight: odlSizes.spacing.factor4.s1
        },
        color: theme.palette.link.primary
    },
    errorBanner: {
        backgroundColor: odlPalette.light.obj.lightRed,
        color: odlPalette.light.obj.red,
        padding: odlSizes.spacing.factor4.s6,
        width: "70%",
        marginBottom: odlSizes.spacing.factor4.s4
    }
}));

export const fieldStyle = makeStyles(() => createStyles({
    container: {
        marginTop: odlSizes.spacing.factor4.s4,
        marginBottom: odlSizes.spacing.factor4.s4
    },
    value: {
        marginTop: odlSizes.spacing.factor4.s1,
        fontWeight: 500,
        whiteSpace: "pre-wrap"
    }
}));

export const rowStyle = makeStyles(() => createStyles({
    rowContainer: {
        "& > div:not(:last-child)": {
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
            borderBottomColor: odlPalette.light.obj.deco
        },
    }
}));