import {
    ActiveRulesState,
    AspectState,
    AspectTypes,
    CommonFieldState,
    ConditionType,
    FieldState,
    FieldStateReference,
    FieldTemplateState,
    FieldTypes,
    FileValue,
    FormState,
    GroupState,
    LinkFieldValue,
    MultiOptionsFieldState,
    MultiSectionAspectState,
    MultiSectionRowState,
    RuleCondition,
    RulesState,
    SectionAspectState,
    SingleOptionFieldState,
    WorkflowActionState
} from "../features/form/state";
import {client} from "./axios-client";
import {
    ActionTypeEnum,
    ComponentTypeEnum,
    ConditionOperatorEnum,
    DocumentTreeNode,
    DomainAction,
    DomainComponentTemplate,
    DomainData,
    DomainFieldMeta,
    DomainFieldMetaParameter,
    DomainHistory,
    DomainMeta,
    FieldData,
    FieldTypeEnum,
    InferenceRule,
    InferenceRuleCondition,
    ReferenceDataValue,
    SectionData,
    Sections
} from "../placeholder";
import {emptyArrayIfUndefined, fallbackIfUndefined, undefinedIfNaN, zeroIfUndefined} from "../util/notUndefined";
import {acceptTypesFileUploadKey, ITEM_DELIMITER} from "./constants";
import {PATH_SEPARATOR} from "./document-service";
import {convertFieldValueToRefDataValue, splitCombinedRefDataString} from "../features/form/form-utils";
import {PortalConfig} from "./contexts";

const operatorMap = {
    [ConditionOperatorEnum.EQUALS]: ConditionType.EQUALS,
    [ConditionOperatorEnum.NOT_EQUALS]: ConditionType.NOT_EQUALS,
    [ConditionOperatorEnum.ALL]: ConditionType.ALL,
    [ConditionOperatorEnum.ANY]: ConditionType.ANY,
    [ConditionOperatorEnum.NOT_IN]: ConditionType.NONE,
    [ConditionOperatorEnum.EMPTY]: ConditionType.EMPTY,
    [ConditionOperatorEnum.POPULATED]: ConditionType.POPULATED,
    [ConditionOperatorEnum.DATE_BETWEEN]: ConditionType.DATE_BETWEEN,
    [ConditionOperatorEnum.DATE_MORE_THAN]: ConditionType.DATE_MORE_THAN,
    [ConditionOperatorEnum.DATE_WITHIN_LAST]: ConditionType.DATE_WITHIN_LAST,
    [ConditionOperatorEnum.DATE_IN_NEXT]: ConditionType.DATE_IN_NEXT
}

export async function loadDomain(typeId: string, portalConfig: PortalConfig): Promise<FormState> {
    const response = await client.post("integration/api/rule/prepare", {"domainTypeId": typeId});
    const formState = metaToFormState(response.data.domain.meta as DomainMeta, portalConfig);
    applyDataToFormState(response.data.domain.data as DomainData, formState);
    return formState;
}

export async function loadExistingDomain(domainCode: string, portalConfig: PortalConfig): Promise<FormState> {
    const [meta, data] = await Promise.all([
        client.get(`integration/ui/meta/${domainCode}`),
        client.get(`integration/api/domain/${domainCode}`)
    ]);
    let formState = metaToFormState(meta.data as DomainMeta, portalConfig);
    applyDataToFormState(data.data as DomainData, formState);
    return formState;
}

function actionsToState(workflowActions: DomainAction[]): WorkflowActionState[] {
    return workflowActions.filter(wa =>
        wa.actionType === ActionTypeEnum.SIMPLE &&
        wa.name &&
        wa.actionId
    ).map(a => {
        return {
            name: a.name as string,
            id: a.actionId as string,
            activeRules: {
                hide: [],
                disable: [],
                require: []
            }
        };
    });
}

export function metaToFormState(meta: DomainMeta, portalConfig: PortalConfig): FormState {
    return {
        typeId: meta.domainTypeId as string,
        typeName: meta.domainTypeName as string,
        loading: false,
        changesPending: false,
        groups: meta.components ? aspectsToGroupState(meta.components) : [],
        aspects: meta.components ? aspectsToAspectState(meta.components, portalConfig) : [],
        rules: meta.inferenceRules ? rulesToRuleState(meta.inferenceRules) : [],
        actions: meta.workflowActions ? actionsToState(meta.workflowActions) : [],
        validatedGroups: 0,
        customErrors: [],
        inInitialStatus: true,
        draftEnabled: fallbackIfUndefined(meta.draftEnabled, false)
    };
}

export function applyDataToFormState(data: DomainData, formState: FormState): void {
    formState.domainCode = data.code;
    formState.status = data.status;
    formState.inInitialStatus = checkInitialStatus(data.status, emptyArrayIfUndefined(data.history));
    applySectionData(data.sections ? data.sections : {}, formState.aspects, data.documents ? data.documents : {});
}

function checkInitialStatus(status: string | undefined, history: DomainHistory[]): boolean {
    if (status === undefined || history.length === 0) {
        return true;
    }
    return history[0].type === status;
}

function applySectionData(sections: Sections, aspectStates: AspectState[], documents: { [key: string]: DocumentTreeNode; }) {
    for (const aspectState of aspectStates) {
        const sectionData = sections[aspectState.name];
        if (sectionData) {
            switch (aspectState.type) {
                case AspectTypes.SINGLE_SECTION:
                    applySingleSectionData(aspectState, sectionData, documents);
                    break;
                case AspectTypes.MULTI_SECTION:
                    applyMultiSectionData(aspectState, sectionData, documents);
                    break;
            }
        }
    }
    for (const aspectState of aspectStates) {
        if (aspectState.type === AspectTypes.SINGLE_SECTION) {
            applyCascadesToSections(aspectState.fields, aspectStates);
        } else if (aspectState.type === AspectTypes.MULTI_SECTION) {
            applyCascadesToSections(aspectState.fieldTemplates, aspectStates);
            for (let row of aspectState.rows) {
                applyCascadesToSections(row.fields, aspectStates, row);
            }
        }
    }
}

function applyCascadesToSections(fields: FieldState[], aspectStates: AspectState[], row?: MultiSectionRowState) {
    for (let field of fields) {
        if ((field.type === FieldTypes.DROPDOWN_SINGLE || field.type === FieldTypes.CHECKBOX_GROUP)
            && field.refDataType.parentCascadingField) {
            const {sectionTemplateId, fieldTemplateId} = field.refDataType.parentCascadingField;
            const parentAspect = aspectStates.find(a => a.template.id === sectionTemplateId);
            if (parentAspect) {
                if (parentAspect.type === AspectTypes.SINGLE_SECTION) {
                    applyParentCode(parentAspect.fields, fieldTemplateId, field);
                } else if (parentAspect.type === AspectTypes.MULTI_SECTION && row) {
                    applyParentCode(row.fields, fieldTemplateId, field);
                }
            }
        }
    }
}

function applyParentCode(parentAspectFields: FieldState[], fieldTemplateId: string,
                         field: SingleOptionFieldState | MultiOptionsFieldState) {
    const parentField = parentAspectFields.find(f => f.template.id === fieldTemplateId);
    if (parentField && parentField.type === FieldTypes.DROPDOWN_SINGLE) {
        field.refDataType.parentCode = parentField.value?.code;
    }
}

function applySingleSectionData(aspectState: SectionAspectState, sectionData: SectionData,
                                documents: { [key: string]: DocumentTreeNode; }): void {
    if (sectionData.fields) {
        const fieldData = sectionData.fields ? sectionData.fields : {};
        let fieldStates = aspectState.fields;
        applyFieldData(fieldStates, fieldData, documents);
    }
}

function applyMultiSectionData(aspectState: MultiSectionAspectState, sectionData: SectionData,
                               documents: { [key: string]: DocumentTreeNode; }): void {
    if (sectionData.rows) {
        const rowData = sectionData.rows ? sectionData.rows : {};
        let rowKeys = Object.keys(rowData);
        for (let rowKey of rowKeys) {
            let rowState = aspectState.rows.find(r => r.key === rowKey);
            if (rowState) {
                applyFieldData(rowState.fields, rowData[rowKey].fields as FieldData, documents);
            } else {
                const fieldStates = JSON.parse(JSON.stringify(aspectState.fieldTemplates));
                applyFieldData(fieldStates, rowData[rowKey].fields as FieldData, documents);
                aspectState.rows.push({
                    key: rowKey,
                    isNew: false,
                    removed: false,
                    fields: fieldStates,
                    activeRules: {
                        hide: [],
                        require: [],
                        disable: []
                    },
                    customErrors: [],
                    displayOrder: zeroIfUndefined(rowData[rowKey].displayOrder)
                });
            }
        }
        aspectState.rows.sort((a, b) => {
            return a.displayOrder - b.displayOrder;
        });
    }
}

function applyFieldData(fieldStates: FieldState[], fieldData: FieldData, documents: { [key: string]: DocumentTreeNode; }) {
    fieldStates.forEach(fieldState => {
        if (fieldData.hasOwnProperty(fieldState.template.name)) {
            applyFieldValue(fieldState, fieldData[fieldState.template.name], documents);
        }
    });
}

function applyFieldValue(fieldState: FieldState, value: any, documents: { [key: string]: DocumentTreeNode; }): void {
    switch (fieldState.type) {
        case FieldTypes.CHECK_BOX:
            fieldState.value = value === "true";
            break;
        case FieldTypes.FILE_UPLOAD:
            fieldState.value = buildFileValues(value, documents);
            break;
        case FieldTypes.DROPDOWN_MULTI:
        case FieldTypes.CHECKBOX_GROUP:
            fieldState.value = convertFieldValueToRefDataValue(value);
            break;
        case FieldTypes.RADIO:
            fieldState.value = fieldState.refDataType.codeTableKey ?
                {name: value, code: undefined} : convertRadioDataToRefData(value, fieldState.refDataType.options);
            break;
        case FieldTypes.DROPDOWN_SINGLE:
            fieldState.value = value ? splitCombinedRefDataString(value) : null;
            break;
        case FieldTypes.LABEL:
            break;
        case FieldTypes.DATE_TIME:
        case FieldTypes.DATE:
            fieldState.value = value ? {
                dateString: value,
                invalid: false
            } : undefined;
            break;
        case FieldTypes.LINK:
            fieldState.originalValue = value;
            fieldState.value = convertLinkValue(value);
            break;
        default:
            fieldState.value = value;
    }
}

function convertLinkValue(value?: string): LinkFieldValue[] {
    if (value) {
        const linkValue: any[] = JSON.parse(value);
        return linkValue.map(v => {
            return {code: v.code, name: v.name};
        });
    }
    return [];
}

function buildFileValues(paths: string, documents: { [key: string]: DocumentTreeNode; }): FileValue[] {
    return paths ? paths.split(ITEM_DELIMITER)
        .map(v => ({
            name: v.replace(/^.*(\\|\/|:)/, ""),
            path: v,
            externalUrl: documents[v]?.data?.externalURL,
            isNew: false
        })) : [];
}

const validAspectTypes = [ComponentTypeEnum.SINGLE_SECTION, ComponentTypeEnum.MULTI_SECTION, ComponentTypeEnum.CUSTOM_LOCATION];

function aspectsToGroupState(aspects: DomainComponentTemplate[]): GroupState[] {
    let groups: GroupState[] = []
    for (let aspect of aspects) {
        if (isValidAspect(aspect))
            if (groups.length === 0 || !aspect.groupName || aspect.groupName !== groups[groups.length - 1].name) {
                groups.push({
                    name: aspect.groupName ? aspect.groupName : aspect.displayName as string,
                    aspectIds: [aspect.id as string]
                });
            } else {
                let groupState = groups[groups.length - 1];
                groupState.aspectIds.push(aspect.id as string);
            }
    }
    return groups;
}

function aspectsToAspectState(aspects: DomainComponentTemplate[], config: PortalConfig): AspectState[] {
    let aspectStates: AspectState[] = []

    for (let aspect of aspects) {
        if (isValidAspect(aspect) &&
            aspect.sectionTemplate &&
            aspect.sectionTemplate.sectionTemplateId &&
            aspect.sectionTemplate.name &&
            aspect.sectionTemplate.templateName) {

            const activeRules: ActiveRulesState = {
                hide: [],
                disable: [],
                require: []
            };

            if (ComponentTypeEnum.SINGLE_SECTION === aspect.componentType || ComponentTypeEnum.CUSTOM_LOCATION === aspect.componentType) {
                const aspectState: SectionAspectState = {
                    id: aspect.id as string,
                    name: aspect.name as string,
                    displayName: aspect.displayName as string,
                    type: AspectTypes.SINGLE_SECTION,
                    template: {
                        id: aspect.sectionTemplate.sectionTemplateId,
                        name: aspect.sectionTemplate.name,
                        visible: fallbackIfUndefined(aspect.sectionTemplate.visible, true),
                        editable: fallbackIfUndefined(aspect.sectionTemplate.editable, true),
                        triggersRules: fallbackIfUndefined(aspect.sectionTemplate.triggersRules, false)
                    },
                    fields: buildFieldList(aspects, aspect, aspect.sectionTemplate.fields, config),
                    activeRules,
                    customErrors: []
                };
                if (ComponentTypeEnum.CUSTOM_LOCATION === aspect.componentType) {
                    const meta: DomainFieldMeta = {
                        fieldTemplateId: aspect.id,
                        name: aspect.name,
                        tooltipText: aspect.tooltipText
                    };
                    const addressField = fieldTemplateToState(meta, FieldTypes.ADDRESS, aspect, aspects, config);
                    if (addressField && FieldTypes.ADDRESS === addressField.type) {
                        aspectState.fields.unshift(addressField);
                    }
                }
                aspectStates.push(aspectState);
            } else if (ComponentTypeEnum.MULTI_SECTION === aspect.componentType) {
                aspectStates.push({
                    id: aspect.id as string,
                    name: aspect.name as string,
                    displayName: aspect.displayName as string,
                    type: AspectTypes.MULTI_SECTION,
                    template: {
                        id: aspect.sectionTemplate.sectionTemplateId,
                        name: aspect.sectionTemplate.name,
                        visible: fallbackIfUndefined(aspect.sectionTemplate.visible, true),
                        editable: fallbackIfUndefined(aspect.sectionTemplate.editable, true),
                        triggersRules: fallbackIfUndefined(aspect.sectionTemplate.triggersRules, false)
                    },
                    allowAddRow: !fallbackIfUndefined(aspect.sectionTemplate.hideAdd, false),
                    allowDeleteRow: !fallbackIfUndefined(aspect.sectionTemplate.hideDelete, false),
                    fieldTemplates: buildFieldList(aspects, aspect, aspect.sectionTemplate.fields, config),
                    rows: [],
                    activeRules,
                    customErrors: []
                });
            }
        }
    }

    return aspectStates;
}

const fieldTypeToStateMapping: { [key: string]: FieldTypes } = {
    [FieldTypeEnum.TEXT]: FieldTypes.TEXT,
    [FieldTypeEnum.NUMBER]: FieldTypes.NUMBER,
    [FieldTypeEnum.BOOLEAN]: FieldTypes.CHECK_BOX,
    [FieldTypeEnum.SELECT_SINGLE]: FieldTypes.DROPDOWN_SINGLE,
    [FieldTypeEnum.SELECT_MULTI]: FieldTypes.DROPDOWN_MULTI,
    [FieldTypeEnum.DATE_TIME]: FieldTypes.DATE_TIME,
    [FieldTypeEnum.DATE]: FieldTypes.DATE,
    [FieldTypeEnum.FILE_UPLOAD]: FieldTypes.FILE_UPLOAD,
    [FieldTypeEnum.LABEL]: FieldTypes.LABEL,
    [FieldTypeEnum.CHECKBOX_GROUP]: FieldTypes.CHECKBOX_GROUP,
    [FieldTypeEnum.RADIO]: FieldTypes.RADIO,
    [FieldTypeEnum.LINK]: FieldTypes.LINK
};

function buildFieldList(aspects: DomainComponentTemplate[],
                        aspect: DomainComponentTemplate,
                        fields: DomainFieldMeta[] | undefined,
                        config: PortalConfig) {
    if (!fields) {
        return [];
    }
    const fieldStates: FieldState[] = [];
    for (const f of fields) {
        if (!f.fieldType) {
            continue;
        }
        const type = fieldTypeToStateMapping[f.fieldType];
        if (!type) {
            continue;
        }
        const fieldState = fieldTemplateToState(f, type, aspect, aspects, config);
        if (fieldState) {
            fieldStates.push(fieldState);
        }
    }
    return fieldStates;
}

function fieldTemplateToState(fieldMeta: DomainFieldMeta,
                              type: FieldTypes,
                              aspectMeta: DomainComponentTemplate,
                              aspects: DomainComponentTemplate[],
                              portalConfig: PortalConfig): FieldState | undefined {
    const template: FieldTemplateState = {
        id: fieldMeta.fieldTemplateId as string,
        name: fieldMeta.name as string,
        displayName: fieldMeta.displayName as string,
        tooltip: fieldMeta.tooltipText,
        editable: fallbackIfUndefined(fieldMeta.editable, true),
        visible: fallbackIfUndefined(fieldMeta.visible, true),
        mandatory: fallbackIfUndefined(fieldMeta.mandatory, false),
        parameters: fallbackIfUndefined(fieldMeta.parameters, []),
        triggersRules: fallbackIfUndefined(fieldMeta.triggerRules, false)
    };

    const activeRules: ActiveRulesState = {
        hide: [],
        disable: [],
        require: []
    };

    const commonState: CommonFieldState = {
        template,
        activeRules,
        errors: {},
        customErrors: []
    }

    switch (type) {
        case FieldTypes.TEXT:
            return {
                type,
                value: fallbackIfUndefined(fieldMeta.defaultFieldValue, ""),
                maxLength: undefinedIfNaN(fieldMeta.fieldLength),
                ...(fieldMeta.validationExpression && {
                    regExValidation: {
                        regEx: fieldMeta.validationExpression,
                        failedMsg: fieldMeta.validationMessage
                    }
                }),
                ...commonState
            };
        case FieldTypes.NUMBER:
            return {
                type,
                value: undefinedIfNaN(fieldMeta.defaultFieldValue),
                min: undefinedIfNaN(fieldMeta.minValueRange),
                max: undefinedIfNaN(fieldMeta.maxValueRange),
                ...(fieldMeta.validationExpression && {
                    regExValidation: {
                        regEx: fieldMeta.validationExpression,
                        failedMsg: fieldMeta.validationMessage
                    }
                }),
                ...commonState
            };
        case FieldTypes.CHECK_BOX:
            return {
                type,
                value: false,
                ...commonState
            };
        case FieldTypes.DROPDOWN_SINGLE:
        case FieldTypes.RADIO:
            return {
                type,
                refDataType: {
                    templateName: aspectMeta.sectionTemplate?.templateName,
                    sectionName: aspectMeta.sectionTemplate?.name,
                    codeTableKey: fieldMeta.codeTableKey,
                    parentCascadingField: buildFieldReference(fieldMeta.cascadingFieldTemplateId, aspects),
                    customReferenceData: !!fieldMeta.referenceData?.length,
                    options: fieldMeta.referenceData || []
                },
                value: null,
                ...commonState
            };
        case FieldTypes.ADDRESS:
        case FieldTypes.LABEL:
            return {
                type,
                ...commonState
            };
        case FieldTypes.DATE_TIME:
        case FieldTypes.DATE:
            return {
                type,
                value: undefined,
                ...commonState
            };
        case FieldTypes.FILE_UPLOAD:
            return {
                type,
                value: [],
                multiple: !!fieldMeta.multiple,
                fileTypes: getSupportedFileTypes(portalConfig, fallbackIfUndefined(fieldMeta.parameters, [])),
                defaultFolder: formatDefaultFolder(fieldMeta.defaultFolder),
                ...commonState
            };
        case FieldTypes.DROPDOWN_MULTI:
        case FieldTypes.CHECKBOX_GROUP:
            return {
                type,
                value: [],
                refDataType: {
                    templateName: aspectMeta.sectionTemplate?.templateName,
                    sectionName: aspectMeta.sectionTemplate?.name,
                    codeTableKey: fieldMeta.codeTableKey,
                    parentCascadingField: buildFieldReference(fieldMeta.cascadingFieldTemplateId, aspects),
                    customReferenceData: !!fieldMeta.referenceData?.length,
                    options: fieldMeta.referenceData || []
                },
                ...commonState
            };
        case FieldTypes.LINK:
            return {
                type,
                ...commonState,
                value: []
            };
    }
}

function buildFieldReference(fieldTemplateId: string | undefined, aspects: DomainComponentTemplate[]): FieldStateReference | undefined {
    if (!fieldTemplateId) {
        return undefined;
    }
    const parentAspect = aspects.find(a => a.sectionTemplate?.fields?.some(f => f.fieldTemplateId === fieldTemplateId));
    if (parentAspect?.sectionTemplate?.sectionTemplateId) {
        return {
            sectionTemplateId: parentAspect.sectionTemplate.sectionTemplateId,
            fieldTemplateId
        }
    }
    return undefined;
}

function formatDefaultFolder(defaultFolder?: string): string | undefined {
    if (!defaultFolder) {
        return undefined;
    }
    let dirWithoutCode = defaultFolder.replace(/\/*{code}/i, "");
    let formattedPath: string = "";
    if (!dirWithoutCode.startsWith(PATH_SEPARATOR)) {
        formattedPath += PATH_SEPARATOR;
    }
    if (dirWithoutCode.endsWith(PATH_SEPARATOR)) {
        // Remove any trailing /
        let index: number = dirWithoutCode.lastIndexOf(PATH_SEPARATOR);
        formattedPath += dirWithoutCode.substring(0, index);
    } else {
        formattedPath += dirWithoutCode;
    }
    return formattedPath;
}

function getSupportedFileTypes(portalConfig: PortalConfig, parameters: DomainFieldMetaParameter[]): string | undefined {
    const param = parameters.find(params => params.key === acceptTypesFileUploadKey);
    const paramValue = fallbackIfUndefined(param?.value, "");
    const fieldFileTypes = paramValue.split(",").map(type => type.trim()).filter(type => type !== "");
    const fileTypes = portalConfig.documentUploadFileTypeWhitelist;
    let supportTypes;
    if (fileTypes.length === 0 && fieldFileTypes.length === 0) {
        return undefined;
    } else if (fileTypes.length > 0 && fieldFileTypes.length === 0) {
        supportTypes = fileTypes;
    } else if (fileTypes.length === 0 && fieldFileTypes.length > 0) {
        supportTypes = fileTypes;
    } else {
        let intersection = fieldFileTypes.filter(fft => fileTypes.find(ft => ft === fft));
        supportTypes = intersection.length > 0 ? intersection : fileTypes;
    }
    return supportTypes.map(extension => "." + extension).join(",");
}

interface ConditionRefs {
    conditionSections: string[],
    conditionFields: string[]
}

function rulesToRuleState(inferenceRules: InferenceRule[]): RulesState[] {
    return inferenceRules.map((value, index) => {
        let operator = ConditionOperatorEnum[value.conditionOperator as any];
        let conditionRefs: ConditionRefs = {
            conditionSections: [],
            conditionFields: []
        };
        let condition: RuleCondition = ruleConditionToRuleConditionState({
            conditionOperator: operator as any,
            childConditions: value.conditions
        }, conditionRefs);
        return {
            id: index.toString(),
            condition,
            conditionSections: conditionRefs.conditionSections,
            conditionFields: conditionRefs.conditionFields,
            effects: value.effects ? value.effects : []
        };
    });
}

function ruleConditionToRuleConditionState(condition: InferenceRuleCondition, refs: ConditionRefs): RuleCondition {
    if (condition.lhs) {
        if (condition.lhs.sectionTemplateId && !refs.conditionSections.includes(condition.lhs.sectionTemplateId)) {
            refs.conditionSections.push(condition.lhs.sectionTemplateId);
        }
        if (condition.lhs.fieldTemplateId && !refs.conditionFields.includes(condition.lhs.fieldTemplateId)) {
            refs.conditionFields.push(condition.lhs.fieldTemplateId);
        }
    }
    switch (condition.conditionOperator) {
        case ConditionOperatorEnum.AND:
            return {
                type: ConditionType.AND,
                conditions: condition.childConditions ?
                    condition.childConditions.map(c => ruleConditionToRuleConditionState(c, refs)) : []
            }
        case ConditionOperatorEnum.OR:
            return {
                type: ConditionType.OR,
                conditions: condition.childConditions ?
                    condition.childConditions.map(c => ruleConditionToRuleConditionState(c, refs)) : []
            }
        case ConditionOperatorEnum.EQUALS:
        case ConditionOperatorEnum.NOT_EQUALS:
        case ConditionOperatorEnum.ALL:
        case ConditionOperatorEnum.ANY:
        case ConditionOperatorEnum.NOT_IN:
        case ConditionOperatorEnum.DATE_BETWEEN:
        case ConditionOperatorEnum.DATE_MORE_THAN:
        case ConditionOperatorEnum.DATE_WITHIN_LAST:
        case ConditionOperatorEnum.DATE_IN_NEXT:
            if (!condition.lhs || !condition.rhs) {
                break;
            }
            return {
                type: operatorMap[condition.conditionOperator] as any,
                lhs: condition.lhs,
                rhs: condition.rhs
            };
        case ConditionOperatorEnum.EMPTY:
        case ConditionOperatorEnum.POPULATED:
            if (!condition.lhs) {
                break;
            }
            return {
                type: condition.conditionOperator === ConditionOperatorEnum.EMPTY ?
                    ConditionType.EMPTY : ConditionType.POPULATED,
                lhs: condition.lhs
            };
    }
    return {
        type: ConditionType.AND,
        conditions: []
    };
}

function isValidAspect(aspect: DomainComponentTemplate): boolean {
    return !!aspect.id &&
        !!aspect.name &&
        !!aspect.displayName &&
        !!aspect.componentType &&
        validAspectTypes.includes(aspect.componentType);
}

function convertRadioDataToRefData(refDataName: string, refDataList: ReferenceDataValue[]): ReferenceDataValue | null {
    const refData = refDataList.find(refData => refData.name === refDataName);
    return refData ? refData : null;
}