/**
 * RegulationWorks REST
 * No descripton provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

'use strict';
import * as models from './models';

export interface SearchCriteria {
    

    displayName?: string;

    indexPath?: string;

    mappingName?: string;

    sectionName?: string;

    value?: string;

    dynamicValue?: string;

    editable?: boolean;

    type?: TypeEnum;

    operator?: OperatorEnum;

    clause?: ClauseEnum;
}

    export enum TypeEnum { 
        TEXT = <any> 'TEXT',
        DATE = <any> 'DATE',
        DATE_TIME = <any> 'DATE_TIME',
        TIME = <any> 'TIME',
        NUMBER = <any> 'NUMBER',
        DOUBLE = <any> 'DOUBLE',
        LONG = <any> 'LONG',
        DROPDOWN = <any> 'DROPDOWN',
        BOOLEAN = <any> 'BOOLEAN',
        TRISTATE = <any> 'TRISTATE',
        ATTACHMENT = <any> 'ATTACHMENT',
        RESOURCE = <any> 'RESOURCE',
        INDEXED_FIELD = <any> 'INDEXED_FIELD',
    }

    export enum OperatorEnum { 
        EQUAL = <any> 'EQUAL',
        SIMILAR = <any> 'SIMILAR',
        GREATER_EQUAL = <any> 'GREATER_EQUAL',
        GREATER_THAN = <any> 'GREATER_THAN',
        LESS_EQUAL = <any> 'LESS_EQUAL',
        LESS_THAN = <any> 'LESS_THAN',
        EMPTY = <any> 'EMPTY',
        POPULATED = <any> 'POPULATED',
        BETWEEN = <any> 'BETWEEN',
    }

    export enum ClauseEnum { 
        SHOULD = <any> 'SHOULD',
        MUST = <any> 'MUST',
        NOT = <any> 'NOT',
    }
