import {FileValue, FormState} from "./state";
import {navButton} from "./FormGroup.style";
import {ISnackBar, useSnackbar} from "../../components/SnackbarProvider";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {inProgressFileUploadFields} from "./form-utils";
import {AppConfigContext, AppLoginContext, useLoadingContext, UserProfile} from "../../api/contexts";
import {loadNewFormValues, submitForm} from "./reducer";
import {UpdateDomain} from "../../placeholder";
import {AlertTypes, FontIcon, ODL_ICONS} from "odl-components";
import {processRedirectActions} from "../../api/redirect-service";
import {ODLButton} from "../../components/ODLWrapper";
import ButtonTypes from "odl-components/components/Button/Button.types";
import {WorkflowActionComponent} from "./FormSummary";
import {CircularProgress, createStyles, Snackbar} from "@material-ui/core";
import {History} from "history";
import {APP_ROUTES} from "../../api/app-routes";
import {fallbackIfUndefined} from "../../util/notUndefined";
import {makeStyles} from "@material-ui/core/styles";
import odlPalette from "odl-components/styles/odlProPalette";
import odlSizes from "odl-components/styles/odlSizes.json"
import clsx from "clsx";

export function DraftButton() {
    const history = useHistory();
    const snackBar = useSnackbar();
    const userProfile: UserProfile = useContext(AppLoginContext).profile;
    const draftEnabled: boolean = useSelector((formState: FormState) => formState.draftEnabled);

    const saveHandler = useCallback(() => {
        snackBar.addMessage({text: "Draft saved successfully", type: AlertTypes.SUCCESS});
        history.push(APP_ROUTES.submissionsDashboard);
    }, [history, snackBar]);

    if (draftEnabled && !userProfile.anonymousUser) {
        return <SubmitButton id={"draft-submission"}
                             successCallback={saveHandler}
                             icon={<FontIcon name={ODL_ICONS.PAUSE_CIRCLE_OUTLINE}/>}
                             buttonText="Save & finish later"
                             isDraft={true}/>
    } else {
        return null;
    }
}

export function SummaryActions() {
    const newSubmission = useSelector((formState: FormState) => !formState.domainCode);
    const changesPending = useSelector((formState: FormState) => formState.changesPending);
    if (newSubmission || changesPending) {
        return <SaveButton/>;
    } else {
        return <WorkflowActionComponent/>;
    }
}

export function SaveButton() {
    const snackBar: ISnackBar = useSnackbar();
    const dispatch = useDispatch();
    const history = useHistory();
    const portalConfig = useContext(AppConfigContext);
    const newSubmission = useSelector((formState: FormState) => !formState.domainCode);

    const successCallback = useCallback((updateDomain: UpdateDomain) => {
        const domainCode = updateDomain.data?.code;
        if (newSubmission) {
            snackBar.addMessage({text: `${domainCode} created successfully`, type: AlertTypes.SUCCESS});
            if (!processRedirectActions(updateDomain.clientActions, history, snackBar, updateDomain.data?.code)) {
                routeToSubmissionReceipt(history, updateDomain.meta?.domainTypeName, updateDomain.data?.notification);
            }
        } else {
            snackBar.addMessage({text: `${domainCode} saved successfully`, type: AlertTypes.SUCCESS});
            loadNewFormValues(dispatch, updateDomain, portalConfig);
        }
    }, [dispatch, history, newSubmission, snackBar, portalConfig]);

    return <SubmitButton id="save-submission" successCallback={successCallback}
                         buttonText={newSubmission ? "Submit Now" : "Save"}
                         isDraft={false}/>
}

interface SubmitButtonProps {
    buttonText: string,
    isDraft: boolean,
    successCallback: (updateDomain: UpdateDomain) => void,
    icon?: React.ReactElement,
    removeCapitalisation?: boolean,
    id?: string
}

export function SubmitButton(props: SubmitButtonProps) {
    const {buttonText, successCallback, isDraft, icon, id} = props;
    const buttonStyle = navButton();
    const snackBar: ISnackBar = useSnackbar();
    const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
    const buttonClass = clsx({
        [buttonStyle.navButton]: true,
        [buttonStyle.unCapitalise]: isDraft
    });

    const submitHandler = () => setSubmitInProgress(true);
    const submitCompleted = () => setSubmitInProgress(false);

    const failedCallback = useCallback(() =>
        snackBar.addMessage({text: "Failed To Save", type: AlertTypes.ERROR}), [snackBar]);

    return <>
        <ODLButton onClickHandler={submitHandler} text={buttonText} useWhiteLabel
                   a11yId={id} icon={icon}
                   type={isDraft ? ButtonTypes.TEXT : ButtonTypes.PRIMARY}
                   className={buttonClass}/>
        <SubmitHandler submitInProgress={submitInProgress} submitCompletedCallback={submitCompleted}
                       successCallback={successCallback} failedCallback={failedCallback}/>
    </>
}

const fileUploadBannerStyle = makeStyles((theme) => createStyles({
    snackbar: {
        backgroundColor: "#4C5460",
        color: odlPalette.light.obj.white,
        padding: odlSizes.spacing.factor4.s3,
        borderRadius: odlSizes.spacing.factor4.s1,
        width: "230px",
        display: "flex",
        justifyContent: "space-between",
        zIndex: theme.zIndex.snackbar
    }
}));

export function SubmitHandler(props: {submitInProgress: boolean,
                                submitCompletedCallback: () => void,
                                successCallback: (updateDomain: UpdateDomain) => void,
                                failedCallback: () => void}) {
    const {submitInProgress, submitCompletedCallback, successCallback, failedCallback} = props;
    const uploadInProgress = useSelector(inProgressFileUploadFields);
    const loadingContext = useLoadingContext();
    const dispatch = useDispatch();
    const isLoading = loadingContext.isLoading;
    const setLoadingState = loadingContext.setLoading;

    useEffect(() => {
        if (submitInProgress) {
            if (!isLoading) {
                setLoadingState(true);
            }
            if (uploadInProgress.length === 0) {
                dispatch(submitForm((updateDomain: UpdateDomain) => successCallback(updateDomain)
                    , () => failedCallback()
                    , () => setLoadingState(false)));
                submitCompletedCallback();
            }
        }
    }, [submitInProgress, uploadInProgress.length, setLoadingState, isLoading, successCallback, failedCallback, submitCompletedCallback, dispatch]);

    return <FileUploadingBanner submitInProgress={submitInProgress} uploadInProgress={uploadInProgress}/>;
}

function FileUploadingBanner(props: {submitInProgress: boolean, uploadInProgress: FileValue[]}) {
    const {submitInProgress, uploadInProgress} = props;
    const styles = fileUploadBannerStyle();

    return <Snackbar open={submitInProgress && uploadInProgress.length !== 0}
                     anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
        <div className={styles.snackbar}>
            <CircularProgress color="inherit" size={20}/>
            <span>Uploading {uploadInProgress.length} documents...</span>
        </div>
    </Snackbar>;
}

export function routeToSubmissionReceipt(history: History, domainTypeName?: string, notification?: string) {
    history.push({
        pathname: APP_ROUTES.submissionsReceipt,
        state: {
            notification: fallbackIfUndefined(notification, ""),
            domainTypeName: domainTypeName
        }
    });
}