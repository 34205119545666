import React, {PropsWithChildren, useContext} from 'react';
import {createStyles, makeStyles} from "@material-ui/core/styles";
import odlSizes from "odl-components/styles/odlSizes.json"
import odlPalette from "odl-components/styles/odlProPalette"
import {AppConfigContext, AppLoginContext} from "../api/contexts";
import {ProfileMenu} from "../app/App";
import {useHistory, useRouteMatch} from "react-router-dom";
import {ODLButton} from "./ODLWrapper";
import ButtonTypes from "odl-components/components/Button/Button.types";
import {APP_ROUTES} from "../api/app-routes";
import {customBreakpoints} from "../app/theme";

interface PageHeaderProps {
    header: string,
    subHeader?: string,
}

const pageHeaderStyles = makeStyles((theme) => createStyles({
    container: {
        marginBottom: odlSizes.spacing.factor4.s8,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between"
    },
    title: {
        display: "flex",
        flexDirection: "column"
    },
    header: {
        fontWeight: 500,
        fontSize: "32px",
        lineHeight: "48px",
        color: odlPalette.light.obj.night,
        marginBottom: odlSizes.spacing.factor4.s1
    },
    subHeading: {
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "28px",
        color: odlPalette.light.obj.twilight,
    },
    buttons: {
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        marginLeft: "auto",
        "& > .MuiButton-root": {
            textTransform: "none"
        }
    },
    login: {
        borderRadius: odlSizes.spacing.factor4.s8,
        width: odlSizes.spacing.xl,
        lineHeight: odlSizes.spacing.l
    },
    mySubmissions: {
        marginRight: odlSizes.spacing.factor4.s6
    },
    [theme.breakpoints.down(customBreakpoints.mobile)]: {
        container: {
            flexWrap: "wrap-reverse"
        },
        buttons: {
            marginLeft: "unset",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            width: "100%"
        },
        mySubmissions: {
            marginRight: "unset"
        },
    },
}));

export function PageHeader(props: PropsWithChildren<PageHeaderProps>) {
    const {header, subHeader} = props;
    const styles = pageHeaderStyles();
    const userContext = useContext(AppLoginContext);
    const config = useContext(AppConfigContext);
    const authenticatedUser: boolean = !userContext.profile.anonymousUser;
    const {path} = useRouteMatch();
    const isSubmissionPage = path === APP_ROUTES.submissionsDashboard;
    const history = useHistory();

    return <div className={styles.container}>
        <div className={styles.title}>
            <span className={styles.header}>{header}</span>
            <span className={styles.subHeading}>{subHeader}</span>
        </div>
        {authenticatedUser && <div className={styles.buttons}>
            {!isSubmissionPage && <ODLButton text="My Submissions" className={styles.mySubmissions} type={ButtonTypes.TEXT} onClickHandler={() => {
                history.push(APP_ROUTES.submissionsDashboard)
            }}/>}
            <ProfileMenu/>
        </div>}
        {!authenticatedUser && config.authenticationEnabled && <div className={styles.buttons}>
            <ODLButton text="Log In" type={ButtonTypes.PRIMARY} onClickHandler={userContext.login}
                       className={styles.login}/>
        </div>}
    </div>
}