/**
 * RegulationWorks REST
 * No descripton provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

'use strict';
import * as models from './models';

export interface DomainComponentTemplate {
    

    id?: string;

    name?: string;

    displayName?: string;

    groupName?: string;

    tooltipText?: string;

    componentType?: ComponentTypeEnum;

    sectionTemplate?: models.DomainSectionMeta;

    savedSearch?: models.SearchDto;

    associationTypeFilters?: Array<models.AssociationTypeFilter>;

    mapLocation?: models.MapLocationMeta;

    searchResultSize?: number;

    hideEditableFieldsOnLoad?: boolean;
}

    export enum ComponentTypeEnum { 
        STANDARD = <any> 'STANDARD',
        SINGLE_SECTION = <any> 'SINGLE_SECTION',
        MULTI_SECTION = <any> 'MULTI_SECTION',
        CELL_TABLE = <any> 'CELL_TABLE',
        DOCUMENTS = <any> 'DOCUMENTS',
        ASSOCIATIONS = <any> 'ASSOCIATIONS',
        SEARCH = <any> 'SEARCH',
        NAMES = <any> 'NAMES',
        IDENTITIES = <any> 'IDENTITIES',
        RESOURCES = <any> 'RESOURCES',
        NOTES = <any> 'NOTES',
        ALERTS = <any> 'ALERTS',
        OFFENCE = <any> 'OFFENCE',
        LEGACY_CHECK_LIST = <any> 'LEGACY_CHECK_LIST',
        CHECK_LIST = <any> 'CHECK_LIST',
        LOCATION = <any> 'LOCATION',
        CUSTOM_LOCATION = <any> 'CUSTOM_LOCATION',
        EXTERNAL_SEARCH = <any> 'EXTERNAL_SEARCH',
        SUMMARY_PANEL = <any> 'SUMMARY_PANEL',
        GROUP = <any> 'GROUP',
        MAP = <any> 'MAP',
    }
