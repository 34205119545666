import {APP_ROUTES} from "./app-routes";
import {History} from "history";
import {ISnackBar} from "../components/SnackbarProvider";
import {AlertTypes} from "odl-components";

export function processRedirectActions(clientActions: string[] | undefined, history: History, snackbar: ISnackBar, code?: string): boolean {
    if (!clientActions || clientActions.length === 0) {
        return false;
    }
    for (let clientAction of clientActions) {
        if (clientAction.startsWith("redirect")) {
            let clientActionParts: string[] = clientAction.split(":");
            if (clientActionParts.length >= 3) {
                const location = clientActionParts[1];
                const targetIndex = clientActionParts[0].length + clientActionParts[1].length + 2;
                const target = clientAction.substring(targetIndex);
                if (location === "domain") {
                    if (code !== target) {
                        snackbar.addMessage({text: `Displaying ${target}`, type: AlertTypes.INFO});
                        history.push(`${APP_ROUTES.submissionsEdit}/${target}`);
                        return true;
                    }
                } else if (location === "external") {
                    window.location.href = target;
                }
            }
        }
    }
    return false;
}