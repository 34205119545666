import {DomainCategory} from "../placeholder/model/DomainCategory";
import {FormCategory, FormType} from "../features/FormSelector/model";
import {DomainType} from "../placeholder/model/DomainType";

export function convertDomainCategory(category: DomainCategory): FormCategory {
    return {
        id: category.id as string,
        name: category.name as string,
        types: category.types ? category.types.map(t => convertDomainType(t)) : []
    }
}

function convertDomainType(type: DomainType): FormType {
    return {
        id: type.id as string,
        name: type.name as string,
        description: type.description as string
    }
}