import clsx from "clsx";
import {createStyles, debounce, InputAdornment, TextField} from "@material-ui/core";
import React, {useCallback} from "react";
import {ODL_ICONS} from "odl-components";
import {makeStyles} from "@material-ui/core/styles";


export const searchStyles = makeStyles(theme => createStyles({
    searchBar: {
        minWidth: "300px",
        "& .icon": {
            color: theme.palette.primary.main,
            fontSize: "16px",
            marginRight: "16px"
        }
    }
}));

export function SearchField(props: {filterChange: (value: string) => void, placeholder: string, className?: string}) {
    const classes = searchStyles();
    const rootClass = clsx(props.className, classes.searchBar);
    const debouncedFilterValue = debounce((value: string) => props.filterChange(value), 200);
    const debouncedChange = useCallback(debouncedFilterValue, [debouncedFilterValue]);
    const searchFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => debouncedChange(event.target.value);

    return <TextField id="search-bar" name="search-bar" className={rootClass}
                      placeholder={props.placeholder}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => searchFilterChange(event)}
                      InputProps={{endAdornment: (
                              <InputAdornment position="end">
                                  <span className={"icon " + ODL_ICONS.MAGNIFY}></span>
                              </InputAdornment>)}}
    />;
}