import {AxiosResponse} from "axios";
import {ColumnTypeEnum, Dashboard, DashboardWidget, SearchResult, SearchSortInfo, WidgetTypeEnum} from "../placeholder";
import {blankIfUndefined, fallbackIfUndefined, notUndefined, zeroIfUndefined} from "../util/notUndefined";
import {client} from "./axios-client";
import {ColumnTemplate} from "../features/Search/Search";

export async function getDashboardWidgets(id: string): Promise<DashboardWidgetItem[]> {
    const rawDashboard: AxiosResponse<{ dashboardWidgets: DashboardWidget[] }> = await client.get(id, {baseURL: "/integration/dashboard"});

    return rawDashboard.data.dashboardWidgets
        .filter(w => w.widgetType === WidgetTypeEnum.LIST && !w.searchInfo?.customDtoProvided) // Only include LIST widgets && no custom search providers
        .sort((a, b) => zeroIfUndefined(a.displayOrder) - zeroIfUndefined(b.displayOrder)) // Sort by display order
        .map(w => {
            if (notUndefined(w.searchInfo)) {
                return {
                    searchId: blankIfUndefined(w.searchInfo.id),
                    title: fallbackIfUndefined(w.title, blankIfUndefined(w.searchInfo.name)),
                    columnTemplates: fallbackIfUndefined(w.searchInfo.columnTemplate, []).map(t => ({
                        name: fallbackIfUndefined(t.name, ""),
                        label: fallbackIfUndefined(t.displayName, ""),
                        type: fallbackIfUndefined(t.columnType, ColumnTypeEnum.TEXT),
                        isCustomFormatted: !!t.customFormatter
                    }))
                };
            } else {
                return null;
            }
        }).filter(notUndefined)
}

export async function listDashboards(): Promise<DashboardItem[]> {
    let dashboardResponse: AxiosResponse<Dashboard[]> = await client.get("/integration/dashboard");

    return dashboardResponse.data
        .map(r => {
            if (r.name == null || r.id == null) {
                return null;
            }
            return {
                id: r.id,
                title: r.name
            }
        }).filter(notUndefined);
}

export async function performSavedSearch(id: string, pageOffset: number = 0, pageSize: number = 0,
                                         searchTerms?: string, sortInfo?: SearchSortInfo): Promise<AxiosResponse<SearchResult>> {
    const body = {
        pageOffset,
        pageSize,
        searchTerms,
        sortInfo
    };
    return client.post(id, body, {baseURL: "/api/v1/search/"});
}

// These types are return types of the services
export interface DashboardItem {
    title: string;
    id: string;
}

export interface DashboardWidgetItem {
    title: string;
    searchId: string;
    columnTemplates: ColumnTemplate[];
}
