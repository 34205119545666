import {ColumnTypeEnum, OrderEnum, SearchColumn, SearchResult, SearchRow} from "../../placeholder";
import {format, isValid, parseISO} from "date-fns";
import {serverDateFormat, serverDateTimeFormat} from "../../api/constants";
import {fallbackIfUndefined} from "../../util/notUndefined";

export interface ColumnTemplate {
    name: string;
    label: string;
    type: ColumnTypeEnum;
    isCustomFormatted: boolean;
}

export interface SortInfo {
    field: string;
    order: OrderEnum;
}

export interface SavedSearch {
    id: string;
    name: string;
    description: string;
    columns: ColumnTemplate[];
    sortInfo?: SortInfo
}

// These types are a subset of the properties provided via a Public API search
export interface SearchResultState {
    count: number;
    searchResultRows: SearchResultRowState[];
}

export interface SearchResultRowState {
    id: string;
    code: string;
    type: string;
    status: string;
    columns: {
        key: string;
        value: string;
    }[]
}

export function formatSearchResults(result: SearchResult, columnTemplates: ColumnTemplate[]): SearchResultState {
    return {
        count: result.count ?? 0,
        searchResultRows: result.searchResultRows ? result.searchResultRows.map(r => convertRow(r, columnTemplates)) : []
    };
}

function convertRow(row: SearchRow, columnTemplates: ColumnTemplate[]): SearchResultRowState {
    return {
        id: row.id ?? "",
        code: row.code ?? "",
        type: row.type ?? "",
        status: row.status ?? "",
        columns: columnTemplates.map(ct => {
            const columnValue = row.columns?.find(c => c.key === ct.name);
            return convertColumn(columnValue, ct);
        })
    };
}

function convertColumn(column: SearchColumn | undefined, columnTemplate: ColumnTemplate): { key: string, value: string } {
    return {
        key: columnTemplate.name,
        value: formatSearchValue(fallbackIfUndefined(column?.value, ""), columnTemplate)
    };
}

function formatDate(value: string, dateFormat: string) {
    const date = parseISO(value);
    if (isValid(date)) {
        return format(date, dateFormat);
    }
    return value;
}

function formatSearchValue(value: string, template: ColumnTemplate): string {
    if (template.isCustomFormatted) {
        return value;
    } else {
        switch (template.type) {
            case ColumnTypeEnum.DATE:
                return formatDate(value, serverDateFormat);
            case ColumnTypeEnum.DATETIME:
                return formatDate(value, serverDateTimeFormat);
            default:
                return value;
        }
    }
}