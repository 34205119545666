import {makeStyles} from "@material-ui/core/styles";
import {createStyles} from "@material-ui/core";
import {Link as RouteLink, useLocation} from "react-router-dom";
import * as H from "history"
import odlSizes from "odl-components/styles/odlSizes.json"
import React from "react";
import {ODL_ICONS} from "odl-components";
import clsx from "clsx";
import odlProPalette from "odl-components/styles/odlProPalette";
import styled from "styled-components";

const breadCrumbStyle = makeStyles((theme) => createStyles({
    activeLink: {
        fontWeight: 500,
        borderBottom: `3px solid ${theme.palette.link.primary}`,
        paddingBottom: odlSizes.spacing.factor4.s2,
        color: theme.palette.text.primary
    },
    link: {
        fontSize: odlSizes.typography.size.small,
        fontWeight: 400,
        lineHeight: odlSizes.spacing.factor4.s4,
        color: theme.palette.text.secondary,
        marginTop: odlSizes.spacing.factor4.s3,
        marginBottom: odlSizes.spacing.factor4.s3,
        display: "flex",
        "& > span": {
            "&:not($activeLink)": {
                paddingBottom: "11px"
            }
        }
    },
    navLink: {
        color: theme.palette.link.primary,
        textDecoration: "none",
        alignItems: "center"
    },
    errorIcon: {
        color: theme.palette.error.main,
        marginLeft: odlSizes.spacing.factor4.s1,
        fontSize: odlSizes.typography.size.small,
        lineHeight: odlSizes.spacing.factor4.s4
    }
}));

export function BreadcrumbLink(props: {
    label: string,
    href?: string,
    onClick?: (event: React.SyntheticEvent) => void,
    error?: boolean,
    activeFn?: (location: H.Location, href?: string) => boolean
}) {
    const location = useLocation();
    const classes = breadCrumbStyle();
    const active = props.activeFn ? props.activeFn(location, props.href) : location.pathname === props.href;
    const clickHandler = (event: React.SyntheticEvent) => props.onClick && props.onClick(event);
    if (!props.href || active) {
        return <span className={classes.link}
                     onClick={event => clickHandler(event)}>
            <span className={`${active && classes.activeLink}`}>{props.label}</span>
            {props.error && <span className={clsx("icon", ODL_ICONS.ALERT, classes.errorIcon)}/>}
        </span>
    } else {
        const to: string = props.href ? props.href : "#";
        return <RouteLink className={clsx(classes.navLink, classes.link)} to={to}
                          onClick={(event: React.SyntheticEvent) => clickHandler(event)}>
            <span>{props.label}</span>
            {props.error && <span className={clsx("icon", ODL_ICONS.ALERT, classes.errorIcon)}/>}
        </RouteLink>
    }
}

function Separator(props: {className?: string}) {
    return <span className={clsx("icon", ODL_ICONS.CHEVRON_RIGHT, props.className)}/>;
}

export const BreadcrumbSeparator = styled(Separator)`
    color: ${odlProPalette.light.obj.deco};
    padding-bottom: 11px;
`;