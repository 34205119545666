import {Button, ButtonProps, Radio} from "odl-components";
import React from "react";
import {createStyles, fade, makeStyles} from "@material-ui/core";
import clsx from "clsx";
import odlProPalette from "odl-components/styles/odlProPalette";
import styled from "styled-components";

export function ODLButton(props: ButtonProps & {useWhiteLabel?: boolean}) {
    const {useWhiteLabel, ...others} = props;
    return useWhiteLabel ? <WhiteLabelStyledButton {...others}/> : <OverwriteStyledButton {...others}/>;
}

const OverwriteStyledButton = styled(Button)`
    &.odlButton__text {
        background-color: inherit;
    }
`;

const WhiteLabelStyledButton = styled(OverwriteStyledButton)`
    &.odlButton__primary {
        background-color: ${({theme}) => theme.palette.whiteLabel.main};
        color: ${({theme}) => theme.palette.getContrastText(theme.palette.whiteLabel.main)};
        &:hover {
            background-color: ${({theme}) => theme.palette.whiteLabel.main};
            color: ${({theme}) => theme.palette.getContrastText(theme.palette.whiteLabel.main)};
        }
    }
    &.odlButton__secondary {
        border-color: ${({theme}) => theme.palette.whiteLabel.main};
        color: ${({theme}) => theme.palette.whiteLabel.main};
        &:hover {
            color: ${({theme}) => theme.palette.whiteLabel.main};
        }

    }
`;

const odlCheckboxRadioStyle = makeStyles((theme) => createStyles({
    odlButton: {
        cursor: "default",
        "& label.MuiFormControlLabel-root": {
            cursor: "pointer",
            "&.Mui-disabled": {
                cursor: "not-allowed"
            }
        },
        "& span.MuiFormControlLabel-label": {
            color: theme.palette.text.primary,
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "22px",
            "&.Mui-disabled": {
                color: theme.palette.text.disabled
            }
        },
        "& span.MuiButtonBase-root.Mui-checked": {
            color: theme.palette.primary.main,
            "&:hover": {
                backgroundColor: fade(theme.palette.primary.main, theme.palette.action.hoverOpacity)
            },
            "&.Mui-disabled": {
                color: odlProPalette.light.obj.neutral
            }
        },
        "& span.MuiButtonBase-root": {
            "&:hover": {
                backgroundColor: fade(theme.palette.primary.main, theme.palette.action.hoverOpacity)
            },
            "&:active": {
                color: theme.palette.primary.main,
                backgroundColor: fade(theme.palette.primary.main, theme.palette.action.hoverOpacity)
            }
        },
    }
}));

export function ODLRadio(props: any) {
    const {className, ...otherProps} = props;
    const radioStyles = odlCheckboxRadioStyle(props);
    const classNames = clsx(radioStyles.odlButton, className);
    return <Radio {...otherProps} className={classNames}/>
}
