import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export const searchBoxStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchBox: {
            marginBottom: theme.spacing(4),
            marginTop: theme.spacing(4)
        }
    }),
);

export const searchTableStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        }
    }),
);
