import {
    ColumnTypeEnum,
    OrderEnum,
    SearchColumnTemplate,
    SearchDto,
    SearchRequest,
    SearchSortInfo
} from "../placeholder";
import {client} from "./axios-client";
import {ColumnTemplate, SavedSearch, SortInfo} from "../features/Search/Search";


export async function getSearchInfo(id: string): Promise<SavedSearch> {

    const request = await client.get<SearchDto>(`integration/api/saved_search/${id}`);
    const searchDto = request.data;

    return {
        id,
        name: searchDto.name ?? "",
        description: searchDto.description ?? "",
        columns: searchDto.columnTemplate?.map(convertColumn) ?? [],
        sortInfo: convertSortInfo(searchDto.sortInfo)
    }
}

export async function prepareExport(savedSearch: SavedSearch, searchTerms?: string): Promise<string> {

    let sortInfo: SearchSortInfo | undefined;
    if (savedSearch.sortInfo) {
        sortInfo = {
            sortField: savedSearch.sortInfo.field,
            order: savedSearch.sortInfo.order
        }
    }

    const body: SearchRequest = {
        searchTerms: searchTerms,
        sortInfo: sortInfo
    };

    const response = await client.post(`/api/v1/export/saved_search/${savedSearch.id}`, body);
    return response.data;
}

export function exportSavedSearch(savedSearch: SavedSearch, searchTerms?: string): void {
    prepareExport(savedSearch, searchTerms).then((docId) => {
        window.open(`integration/api/export/${docId}`, "_self");
    });
}

function convertColumn(searchColumnTemplate: SearchColumnTemplate): ColumnTemplate {
    return {
        name: searchColumnTemplate.name ?? "",
        label: searchColumnTemplate.displayName ?? "",
        type: searchColumnTemplate.columnType ?? ColumnTypeEnum.TEXT,
        isCustomFormatted: !!searchColumnTemplate.customFormatter
    }
}

function convertSortInfo(searchSortInfo: SearchSortInfo | undefined): SortInfo | undefined {
    if (!searchSortInfo || !searchSortInfo.sortField) {
        return undefined;
    }
    return {
        field: searchSortInfo.sortField,
        order: searchSortInfo.order ?? OrderEnum.ASC
    }
}