import {PaletteColorOptions, ThemeOptions} from "@material-ui/core";
import createPalette, {Palette, PaletteColor, TypeText} from "@material-ui/core/styles/createPalette";
import odlProPalette from "odl-components/styles/odlProPalette"
import odlSizes from "odl-components/styles/odlSizes.json"
import {PortalConfig} from "../api/contexts";

declare module "@material-ui/core/styles/createPalette" {
    interface Palette {
        whiteLabel: PaletteColor;
        brand: PaletteColor;
        link: Partial<TypeText>;
        globalHeader: PaletteColor;
        globalFooter: PaletteColor;
    }
    interface PaletteOptions {
        whiteLabel: PaletteColorOptions;
        brand: PaletteColorOptions;
        link: Partial<TypeText>;
        globalHeader: PaletteColorOptions;
        globalFooter: PaletteColorOptions;
    }
}

export const customBreakpoints = {
    mobile: 768,
    tablet: 1024,
    desktop: 1408
};

function createThemePalette(props: PortalConfig): Palette {
    return createPalette({
        primary: {
            main: odlProPalette.light.obj.blue
        },
        secondary: {
            main: odlProPalette.light.obj.lightBlue
        },
        whiteLabel: {
            main: props.primaryColour ? props.primaryColour : odlProPalette.light.obj.blue
        },
        brand: {
            main: odlProPalette.light.brand.regworks
        },
        link: {
            primary: odlProPalette.light.obj.blue
        },
        text: {
            primary: odlProPalette.light.obj.night,
            secondary: odlProPalette.light.obj.twilight,
            disabled: odlProPalette.light.obj.neutral
        },
        action: {
            disabled: odlProPalette.light.obj.deco
        },
        error: {
            main: odlProPalette.light.obj.red
        },
        globalHeader: {
            main: props.headerBannerColour ? props.headerBannerColour : odlProPalette.dark.vacuum.dp24,
        },
        globalFooter: {
            main: props.footerBannerColour ? props.footerBannerColour : odlProPalette.dark.vacuum.dp24,
        },
        background: {
            default: odlProPalette.light.obj.white
        }
    });
}

export function createThemeOptions(props: PortalConfig): ThemeOptions {
    let palette = createThemePalette(props);
    return {
        palette,
        props: {
            MuiInputLabel: {
                disableAnimation: true
            },
            MuiInput: {
                disableUnderline: true
            }
        },
        overrides: {
            MuiLink: {
                root: {
                    color: palette.link.primary
                }
            },
            MuiInputLabel: {
                root: {
                    fontSize: "14px",
                    fontWeight: "normal",
                    lineHeight: "20px",
                    "&$focused": {
                        color: palette.text.secondary
                    },
                    "&$disabled": {
                        color: palette.text.secondary
                    },
                    "&$error": {
                        color: palette.text.secondary
                    }
                },
                asterisk: {
                    color: odlProPalette.light.obj.purple
                },
                formControl: {
                    position: "unset",
                    transform: "none"
                },
                shrink: {
                    transform: "none"
                }
            },
            MuiInputBase: {
                multiline: {
                    padding: 0,
                }
            },
            MuiInput: {
                multiline: {
                    height: "auto",
                    minHeight: odlSizes.spacing.factor4.s11
                },
                root: {
                    height: odlSizes.spacing.factor4.s11,
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    boxSizing: "border-box",
                    backgroundColor: odlProPalette.light.obj.white,
                    border: `1px solid ${odlProPalette.light.obj.neutral}`,
                    borderRadius: "4px",
                    caretColor: odlProPalette.light.obj.blue,
                    ":not(.MuiFormLabel-filled)+&$disabled:not($error),&:first-child $disabled:not($error)": {
                        borderColor: odlProPalette.light.obj.deco
                    },
                    "&$disabled": {
                        backgroundColor: odlProPalette.light.obj.day,
                        color: palette.text.primary,
                        cursor: "not-allowed"
                    },
                    "&:not($disabled):not($error)": {
                        "&$focused, &:hover": {
                            borderColor: odlProPalette.light.obj.blue,
                            boxShadow: `inset 0px 0px 4px ${odlProPalette.light.obj.blue}`
                        }
                    },
                    "&$error": {
                        border: `1px dashed ${odlProPalette.light.obj.red}`,
                        backgroundColor: odlProPalette.light.obj.lightRed
                    },
                    ".MuiAutocomplete-root &": {
                        height: "auto",
                        minHeight: "44px",
                        padding: "4px 47px 4px 12px",
                        flexWrap: "wrap",
                        "& [role='progressbar']": {
                            position: "absolute",
                            right: "3px"
                        },
                        "& .MuiAutocomplete-tag": {
                            height: "28px",
                            "& .MuiChip-deleteIcon": {
                                lineHeight: "22px"
                            }
                        }
                    }
                },
                input: {
                    height: "100%",
                    boxSizing: "border-box",
                    padding: "11px 15px",
                    "&::placeholder": {
                        color: odlProPalette.light.obj.neutral,
                        fontWeight: "normal",
                        opacity: 1
                    },
                    ".Mui-error > &::placeholder": {
                        color: palette.text.secondary
                    },
                    ":not(.Mui-error) > &$disabled::placeholder": {
                        color: odlProPalette.light.obj.deco
                    },
                    "&$disabled": {
                        cursor: "not-allowed"
                    },
                    ".MuiAutocomplete-root &": {
                        width: "0px",
                        minWidth: "30px",
                        padding: "7px 3px"
                    }
                },
                formControl: {
                    "label + &": {
                        marginTop: odlSizes.spacing.factor4.s3
                    }
                }
            },
            MuiSelect: {
                select: {
                    "&$disabled": {
                        cursor: "not-allowed"
                    }
                }
            },
            MuiFormHelperText: {
                root: {
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    marginTop: odlSizes.spacing.factor4.s2
                }
            },
            MuiFormControlLabel: {
                root: {
                    "&$disabled": {
                        cursor: "not-allowed"
                    }
                },
                label: {
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "22px"
                }
            },
            MuiFormGroup: {
                root: {
                    marginTop: odlSizes.spacing.factor4.s3,
                    "&.Mui-error": {
                        border: `1px dashed ${odlProPalette.light.obj.red}`,
                        borderRadius: "4px",
                        backgroundColor: odlProPalette.light.obj.lightRed,
                        "& > *": {
                            paddingLeft: odlSizes.spacing.factor4.s4
                        }
                    },
                    "& > *": {
                        width: "fit-content"
                    }
                }
            },
            MuiPaper: {
                root: {
                    "& .MuiAutocomplete-option": {
                        "&[data-focus='true']": {
                            backgroundColor: palette.secondary.main,
                            boxShadow: `inset 4px 0 0 0 ${palette.primary.main}`
                        },
                        "&[aria-selected='true']:not([data-focus='true'])": {
                            backgroundColor: "unset"
                        }
                    }
                },
                elevation1: {
                    boxShadow: "none"
                }
            }
        }
    };
}
