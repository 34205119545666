/**
 * RegulationWorks REST
 * No descripton provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

'use strict';
import * as models from './models';

export interface DomainFieldMeta {


    fieldTemplateId?: string;

    name?: string;

    displayName?: string;

    fieldType?: FieldTypeEnum;

    parameters?: Array<models.DomainFieldMetaParameter>;

    visible?: boolean;

    editable?: boolean;

    mandatory?: boolean;

    codeTableKey?: string;

    minValueRange?: number;

    maxValueRange?: number;

    multiple?: boolean;

    showCreate?: boolean;

    defaultFolder?: string;

    filterString?: Array<models.RefSearchFilter>;

    validationExpression?: string;

    validationMessage?: string;

    triggerRules?: boolean;

    linkType?: LinkTypeEnum;

    defaultResourceRole?: string;

    defaultFieldValue?: string;

    tooltipText?: string;

    referenceData?: Array<models.ReferenceDataValue>;

    domainTypesIndexReference?: Array<models.IndexReference>;

    dynamicParameters?: Array<models.SearchCriteria>;

    geoCoordParameters?: models.GeoCoordinateMeta;

    showCodeAndName?: boolean;

    savedSearchId?: string;

    lineFeed?: number;

    fieldLength?: number;

    legislativeReference?: string;

    cascadingFieldTemplateId?: string;
}

    export enum FieldTypeEnum {
        TEXT = <any> 'TEXT',
        BOOLEAN = <any> 'BOOLEAN',
        NUMBER = <any> 'NUMBER',
        SELECT_SINGLE = <any> 'SELECT_SINGLE',
        SELECT_MULTI = <any> 'SELECT_MULTI',
        DATE = <any> 'DATE',
        TIME = <any> 'TIME',
        DATE_TIME = <any> 'DATE_TIME',
        TRI_STATE = <any> 'TRI_STATE',
        LINK = <any> 'LINK',
        CHECK_TEXT = <any> 'CHECK_TEXT',
        FILE_UPLOAD = <any> 'FILE_UPLOAD',
        LABEL = <any> 'LABEL',
        RADIO = <any> 'RADIO',
        HTML = <any> 'HTML',
        HTML_ANCHOR = <any> 'HTML_ANCHOR',
        LATITUDE = <any> 'LATITUDE',
        LONGITUDE = <any> 'LONGITUDE',
        PASSWORD = <any> 'PASSWORD',
        CHECKBOX_GROUP = <any> 'CHECKBOX_GROUP'
    }

    export enum LinkTypeEnum {
        ACTIVITY = <any> 'ACTIVITY',
        ADDRESS = <any> 'ADDRESS',
        ASSET = <any> 'ASSET',
        ENTITY = <any> 'ENTITY',
        GEOCOORDDDM = <any> 'GEOCOORDDDM',
        PERSON = <any> 'PERSON',
    }
