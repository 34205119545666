/**
 * RegulationWorks REST
 * No descripton provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

'use strict';
import * as models from './models';

export interface InferenceRuleCondition {
    

    conditionOperator?: ConditionOperatorEnum;

    childConditions?: Array<models.InferenceRuleCondition>;

    lhs?: models.RuleValueReference;

    rhs?: models.RuleValueReference;
}

    export enum ConditionOperatorEnum { 
        AND = <any> 'AND',
        OR = <any> 'OR',
        EQUALS = <any> 'EQUALS',
        NOT_EQUALS = <any> 'NOT_EQUALS',
        EMPTY = <any> 'EMPTY',
        POPULATED = <any> 'POPULATED',
        ANY = <any> 'ANY',
        NOT_IN = <any> 'NOT_IN',
        ALL = <any> 'ALL',
        DATE_BETWEEN = <any> 'DATE_BETWEEN',
        DATE_MORE_THAN = <any> 'DATE_MORE_THAN',
        DATE_WITHIN_LAST = <any> 'DATE_WITHIN_LAST',
        DATE_IN_NEXT = <any> 'DATE_IN_NEXT',
    }
