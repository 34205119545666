import React, {createContext, PropsWithChildren, useCallback, useContext, useMemo, useState} from "react";
import {createStyles, Snackbar} from "@material-ui/core";
import {Alert} from "odl-components";
import {makeStyles} from "@material-ui/core/styles";
import AlertTypes from "odl-components/components/Alert/Alert.types";

export interface ISnackBar {
    addMessage: (message: any) => void;
}

export interface IMessage {
    text: string,
    type: AlertTypes
}

const contextDefault: ISnackBar = {
    addMessage: (message: IMessage) => {}
};

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarContext = createContext(contextDefault);

const styles = makeStyles(() => createStyles({
    alert: {
        "& > div.MuiAlert-message": {
            minHeight: "unset"
        }
    },
    message: {
        whiteSpace: "nowrap"
    }
}));

export function SnackbarProvider(props: PropsWithChildren<any>) {
    const [messages, setMessages] = useState<IMessage[]>([]);
    const classes = styles();
    const addMessage = useCallback((message) => {
        setMessages((messages) => [...messages, message]);
    }, []);
    const value = useMemo(() => ({addMessage}), [addMessage]);

    const handleClose = (event: React.SyntheticEvent, reason: string, message: IMessage) => {
        setMessages((messages) => messages.filter(m => m !== message));
    };

    return <SnackbarContext.Provider value={value}>
        {props.children}
        {messages.filter((message, index) => !index).map((message, index) =>
            <Snackbar key={message.text}
                      open={true}
                      autoHideDuration={5000}
                      anchorOrigin={{vertical: "top", horizontal: "right"}}
                      onClose={(event, reason) => handleClose(event, reason, message)}>
                <Alert a11yId={`snackbar-message-${index}`}
                       className={classes.alert}
                       type={message.type}>
                    <div className={classes.message}>{message.text}</div>
                </Alert>
            </Snackbar>)
        }
    </SnackbarContext.Provider>
}