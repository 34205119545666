import {createSelector} from "@reduxjs/toolkit";
import {
    AspectState,
    AspectTypes,
    FieldState,
    FieldTypes,
    FormState,
    MultiSectionAspectState,
    MultiSectionRowState,
    SectionAspectState,
} from "./state";
import {notUndefined} from "../../util/notUndefined";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useMemo, useRef} from "react";
import {Alert, AlertTypes, ODL_ICONS, odlStyled} from "odl-components";
import {performAddRow, performRemoveRow} from "./reducer";
import {Grid, IconButton, Paper, Typography} from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import {Redirect, useHistory} from 'react-router-dom';
import sanitize from 'sanitize-html';
import {errorBanner, formGroupStyle, formSectionStyle, navButton} from "./FormGroup.style";
import {ODLButton} from "../../components/ODLWrapper";
import odlSizes from 'odl-components/styles/odlSizes.json'
import {
    atLeastOneFieldVisible,
    findFirstErrorClass,
    isEditable,
    isVisible,
    selectGroupSize,
    selectGroupStates
} from "./form-utils";
import {FormField} from "./FieldComponents";
import clsx from "clsx";
import ButtonTypes from "odl-components/components/Button/Button.types";
import {IMessage} from "../../components/SnackbarProvider";
import {DraftButton} from "./FormSubmission";
import {pageBottomButtons} from "../../components/PageComponents";

const sanitizeOptions: sanitize.IOptions = {
    allowedTags: sanitize.defaults.allowedTags.concat(["img"])
}

const selectActiveAspects = (groupIndex: number) =>
    createSelector(
        selectGroupStates,
        (state: FormState) => state.aspects,
        (groups, aspects) =>
            groups[groupIndex].aspectIds
                .map(id => aspects.find(a => a.id === id))
                .filter(notUndefined)
                .filter(isVisible)
    );

interface FormContainerProps {
    groupIndex: number,
    baseUrl: string
}

export function FormGroupContainer(props: FormContainerProps) {
    const {groupIndex, baseUrl} = props;
    const groupSize = useSelector(selectGroupSize);

    if (groupSize === 0) {
        return <span>No sections found</span>;
    }

    if (groupIndex >= groupSize) {
        return <Redirect to={baseUrl + "/0"}/>;
    }

    return <FormGroup groupIndex={groupIndex} baseUrl={baseUrl}/>;
}

export function FormGroup(props: FormContainerProps) {
    const {groupIndex, baseUrl} = props;
    const classes = formGroupStyle();
    const buttonClasses = pageBottomButtons();
    const inInitialState: boolean | undefined = useSelector((state: FormState) => state.inInitialStatus);
    const newSubmission: boolean = useSelector((formState: FormState) => !formState.domainCode);
    const draftEnabled: boolean = useSelector((formState: FormState) => formState.draftEnabled);
    const aspect = useMemo(() => selectActiveAspects(groupIndex), [groupIndex]);
    const aspects: AspectState[] = useSelector(aspect).filter(atLeastOneFieldVisible);
    const ref = useRef(null);
    const prevRef = useRef(null);

    useEffect(() => {
        prevRef.current = ref as any;
    }, [ref]);
    const prevRefCurrent = prevRef.current;

    useEffect(() => {
        //only run when ref changes, not aspects.
        if (ref !== prevRefCurrent) {
            const errorClass: string | null = findFirstErrorClass(aspects);
            if (errorClass && ref) {
                let refCurrent = ref.current as unknown as HTMLElement;
                let elements = refCurrent.getElementsByClassName(errorClass);
                if (elements.length) {
                    elements[0].scrollIntoView();
                }
            }
        }
    }, [ref, prevRefCurrent, aspects]);

    return <div className={classes.container} ref={ref}>
        <Grid item className={classes.left}>
            {aspects.map(a => {
                let key = a.id + "-aspect";
                if (a.type === AspectTypes.MULTI_SECTION) {
                    return <FormMultiSection key={key} aspect={a}/>;
                }
                return <FormSection key={key} aspect={a}/>;
            })}
        </Grid>
        <Grid container item className={buttonClasses.container} justify="flex-end" alignItems="center">
            {draftEnabled && (newSubmission || (inInitialState !== undefined && inInitialState)) &&
            <div id="draft-submission-container">
                <DraftButton/>
            </div>}
            <BackPageButton groupIndex={groupIndex} baseUrl={baseUrl}/>
            <NextPageButton groupIndex={groupIndex} baseUrl={baseUrl}/>
        </Grid>
    </div>;
}

export function ErrorBanner(props: { errors: IMessage[], className?: string, checkForWarnings?: boolean }) {
    const {errors} = props;

    const errorList = errors.filter(e => e.type === AlertTypes.ERROR);
    const infoList = errors.filter(e => e.type === AlertTypes.INFO);

    return errors.length ? <div className={props.className}>
        <AlertBanner errors={errorList} type={AlertTypes.ERROR}/>
        <AlertBanner errors={infoList} type={AlertTypes.INFO}/>
    </div> : null;
}

function AlertBanner(props: { errors: IMessage[], type: AlertTypes, className?: string }) {
    const styles = errorBanner();
    const {errors, type, className} = props;
    if (!errors.length) {
        return null;
    }

    return <AlertBannerOverride a11yId={`error-${type}`} type={type} key={type} className={className}>
        {errors.map((e, i) =>
            <div className={styles.message} key={i}>{e.text}</div>)}
    </AlertBannerOverride>
}

const AlertBannerOverride = odlStyled(Alert)`
    &.odlAlert__root {
        padding: 0;
        margin: ${odlSizes.spacing.factor4.s2} 0;
        display: flex;
        align-items: center;
        border-radius: 8px;
    },
    & > .odlAlert__icon {
        margin: ${odlSizes.spacing.factor4.s4};
    },
    & > .odlAlert__message {
        min-height: unset;
        margin-top: ${odlSizes.spacing.factor4.s4};
        margin-right: ${odlSizes.spacing.factor4.s4};
        margin-bottom: ${odlSizes.spacing.factor4.s4};
        text-align: left;
    }
`

export function NextPageButton(props: FormContainerProps & { className?: string }) {
    const buttonIcon = "icon " + ODL_ICONS.ARROW_RIGHT;
    const buttonStyle = navButton();
    const history = useHistory();
    const groupSize = useSelector(selectGroupSize);
    const {groupIndex, baseUrl, className} = props;

    const route = groupIndex + 1 < groupSize ? `${baseUrl}/${groupIndex + 1}` : `${baseUrl}/summary`;

    const onClick = () => {
        history.push(route);
        window.scrollTo(0, 0);
    };

    return <ODLButton a11yId="next-submission-page" type={ButtonTypes.PRIMARY}
                      className={clsx(className, buttonStyle.navButton)} useWhiteLabel
                      endIcon={<span className={buttonIcon}/>}
                      onClickHandler={onClick}
                      text="Next"/>;
}

export function BackPageButton(props: FormContainerProps & { className?: string }) {
    const buttonIcon = "icon " + ODL_ICONS.ARROW_LEFT;
    const history = useHistory();
    const style = navButton();
    const {groupIndex, baseUrl, className} = props;

    const showButton = groupIndex - 1 >= 0;
    const route = `${baseUrl}/${groupIndex - 1}`;

    return showButton ?
        <ODLButton a11yId="back-submission-page" type={ButtonTypes.TEXT}
                   className={clsx(className, style.navButton)}
                   icon={<span className={buttonIcon}/>}
                   onClickHandler={() => history.push(route)}
                   text="Back"/> : null;
}

export function FormSection(props: { aspect: SectionAspectState }) {
    const {displayName, fields, customErrors} = props.aspect;

    const sectionStyles = formSectionStyle();
    const defaultFieldStyle: string = clsx(sectionStyles.field);
    const labelFieldStyle: string = clsx(sectionStyles.field, sectionStyles.label);

    return <Paper elevation={0} className={sectionStyles.sectionContainer}>
        <Typography variant="h6" className={sectionStyles.displayName}>{displayName}</Typography>
        <AlertBanner errors={customErrors} type={AlertTypes.ERROR} className={sectionStyles.errorBanner}/>
        {fields.filter(isVisible).map(f =>
            <Grid container key={f.template.id + "-field-row"}>
                <Grid item className={f.type !== FieldTypes.LABEL ? defaultFieldStyle : labelFieldStyle}>
                    <FormField field={f} aspect={props.aspect}/>
                </Grid>
                {f.template.tooltip && f.type !== FieldTypes.LABEL &&
                <Grid container item className={sectionStyles.tooltip} justify="center" direction="column">
                    <div dangerouslySetInnerHTML={{__html: sanitize(f.template.tooltip, sanitizeOptions)}}/>
                </Grid>}
            </Grid>
        )}
    </Paper>;
}

export function FormMultiSection(props: { aspect: MultiSectionAspectState }) {
    const {displayName, rows, customErrors, allowAddRow, allowDeleteRow} = props.aspect;
    const editable = isEditable(props.aspect);

    const dispatch = useDispatch();
    const sectionStyles = formSectionStyle();

    const onAdd = () => dispatch(performAddRow(props.aspect));

    const add = editable && allowAddRow ? <ODLButton text="Add" className={sectionStyles.addRowButton}
                                                     type={ButtonTypes.SECONDARY}
                                                     icon={<span className="icon icon-plus"/>}
                                                     onClickHandler={onAdd}/> : <br/>;

    return <Paper elevation={0} className={sectionStyles.sectionContainer}>
        {<Typography variant="h6" className={sectionStyles.displayName}>{displayName}</Typography>}
        <AlertBanner errors={customErrors} type={AlertTypes.ERROR} className={sectionStyles.errorBanner}/>
        {rows.filter(r => !r.removed && r.activeRules.hide.length === 0).map(r =>
            <FormMultiSectionRow key={r.key} aspect={props.aspect} row={r} fields={r.fields}
                                 parentEditable={editable} allowRemoveRow={allowDeleteRow}/>
        )}
        {add}
    </Paper>;
}

function FormMultiSectionRow(props: {
    aspect: AspectState,
    row: MultiSectionRowState,
    fields: FieldState[],
    parentEditable: boolean,
    allowRemoveRow: boolean
}) {

    const {aspect, row, fields} = props;

    const sectionStyles = formSectionStyle();
    const defaultFieldStyle: string = clsx(sectionStyles.field);
    const labelFieldStyle: string = clsx(sectionStyles.field, sectionStyles.label);

    let dispatch = useDispatch();
    const onRemove = () => dispatch(performRemoveRow({aspect, row}));
    const rowStyling = clsx({
        [sectionStyles.multiSectionRow]: true,
        [sectionStyles.multiSectionRowError]: row.customErrors.length > 0,
    });
    return <div className={rowStyling}>
        {props.parentEditable && row.activeRules.disable.length === 0 && props.allowRemoveRow &&
        <IconButton aria-label="remove-row" onClick={onRemove}>
            <ClearIcon/>
        </IconButton>
        }
        <Grid container wrap="nowrap">
            <Grid item className={sectionStyles.multiSectionRowContent}>
                <AlertBanner errors={row.customErrors} type={AlertTypes.ERROR}/>
                <Grid container direction="row" justify="space-between"
                      className={sectionStyles.multiSectionRowContent}>
                    {fields.filter(isVisible).map(f =>
                        <div key={row.key + f.template.name}
                             className={f.type !== FieldTypes.LABEL ? defaultFieldStyle : labelFieldStyle}>
                            <FormField field={f} aspect={aspect} row={row}/>
                        </div>
                    )}
                </Grid>
            </Grid>
        </Grid>
    </div>;
}