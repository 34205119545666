import {DomainCategory, UiPortalConfig} from "../placeholder";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {client} from "./axios-client";
import {defaultProfile, PortalConfig, UserProfile} from "./contexts";
import {fallbackIfUndefined} from "../util/notUndefined";

type PortalUserConfig = { portalConfig: PortalConfig; userProfile: UserProfile };

export async function getUiConfig(): Promise<PortalUserConfig> {
    // this header stops the GET request from following the redirect
    const config: AxiosRequestConfig = {headers: {"X-Requested-With": "XMLHttpRequest"}};
    return client.get("integration/ui/portalUi", config).then(r => {
        const uiConfig: UiPortalConfig = r.data;
        return {
            userProfile: uiConfigToUserProfile(uiConfig),
            portalConfig: uiConfigToPortalConfig(uiConfig)
        };
    }, handleErrors) as Promise<PortalUserConfig>;
}

function uiConfigToUserProfile(uiConfig: UiPortalConfig): UserProfile {
    if (!uiConfig.currentUser?.username || uiConfig.currentUser.anonymousUser) {
        return defaultProfile;
    }
    return {
        username: uiConfig.currentUser.username,
        anonymousUser: !!uiConfig.currentUser.anonymousUser,
        fullName: uiConfig.currentUser.fullName,
        givenNames: uiConfig.currentUser.givenNames,
        surname: uiConfig.currentUser.surname
    };
}

function uiConfigToPortalConfig(uiConfig: UiPortalConfig): PortalConfig {
    return {
        documentUploadFileTypeWhitelist: fallbackIfUndefined(uiConfig.documentUploadFileTypeWhitelist, []),
        documentMaxUploadSizeMb: fallbackIfUndefined(uiConfig.documentMaxUploadSizeMb, 100),
        footerLogo: uiConfig.footerLogo,
        footerBannerColour: uiConfig.footerBannerColour,
        headerBannerColour: uiConfig.headerBannerColour,
        headerLogo: uiConfig.headerLogo,
        primaryColour: uiConfig.primaryColour,
        authenticationEnabled: fallbackIfUndefined(uiConfig.authenticationEnabled, false)
    };
}

function handleErrors(e: any) {
    // if we get a 401 unauthorized and a redirection in header, change the location
    if (e && e.response && 401 === e.response.status && e.response.headers && e.response.headers.location) {
        window.sessionStorage.setItem("LoginAttempted", "true");
        window.location.href = e.response.headers.location;
    }
}

export async function getDomainCategories(): Promise<DomainCategory[]> {
    let integration: AxiosResponse = await client.get("integration/ui/domainCategories", {
        params: {
            "create_flag": true,
            "show_type_description": true
        }
    });
    return integration.data;
}