import {AspectState, AspectTypes, FieldState, FieldTypes, FormState} from "../features/form/state";
import {
    DomainData,
    FieldData,
    MultiSectionData,
    ReferenceDataValue,
    SectionData,
    Sections,
    TriggerRuleAction,
    UpdateDomain
} from "../placeholder";
import {client} from "./axios-client";
import {ITEM_DELIMITER} from "./constants";
import {convertRefDataValuesToFieldValue, convertSingleRefDataValueToFieldValue} from "../features/form/form-utils";
import {FieldChangeActionPayload} from "../features/form/reducer";
import {blankIfUndefined, notUndefined} from "../util/notUndefined";

export async function submitDomain(state: FormState): Promise<UpdateDomain> {
    let response = await client.post(`integration/api/domain/create/${state.typeId}`, stateToDomainData(state));
    return response.data;
}

export async function updateDomain(state: FormState): Promise<UpdateDomain> {
    let response = await client.post(`integration/api/domain`, stateToDomainData(state));
    return response.data;
}

export async function triggerRules(state: FormState, change: FieldChangeActionPayload): Promise<UpdateDomain> {
    const triggerRequest: TriggerRuleAction = {
        oldValue: stateToFieldValue(change.field) as any,
        newValue: stateToFieldValue({...change.field, value: change.value}) as any,
        sectionTemplateId: change.aspect.template.id,
        sectionName: change.aspect.template.name,
        fieldName: change.field.template.name,
        domainTypeId: state.typeId,
        domainCode: state.domainCode,
        multisectionId: change.row?.key,
        sections: stateToAspectsData(state.aspects),
        meta: {components: []}
    };
    const response = await client.post("integration/api/rule/trigger", triggerRequest);
    return response.data;
}

export async function triggerSectionRules(state: FormState, aspect: AspectState, rowId?: string): Promise<UpdateDomain> {
    const triggerRequest: TriggerRuleAction = {
        sectionTemplateId: aspect.template.id,
        sectionName: aspect.template.name,
        domainTypeId: state.typeId,
        domainCode: state.domainCode,
        multisectionId: rowId,
        sections: stateToAspectsData(state.aspects),
        meta: {components: []}
    };
    const response = await client.post("integration/api/rule/trigger", triggerRequest);
    return response.data;
}

function stateToDomainData(state: FormState): DomainData {
    return {
        code: state.domainCode,
        status: state.status,
        sections: stateToAspectsData(state.aspects)
    };
}

function stateToAspectsData(aspects: AspectState[]): Sections {
    let sections: Sections = {};
    for (let aspect of aspects) {
        let sectionData = stateToAspectData(aspect);
        if (sectionData) {
            sections[aspect.name] = sectionData;
        }
    }
    return sections;
}

function stateToAspectData(state: AspectState): SectionData | undefined {
    if (state.type === AspectTypes.SINGLE_SECTION) {
        let fieldData = stateToFieldData(state.fields);
        return {
            fields: fieldData
        };
    } else if (state.type === AspectTypes.MULTI_SECTION) {
        let rowData: { [key: string]: MultiSectionData } = {}
        for (let row of state.rows) {
            rowData[row.key] = {
                fields: stateToFieldData(row.fields),
                delete: row.removed,
                displayOrder: row.displayOrder
            };
        }
        return {
            rows: rowData
        };
    }
}

function stateToFieldData(fields: FieldState[]): FieldData {
    let fieldData: { [key: string]: string | number | undefined | null } = {}
    for (let field of fields) {
        fieldData[field.template.name] = stateToFieldValue(field);
    }
    return fieldData as FieldData;
}

function stateToFieldValue(field: FieldState): string | undefined | null {
    switch (field.type) {
        case FieldTypes.TEXT:
            return field.value;
        case FieldTypes.NUMBER:
            return notUndefined(field.value) ? String(field.value) : null;
        case FieldTypes.DATE_TIME:
        case FieldTypes.DATE:
            return field.value?.dateString;
        case FieldTypes.CHECK_BOX:
            return String(field.value);
        case FieldTypes.DROPDOWN_SINGLE:
            if (field.value) {
                return convertSingleRefDataValueToFieldValue(field.value);
            }
            return undefined;
        case FieldTypes.DROPDOWN_MULTI:
        case FieldTypes.CHECKBOX_GROUP:
            if (field.value) {
                return convertRefDataValuesToFieldValue(field.value);
            }
            return undefined;
        case FieldTypes.FILE_UPLOAD:
            return field.value.length > 0 ?
                field.value.map(f => f.path).join(ITEM_DELIMITER) : null;
        case FieldTypes.RADIO:
            if (field.value) {
                return convertRadioRefDataToFieldValue(field.value);
            }
            return undefined;
        case FieldTypes.LINK:
            if (field.value) {
                return field.originalValue;
            }
            return undefined;
    }
}

function convertRadioRefDataToFieldValue(refData: ReferenceDataValue): string {
    return blankIfUndefined(refData.name);
}