import {AxiosResponse} from "axios";
import {client} from "./axios-client";
import {ReferenceDataValue} from "../placeholder";

export async function listRefDataByPath(templateName: string, sectionName: string, fieldName: string, parentCode?: string): Promise<ReferenceDataValue[]> {
    const config = {params: {templateName, sectionName, fieldName, parentCode}};
    const response: AxiosResponse<ReferenceDataValue[]> = await client.get("/api/v1/referenceData", config);
    return response.data;
}

export async function listRefDataByCode(codeTableKey: string, parentCode?: string): Promise<ReferenceDataValue[]> {
    const config = {params: {"key": codeTableKey, parentCode}};
    const response: AxiosResponse<ReferenceDataValue[]> = await client.get("/api/v1/referenceData", config);
    return response.data;
}
