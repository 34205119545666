
export function notUndefined<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export function fallbackIfUndefined<T>(val: T | null | undefined, fallback: T): T {
    if (val === null || val === undefined) {
        return fallback;
    }
    return val;
}

export function zeroIfUndefined(x: number | null | undefined): number {
    return fallbackIfUndefined(x, 0);
}

export function blankIfUndefined(str: string | null | undefined): string {
    return fallbackIfUndefined(str, "");
}

export function emptyArrayIfUndefined<T>(array: T[] | null | undefined) {
    return fallbackIfUndefined(array, []);
}

export function undefinedIfNaN(x: any): number | undefined {
    const n: number = parseFloat(x);
    return isNaN(n) ? undefined : n;
}
