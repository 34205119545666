import {AxiosResponse} from "axios";
import {CustomLocationData} from "../placeholder";
import {client} from "./axios-client";

export async function getPlaceSuggestions(searchTerm: string, aspectId: string): Promise<string[]> {
    const config = {params: {"search_term": searchTerm, "aspect_id": aspectId}};
    const response: AxiosResponse<string[]> = await client.get("/integration/location/place", config);
    return response.data;
}

export async function getCustomLocation(address: string, aspectId: string): Promise<CustomLocationData> {
    const config = {params: {address, "aspect_id": aspectId}};
    const response: AxiosResponse<CustomLocationData> = await client.get("/integration/location/geocode/custom", config);
    return response.data;
}
